import {Moment} from "moment";
import moment from "moment/moment";

export default class WarehouseSchema {
  constructor(
    readonly id: string | undefined,
    readonly warehouseId: string | undefined,
    readonly version: number | undefined,
    readonly name: string | undefined,
    readonly schema: string | undefined,
    readonly topology: string | undefined,
    readonly graph: string | undefined,
    readonly creationTime: Moment | undefined,
    readonly isActive: boolean | undefined,
    readonly isDraft: boolean | undefined,
  ) {
  }

  formatDate(): string | undefined {
    const format = this.creationTime?.add(moment.duration(1, 'year')).isBefore(moment())
      ? 'D MMMM YYYY'
      : 'D MMMM'
    ;

    return this.creationTime?.format(format);
  }
}
