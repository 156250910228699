import Collection from "../../domain/collection";
import WarehouseTopologySection from "./WarehouseTopologySection";
import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopologySectionCollection extends Collection<WarehouseTopologySection> {
  findByName(name: string): WarehouseTopologySection | undefined {
    return this.find(
      (section: WarehouseTopologySection) => section.name === name
    );
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologySectionCollection {
    return new WarehouseTopologySectionCollection(
      this
        .getAll()
        .map(
          (section: WarehouseTopologySection) => section.withoutCells(removingCells)
        )
        .filter(
          (section: WarehouseTopologySection) => !section.lines.isEmpty()
        )
    );
  }
}
