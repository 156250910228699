import React, {useEffect, useState} from "react";
import {Input, Avatar, Dropdown, Menu, Timeline} from "antd";
import {SearchOutlined, UserOutlined, DownOutlined} from "@ant-design/icons";
import connect from "../../../stores/connect";
import {WarehouseSchemaDto} from "../../../api/swagger";
import WarehouseCollection from "../../../models/Warehouses/WarehouseCollection";
import Warehouse from "../../../models/Warehouses/Warehouse";
import WarehouseSchema from "../../../models/Warehouses/WarehouseSchema";
import WarehouseSchemaButton from "./WarehouseSchemaButton";
import WarehouseSchemaDescription from "./WarehouseSchemaDescription";
import WarehouseSchemaIcon from "../../../components/Svg/WarehouseSchemaIcon";
import WarehouseSchemaHistoryItem from "./WarehouseSchemaHistoryItem";
import WarehouseTopologyCollection from "../../../models/Warehouses/WarehouseTopologyCollection";
import NewProjectModal from "./ProjectSettingsModal/NewProjectModal";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WarehouseListProperties {
  currentWarehouse: Warehouse | undefined;
  isTopologyListLoading: boolean;
  isWarehouseListLoading: boolean;
  getTopologies: () => Promise<WarehouseTopologyCollection>;
  getWarehouses: () => Promise<void>;
  setCurrentWarehouse: (warehouse: Warehouse | undefined) => void;
  warehouses: WarehouseCollection;
}

const DisconnectedWarehouseList: React.FC<WarehouseListProperties> = (
  {
    currentWarehouse,
    isTopologyListLoading,
    isWarehouseListLoading,
    getTopologies,
    getWarehouses,
    setCurrentWarehouse,
    warehouses,
  }
) => {

  useEffect(() => {
    (async () => {
      await getTopologies();
    })();
  }, [getTopologies]);

  useEffect(() => {
    if (warehouses.count() === 1) {
      setCurrentWarehouse(warehouses.getAll()[0]);
    }
  }, [warehouses]);

  const [isCreateWarehouseModalOpen, setCreateWarehouseModalOpen] = useState<boolean>(false);
  const startWarehouseCreation = () => setCreateWarehouseModalOpen(true);

  useEffect(() => {
    if (isCreateWarehouseModalOpen) {
      return;
    }
    (async () => {
      await getWarehouses();
    })();
  }, [getWarehouses, isCreateWarehouseModalOpen]);

  const onSearch = (event) => {
    // todo: implement search // console.log(event.target.value);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <span onClick={() => {}}>
          Загрузить
        </span>
      </Menu.Item>
    </Menu>
  );

  const isLoading = isWarehouseListLoading || isTopologyListLoading;

  return (
    <div className="warehouse-list">
      <div className="warehouse-list-header">
        <div className="warehouse-list-header-title">
          Список электронных двойников склада
        </div>
        <div className="warehouse-list-header-search">
          <Input
            placeholder="Поиск"
            allowClear
            prefix={<SearchOutlined />}
            size="large"
            onChange={onSearch}
          />
        </div>
        <div className="warehouse-list-header-new-warehouse">
          <Dropdown.Button overlay={menu} icon={<DownOutlined />} size="large">
            <span onClick={startWarehouseCreation}>Новый склад</span>
          </Dropdown.Button>
        </div>
        <div className="warehouse-list-header-avatar">
          <Avatar
            size={44}
            shape="square"
            style={{borderRadius: 'var(--button-border-radius)', border: 'solid 1px #f3f3f3'}}
            icon={<UserOutlined />}
          />
        </div>
      </div>
      <div className="warehouse-list-content">
        <div className="warehouse-list-content-list">
          {isLoading && (
            <div className="warehouse-list-content-list-item">Загрузка списка складов...</div>
          )}
          {!isLoading && !warehouses.isEmpty() && warehouses.map((warehouse) => (
            <div
              key={warehouse.id}
              onClick={() => setCurrentWarehouse(warehouse)}
              className={`warehouse-list-content-list-item ${warehouse.equals(currentWarehouse) ? 'selected' : ''}`}
            >
              <div className="warehouse-list-content-list-item-name">
                {warehouse.name}
              </div>
              <div className="warehouse-list-content-list-item-versions">
                <WarehouseSchemaButton schema={warehouse.schemas.getActive()} />
                <WarehouseSchemaButton schema={warehouse.schemas.getDrafts().getLatest()} />
              </div>
            </div>
          ))}
        </div>
        {!!currentWarehouse && !currentWarehouse.schemas.isEmpty() && (
          <div className="warehouse-list-content-versions">
            <div key="last-versions" className="warehouse-list-content-versions-last">
              <WarehouseSchemaDescription schema={currentWarehouse.schemas.getDrafts().getLatest()} />
              <WarehouseSchemaDescription schema={currentWarehouse.schemas.getActive()} />
            </div>
            <div key="history" className="warehouse-list-content-versions-history">
              <div className="warehouse-list-content-versions-history-title">
                История версий
              </div>
              <Timeline>
                {currentWarehouse.schemas.sortByDate().map((schema) => (
                  <Timeline.Item key={schema.id} dot={<WarehouseSchemaIcon schema={schema} />}>
                    <div className="warehouse-list-content-versions-history-item">
                      <WarehouseSchemaHistoryItem schema={schema} />
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          </div>
        )}
      </div>
      <NewProjectModal isOpen={isCreateWarehouseModalOpen} setOpen={setCreateWarehouseModalOpen} />
    </div>
  );
};

const WarehouseList = connect(({store}) => ({
  currentWarehouse: store.warehouseStore.currentWarehouse,
  isTopologyListLoading: store.warehouseStore.isLoadingTopologies,
  isWarehouseListLoading: store.warehouseStore.isLoadingWarehouses,
  getWarehouses: store.warehouseStore.getAll,
  getTopologies: store.warehouseStore.getTopologies,
  setCurrentWarehouse: store.warehouseStore.setCurrentWarehouse,
  warehouses: store.warehouseStore.warehouses,
}))(DisconnectedWarehouseList)

export default WarehouseList;
