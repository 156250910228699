import React from "react";

const ShelfHeightIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.75 11.25L11.25 11.25M0.75 0.75L11.25 0.75M6 2.79167L6 9.20833M4.25 9.20833L7.75 9.20833M4.25 2.79167L7.75 2.79167"
                className="stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ShelfHeightIcon;
