import React, {useState} from "react";
import {Modal, Button} from "antd";
import WarehouseSchema from "../../models/Warehouses/WarehouseSchema";
import connect from "../../stores/connect";

interface DeleteIconProps {
  deleteWarehouseSchema: (schema: WarehouseSchema) => Promise<void>;
  isDeletingWarehouseSchema: boolean;
  isLoadingWarehouses: boolean;
  schema: WarehouseSchema;
}

const DisconnectedDeleteIcon: React.FC<DeleteIconProps> = (
  {
    deleteWarehouseSchema,
    isDeletingWarehouseSchema,
    isLoadingWarehouses,
    schema,
  }
) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [isActiveSchemaWarningOpen, setIsActiveSchemaWarningOpen] = useState(false);

  const startDeletingSchema = () => {
    if (!schema) {
      return;
    }
    if (schema.isActive) {
      setIsActiveSchemaWarningOpen(true);
    } else {
      setDeleteModalOpen(true);
    }
  };

  const processDeletingSchema = async () => {
    await deleteWarehouseSchema(schema);
  };

  const isLoading = isDeletingWarehouseSchema || isLoadingWarehouses;

  return (
    <>
      <svg onClick={startDeletingSchema} className="delete-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_259_2976)">
          <mask id="mask0_259_2976" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.50698 2.5H5V2C5 1.60217 5.15803 1.22065 5.43934 0.93934C5.72065 0.658035 6.10217 0.5 6.5 0.5H9.5C9.8978 0.5 10.2793 0.658035 10.5607 0.93934C10.8419 1.22065 11 1.60217 11 2V2.5H13.4933C13.4974 2.49995 13.5016 2.49995 13.5057 2.5H15C15.2761 2.5 15.5 2.72386 15.5 3C15.5 3.27614 15.2761 3.5 15 3.5H13.9601L13.075 14.124C13.0439 14.4991 12.8729 14.8488 12.596 15.1037C12.319 15.3586 11.9564 15.5 11.58 15.5H4.4207C4.04431 15.5 3.68172 15.3586 3.40478 15.1037C3.12784 14.8488 2.95686 14.4991 2.92574 14.124L2.03998 3.5H1C0.72386 3.5 0.5 3.27614 0.5 3C0.5 2.72386 0.72386 2.5 1 2.5H2.49418C2.49846 2.49995 2.50273 2.49995 2.50698 2.5ZM3.04345 3.5L3.92232 14.0413C3.93269 14.1663 3.98969 14.2829 4.082 14.3679C4.17431 14.4529 4.29524 14.5 4.4207 14.5H11.5801C11.7056 14.5 11.8264 14.4529 11.9187 14.3679C12.011 14.2829 12.068 14.1661 12.0784 14.0411L12.9566 3.5H10.5H5.5H3.04345ZM10 2.5H6V2C6 1.86739 6.05268 1.74021 6.14645 1.64645C6.24021 1.55268 6.36739 1.5 6.5 1.5H9.5C9.6326 1.5 9.7598 1.55268 9.85353 1.64645C9.94733 1.74021 10 1.86739 10 2V2.5ZM6.5 12C6.22386 12 6 11.7761 6 11.5V6.5C6 6.22386 6.22386 6 6.5 6C6.77613 6 7 6.22386 7 6.5V11.5C7 11.7761 6.77613 12 6.5 12ZM9 11.5C9 11.7761 9.22387 12 9.5 12C9.77613 12 10 11.7761 10 11.5V6.5C10 6.22386 9.77613 6 9.5 6C9.22387 6 9 6.22386 9 6.5V11.5Z" fill="black"/>
          </mask>
          <g mask="url(#mask0_259_2976)">
            <rect width="16" height="16" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_259_2976">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Modal
        visible={isDeleteModalOpen}
        title="Удалить выбранную версию плана?"
        onCancel={() => setDeleteModalOpen(false)}
        footer={[
          <Button key="ok" loading={isLoading} onClick={processDeletingSchema} type="primary" danger>
            Удалить
          </Button>,
          <Button key="cancel" onClick={() => setDeleteModalOpen(false)}>Отменить</Button>
        ]}
      />
      <Modal
        visible={isActiveSchemaWarningOpen}
        title="Нельзя удалить текущую версию плана склада."
        onCancel={() => setIsActiveSchemaWarningOpen(false)}
        footer={[
          <Button key="ok" onClick={() => setIsActiveSchemaWarningOpen(false)} type="primary">Понятно</Button>
        ]}
      />
    </>
  );
};

export default connect(({store}) => ({
  deleteWarehouseSchema: store.warehouseStore.deleteWarehouseSchema,
  isDeletingWarehouseSchema: store.warehouseStore.isDeletingWarehouseSchema,
  isLoadingWarehouses: store.warehouseStore.isLoadingWarehouses,
}))(DisconnectedDeleteIcon);
