import React from "react";

const DoorIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 4.5C18.8332 4.5 16.6876 4.92679 14.6857 5.75599C12.6839 6.58519 10.8649 7.80057 9.33274 9.33274C7.80057 10.8649 6.58519 12.6839 5.75599 14.6857C4.92678 16.6876 4.5 18.8332 4.5 21"
      className="stroke"
      strokeWidth="1.5"
      strokeDasharray="2 2"
    />
    <path d="M3 20H20.9999" className="stroke" strokeWidth="2"/>
    <path d="M20.25 3V19.5" className="stroke" strokeWidth="1.5"/>
  </svg>
;

export default DoorIcon;
