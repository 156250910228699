import React from "react";

const WarehouseSchemaNonActiveIcon: React.FC = () => {
  return (
    <svg className="warehouse-schema-button-icon-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#707070" />
      <g clipPath="url(#clip0_259_2930)">
        <path d="M7.5 6V11.25H6C5.60218 11.25 5.22064 11.408 4.93934 11.6893C4.65804 11.9706 4.5 12.3522 4.5 12.75V15.75C4.5 16.3467 4.73705 16.919 5.15901 17.341C5.58097 17.7629 6.15326 18 6.75 18C7.34674 18 7.91903 17.7629 8.34099 17.341C8.76295 16.919 9 16.3467 9 15.75H10.5C10.5 16.3467 10.7371 16.919 11.159 17.341C11.581 17.7629 12.1533 18 12.75 18C13.3467 18 13.919 17.7629 14.341 17.341C14.7629 16.919 15 16.3467 15 15.75V12.75L12 6H7.5ZM15.75 6.75V17.25H19.5V16.125H16.875V6.75H15.75ZM8.625 7.125H11.4L13.875 12.75H8.625V7.125ZM6.75 14.625C6.9725 14.625 7.19001 14.691 7.37502 14.8146C7.56002 14.9382 7.70422 15.1139 7.78936 15.3195C7.87451 15.525 7.89679 15.7512 7.85338 15.9695C7.80997 16.1877 7.70283 16.3882 7.5455 16.5455C7.38816 16.7028 7.18771 16.81 6.96948 16.8534C6.75125 16.8968 6.52505 16.8745 6.31948 16.7894C6.11391 16.7042 5.93821 16.56 5.8146 16.375C5.69098 16.19 5.625 15.9725 5.625 15.75C5.625 15.4516 5.74353 15.1655 5.9545 14.9545C6.16548 14.7435 6.45163 14.625 6.75 14.625ZM12.75 14.625C12.9725 14.625 13.19 14.691 13.375 14.8146C13.56 14.9382 13.7042 15.1139 13.7894 15.3195C13.8745 15.525 13.8968 15.7512 13.8534 15.9695C13.81 16.1877 13.7028 16.3882 13.5455 16.5455C13.3882 16.7028 13.1877 16.81 12.9695 16.8534C12.7512 16.8968 12.525 16.8745 12.3195 16.7894C12.1139 16.7042 11.9382 16.56 11.8146 16.375C11.691 16.19 11.625 15.9725 11.625 15.75C11.625 15.4516 11.7435 15.1655 11.9545 14.9545C12.1655 14.7435 12.4516 14.625 12.75 14.625Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_259_2930">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarehouseSchemaNonActiveIcon;
