import React from "react";

const HolesIcon: React.FC = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_726_1786)">
    <mask id="mask0_726_1786" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path
        d="M7.75 9.25H7V10V20.25H3.75V3.75H20.25V20.25H17V10V9.25H16.25H12H7.75Z"
        className="stroke"
        strokeWidth="1.5"
      />
    </mask>
    <g mask="url(#mask0_726_1786)">
      <rect width="24" height="24" className="fill"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_726_1786">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export default HolesIcon;
