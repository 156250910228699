import { action, observable } from 'mobx';

import { CreateOrUpdateUserInput } from '../services/user/dto/createOrUpdateUserInput';
import { EntityDto } from '../services/dto/entityDto';
import { GetRoles } from '../services/user/dto/getRolesOuput';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { PagedUserResultRequestDto } from '../services/user/dto/PagedUserResultRequestDto';
import { UpdateUserInput } from '../services/user/dto/updateUserInput';
import userService from '../services/user/userService';
import {UserDto} from "../api/swagger";
import {CreateOrUpdateStaffInput} from "../services/user/dto/createOrUpdateStaffInput";
import {apiClient} from "../services/apiClient";

const MAX_INT_VALUE = 2147483647;

class UserStore {
  @observable users!: PagedResultDto<UserDto>;
  @observable editUser!: CreateOrUpdateUserInput;
  @observable editStaff!: CreateOrUpdateStaffInput;
  @observable addedUser: UserDto | undefined;
  @observable roles: GetRoles[] = [];
  @observable isUsersLoaded = false;
  @observable isRolesLoaded = false;

  @action
  async create(createUserInput: CreateOrUpdateUserInput): Promise<void> {
    const result = await userService.create(createUserInput);
    this.addedUser = result;
    this.users.items.push(result);
  }

  @action
  async createStaff(createStaffInput: CreateOrUpdateStaffInput): Promise<void> {
    const result = await userService.createStaff(createStaffInput);
    this.addedUser = result;
    this.users.items.push(result);
  }

  @action
  async update(updateUserInput: UpdateUserInput | UserDto): Promise<void> {
    const result = await userService.update(updateUserInput);
    this.users.items = this.users.items.map((x: UserDto) => {
      if (x.id === updateUserInput.id) {
        x = result;
      }
      return x;
    });
  }

  @action
  async delete(entityDto: EntityDto): Promise<void> {
    await userService.delete(entityDto);
    this.users.items = this.users.items.filter((x: UserDto) => x.id !== entityDto.id);
  }

  @action
  async getRoles(): Promise<void> {
    this.roles = await userService.getRoles();
    this.isRolesLoaded = true;
  }

  @action
  async get(entityDto: EntityDto): Promise<void> {
    this.editUser = await userService.get(entityDto);
  }

  @action
  createUser(): void {
    this.editUser = {
      userName: '',
      name: '',
      surname: '',
      emailAddress: '',
      phoneNumber: '',
      isActive: false,
      roleNames: [],
      password: '',
      id: 0,
    };
  }

  @action
  createStaffModel(): void {
    this.editStaff = {
      fullName: '',
      emailAddress: undefined,
      phoneNumber: undefined,
      roleNames: [],
      id: 0,
    };
  }

  @action
  async getAll(pagedFilterAndSortedRequest: PagedUserResultRequestDto): Promise<void> {
    pagedFilterAndSortedRequest.maxResultCount = pagedFilterAndSortedRequest.maxResultCount ?? MAX_INT_VALUE
    const result = await userService.getAll(pagedFilterAndSortedRequest);
    this.users = result;
    this.isUsersLoaded = true;
  }

  @action
  async getStaffQrCodesZipFile(userIds: number[], isReworkAuthKeys: boolean): Promise<{blob: Blob, fileDownloadName: string}> {
    const result = await apiClient.getQRCodesZipFile({userIds: userIds, isReworkAuthKeys: isReworkAuthKeys})
    return {blob: result.data, fileDownloadName: result.fileName ?? ''}
  }

  changeLanguage = async (languageName: string): Promise<void> => {
    await userService.changeLanguage({ languageName: languageName });
  };
}

export default UserStore;
