import React, {useEffect, useState} from "react";
import {Button, Modal, Select} from "antd";
import convert from "convert-units";
import connect from "../../../../stores/connect";
import Warehouse from "../../../../models/Warehouses/Warehouse";
import WarehouseTopology from "../../../../models/Warehouses/WarehouseTopology";
import WarehouseTopologyCollection from "../../../../models/Warehouses/WarehouseTopologyCollection";
import {State} from "../../react-planner/src/models";
import {
  ProjectSettings,
  ProjectSettingsModalNameRow,
  ProjectSettingsModalRow,
  ProjectSettingsModalRowItem,
  ProjectSettingsModalSizeItem, ProjectSettingsModalSizeUnit,
  ProjectSettingsModalSourceRow, ProjectSettingsModalTemperatures,
  ProjectSettingsModalUnitRow, ProjectSettingsModalWarehouseTopologyItem, ProjectSettingsModalWarehouseTypeItem
} from "./ProjectSettingsComponents";
import {UNIT_CENTIMETER, UNIT_METER, UNIT_MILLIMETER} from "../../react-planner/es/constants";
import {EditWarehouseRequestDto, WarehouseDto, WarehouseType} from "../../../../api/swagger";

interface ProjectSettingsProps {
  createEditWarehouseRequestDto: () => EditWarehouseRequestDto | undefined,
  editWarehouse: (warehouse: Warehouse, dto: EditWarehouseRequestDto) => Promise<void>,
  isLinkingWarehouseToTopology: boolean;
  isOpen: boolean;
  linkWarehouseToTopology: (warehouse: Warehouse, topology: WarehouseTopology | null) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  projectActions: object;
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  state: State;
  warehouse: Warehouse;
  warehouseTopologies: WarehouseTopologyCollection;
}

const DisconnectedProjectSettingsModal: React.FC<ProjectSettingsProps> = (
  {
    createEditWarehouseRequestDto,
    editWarehouse,
    isLinkingWarehouseToTopology,
    isOpen,
    linkWarehouseToTopology,
    projectActions,
    setOpen,
    state,
    warehouse,
    warehouseTopologies,
  }
) => {
  const closeModal = () => setOpen(false);
  const [warehouseTopologyId, setWarehouseTopologyId] = useState(warehouse?.externalId);

  const [warehouseName, setWarehouseName] = useState(warehouse ? warehouse.name : '');

  const [isCreatingFromTopology, setCreatingFromTopology] = useState<boolean>(false);

  const [hasTemperatureCondition, setHasTemperatureCondition] = useState<boolean>(!!warehouse.hasTemperatureCondition);

  const [warehouseType, setWarehouseType] = useState<WarehouseType | undefined>(warehouse.type);

  const [temperatureFrom, setTemperatureFrom] = useState<number>(warehouse.minDegreesCelsius ?? -50);
  const [temperatureTo, setTemperatureTo] = useState(warehouse.maxDegreesCelsius ?? 50);

  const scene = state.get('scene');
  const [unit, setUnit] = useState(scene.has('_unit') ? scene.get('_unit') : scene.get('unit'));
  const [height, setHeight] = useState(convert(scene.get('height')).from(scene.get('unit')).to(unit));
  const [width, setWidth] = useState(convert(scene.get('width')).from(scene.get('unit')).to(unit));

  useEffect(() => {
    setUnit(scene.has('_unit') ? scene.get('_unit') : scene.get('unit'));
  }, [scene]);

  useEffect(() => {
    setHeight(convert(scene.get('height')).from(scene.get('unit')).to(unit));
    setWidth(convert(scene.get('width')).from(scene.get('unit')).to(unit));
  }, [scene, unit]);

  useEffect(() => {
    setWarehouseName(warehouse ? warehouse.name : '');
  }, [warehouse]);

  const saveSettings = async () => {
    const topology = warehouseTopologies.findById(warehouseTopologyId);
    if (topology?.externalId !== (warehouse?.externalId || undefined)) {
      if (topology instanceof WarehouseTopology) {
        await linkWarehouseToTopology(warehouse, topology);
      } else {
        await linkWarehouseToTopology(warehouse, null);
      }
    }
    const editWarehouseDto = createEditWarehouseRequestDto();
    // @ts-ignore
    projectActions.setProjectProperties({
      _unit: unit,
      hasTemperatureCondition: hasTemperatureCondition,
      minDegreesCelsius: temperatureFrom,
      maxDegreesCelsius: temperatureTo,
      height: convert(height).from(unit).to(scene.get('unit')),
      width: convert(width).from(unit).to(scene.get('unit')),
    });
    if (editWarehouseDto) {
      await editWarehouse(warehouse, {
        ...editWarehouseDto,
        type: warehouseType,
        hasTemperatureCondition,
        minDegreesCelsius: temperatureFrom,
        maxDegreesCelsius: temperatureTo,
      });
    }
    closeModal();
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      title="Настройки"
      footer={[
        <Button key="ok" type="primary" loading={isLinkingWarehouseToTopology} onClick={saveSettings}>
          Сохранить
        </Button>,
        <Button key="cancel" onClick={closeModal}>Отменить</Button>
      ]}
    >

      <ProjectSettings>
        {!warehouse && (
          <ProjectSettingsModalSourceRow value={isCreatingFromTopology} onChange={setCreatingFromTopology} />
        )}
        <ProjectSettingsModalRow>
          <ProjectSettingsModalNameRow value={warehouseName} onChange={setWarehouseName} />
        </ProjectSettingsModalRow>
        <ProjectSettingsModalRow>
          <ProjectSettingsModalWarehouseTopologyItem
            warehouseTopologies={warehouseTopologies}
            value={warehouseTopologyId}
            onChange={setWarehouseTopologyId}
          />
        </ProjectSettingsModalRow>
        <ProjectSettingsModalRow>
          <ProjectSettingsModalSizeUnit
            height={height}
            onHeightChange={setHeight}
            onUnitChange={setUnit}
            onWidthChange={setWidth}
            unit={unit}
            width={width}
          />
        </ProjectSettingsModalRow>
        <ProjectSettingsModalRow>
          <ProjectSettingsModalWarehouseTypeItem value={warehouseType} onChange={setWarehouseType} />
        </ProjectSettingsModalRow>
        <ProjectSettingsModalRow>
          <ProjectSettingsModalTemperatures
            hasTemperatureCondition={hasTemperatureCondition}
            onHasTemperatureConditionUpdate={setHasTemperatureCondition}
            onTemperatureFromChange={setTemperatureFrom}
            onTemperatureToChange={setTemperatureTo}
            temperatureFrom={temperatureFrom}
            temperatureTo={temperatureTo}
          />
        </ProjectSettingsModalRow>
      </ProjectSettings>
    </Modal>
  );
};

export default connect(({store}) => ({
    createEditWarehouseRequestDto: store.warehouseStore.createEditWarehouseRequestDto,
    editWarehouse: store.warehouseStore.editWarehouse,
    isLinkingWarehouseToTopology: store.warehouseStore.isLinkingWarehouseToTopology,
    linkWarehouseToTopology: store.warehouseStore.linkWarehouseToTopology,
    warehouse: store.warehouseStore.currentWarehouse,
    warehouseTopologies: store.warehouseStore.topologies,

}))(DisconnectedProjectSettingsModal);
