import WarehouseTopologyRack from "./WarehouseTopologyRack";
import Collection from "../../domain/collection";
import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopologyRackCollection extends Collection<WarehouseTopologyRack> {
  findByName(name: string): WarehouseTopologyRack | undefined {
    return this.find(
      (rack) => rack.name === name
    );
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologyRackCollection {
    return new WarehouseTopologyRackCollection(
      this
        .getAll()
        .map(
          (rack: WarehouseTopologyRack) => rack.withoutCells(removingCells)
        )
        .filter(
          (rack: WarehouseTopologyRack) => !rack.shelves.isEmpty()
        )
    );
  }
}
