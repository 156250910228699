"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "+ Add Horizontal Giude": "+ Aggiungi Guida Orizzontale",
  "+ Add Vertical Giude": "+ Aggiungi Guida Verticale",
  "2D View": "Vista 2D",
  "3D First Person": "3D Prima persona",
  "3D View": "Vista 3D",
  "Altitude": "Quota",
  "altitude": "Quota",
  "Areas": "Aree",
  "Cancel": "Annulla",
  "Catalog": "Catalogo",
  "Chain selected Elements to Group": "Collega l'Elemento selzionato al Gruppo",
  "Circular": "Circolare",
  "color": "colore",
  "Configure layer": "Configura livello",
  "Configure project": "Configura progetto",
  "Confirm": "Conferma",
  "Copy": "Copia",
  "Delete group and all Elements": "Elimina Gruppo e tutti i suoi Elementi",
  "Delete layer": "Elimina livello",
  "Delete": "Elimina",
  "Elements on layer {0}": "Elementi nel livello {0}",
  "Elements": "Elementi",
  "Get Screenshot": "Cattura uno screenshot",
  "Group [{0}]": "Gruppo [{0}]",
  "Group's Barycenter": "Baricentro del Gruppo",
  "Group's Elements": "Elementi del Gruppo",
  "Groups": "Gruppi",
  "Guides": "Guide",
  "height": "Altezza",
  "Holes": "Buchi",
  "Horizontal": "Orizzontale",
  "Items": "Oggetti",
  "Last Selected": "Ultimi Selezionati",
  "Layer config": "Configurazione livello",
  "Layer": "Livello",
  "Layers": "Livelli",
  "Length": "Lunghezza",
  "Lines": "Linee",
  "Load project": "Carica progetto",
  "Mouse X Coordinate": "Coordinata X del Mouse",
  "Mouse Y Coordinate": "Coordinata Y del Mouse",
  "Name": "Nome",
  "New Empty Group": "Crea Gruppo Vuoto",
  "New Group from selected": "Crea Gruppo dai selezionati",
  "New layer": "Nuovo livello",
  "New project": "Nuovo progetto",
  "opacity": "Opacità",
  "Open catalog": "Apri catalogo",
  "order": "Ordine",
  "Pan": "Muovi",
  "Paste": "Incolla",
  "Project config": "Configurazione progetto",
  "Properties: [{0}] {1}": "Proprietà: [{0}] {1}",
  "Prototype": "Prototipo",
  "Reset": "Annulla",
  "Rotation": "Rotazione",
  "Save project": "Salva progetto",
  "Save": "Salva",
  "Scene Zoom Level": "Livello di Zoom della Scena",
  "Search Element": "Cerca Elemento",
  "Snap to Grid": "Snap alla Griglia",
  "Snap to Guide": "Snap alla Guida",
  "Snap to Line": "Snap alla Linea",
  "Snap to Point": "Snap al Punto",
  "Snap to Segment": "Snap al Segmento",
  "texture": "texture",
  "thickness": "spessore",
  "Toggle Group Visibility": "Varia la visibilità del Gruppo",
  "Un-chain all Group\'s Elements and remove Group": "Elimina il Gruppo e scollega tutti gli Elementi appartenenti",
  "Un-chain Element from Group": "Scollega l'Elemento dal Gruppo",
  "Undo (CTRL-Z)": "Annulla (CTRL-Z)",
  "Unselect": "Deseleziona",
  "Vertical": "Verticale",
  "width": "Larghezza",
  "Would you want to start a new Project?": "Vuoi iniziare un nuovo progetto?",
  "Zoom in": "Ingrandisci",
  "Zoom out": "Riduci"
};