import {action, observable} from 'mobx';

import {HubConnection, HubConnectionState} from "@microsoft/signalr/dist/esm/HubConnection";
import crypto from "crypto";
import AppConsts from "../lib/appconst";
import utils from "../utils/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let abp: any;

const config = {
  audio: true,
  video: true
}

class WebRTCStore {
  @observable localStream: MediaStream | undefined
  @observable remoteStream: MediaStream | undefined
  @observable isStarted = false;
  @observable isInitiator = false;
  @observable isChannelReady = false;
  @observable isCalling = false;
  @observable callingChats: string[] = [];
  @observable room = '';

  private chatHub: HubConnection | undefined
  private peerConnection: RTCPeerConnection | undefined
  private iceServerConfig: RTCConfiguration | undefined

  initIceServerConfig = (): void => {
    let username: string | undefined,
        password: string | undefined
    if (AppConsts.turnServer && AppConsts.turnSecret) {
      const unixTimeStamp = Math.round(Date.now()/1000 + 24*3600) // this credential would be valid for the next 24 hours
      username = `${unixTimeStamp}:${abp.signalr!.user?.userName}`
      const hmac = crypto.createHmac('sha1', AppConsts.turnSecret);
      hmac.setEncoding('base64');
      hmac.write(username);
      hmac.end();
      password = hmac.read();
    }

    const iceServer: RTCIceServer = {
      urls: AppConsts.turnServer ?? AppConsts.stunServer!,
      username: username,
      credential: password
    }

    this.iceServerConfig = {
      iceServers: [iceServer],
      sdpSemantics: 'unified-plan'
    } as RTCConfiguration
  }

  @action
  async initSignals(attempt = 1): Promise<void> {
    if (this.isSignalRConnected()) {
      this.chatHub = abp.signalr!.hubs?.common
      this.defineSignaling();
      console.log('initSignals done')
    } else if (attempt < 10) {
      await utils.wait(3000)
      await this.initSignals(attempt + 1)
    } else {
      // eslint-disable-next-line no-console
      console.log('Signals is not defined')
    }
  }

  @action
  start = async (room: string): Promise<void> => {
    if (this.isSignalRConnected()) {
      if (!this.chatHub) await this.initSignals()
      if (!this.iceServerConfig) this.initIceServerConfig()
      this.room = room
      await this.createOrJoinRoom()
      await this.getUserMedia()
    }
  }

  createPeerConnection = (): void => {
    try {
      this.peerConnection = new RTCPeerConnection(this.iceServerConfig);

      this.peerConnection.onicecandidate = async (event: RTCPeerConnectionIceEvent) => {
        if (event.candidate) {
          console.log(event.candidate.type, 'candidate', event.candidate)
          await this.sendIceCandidate(event);
        } else {
          // eslint-disable-next-line no-console
          console.log('End of candidates.', event);
        }
      };

      this.peerConnection.ontrack = (event: RTCTrackEvent) => {
        if (event.streams[0]) {
          this.addRemoteStream(event.streams[0]);
        }
      };

      this.peerConnection.onicecandidateerror = (event: RTCPeerConnectionIceErrorEvent) => {
        console.log('!!!RTCPeerConnection error!!!', event)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to create PeerConnection.', e.message);
    }
  }

  defineSignaling(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.chatHub!.on('onMessage', async (message: any): Promise<void> => {
      if (message === 'got user media') {
        await this.tryInitiateCall();

      } else if (message.type === 'offer') {
        console.log('got offer')
        await this.tryInitiateCall();
        await this.setRemoteDescription(message)
        await this.sendAnswer()

      } else if (message.type === 'answer' && this.isStarted) {
        console.log('got answer')
        await this.setRemoteDescription(message)

      } else if (message.candidate && this.isStarted) {
        console.log('got candidate', message)
        await this.addIceCandidate(message);

      } else if (message === 'disconnect') {
        console.log('got disconnect')
        await this.handleHangup()
      }
    })

    this.chatHub!.on('created', () => {
      console.log('room created')
      this.isInitiator = true
    });

    this.chatHub!.on('joined', async () => {
      console.log('room joined')
      this.isChannelReady = true;
    });

    this.chatHub!.on('onCall', (taskId: string) => {
      console.log('got onCall for', taskId)
      if (!(this.isStarted || this.isInitiator)) {
        this.isCalling = true
        this.callingChats = [... this.callingChats, taskId]
      }
    });

    this.chatHub!.on('onCallStop', (taskId: string) => {
      console.log('got onCallStop for', taskId)
      this.callingChats = this.callingChats.filter(x => x !== taskId)
      this.isCalling = Boolean(this.callingChats)
    });

    this.chatHub!.on('busy', (taskId: string) => {
      console.log('got busy for', taskId)
      this.callingChats = this.callingChats.filter(x => x !== taskId)
      this.isCalling = Boolean(this.callingChats)
    });
  }

  tryInitiateCall = async (): Promise<void> => {
    if (!this.isStarted && this.localStream && this.isChannelReady) {
      this.createPeerConnection();

      this.peerConnection!.addTrack(this.localStream.getVideoTracks()[0], this.localStream);
      this.peerConnection!.addTrack(this.localStream.getAudioTracks()[0], this.localStream);

      this.isStarted = true;
      console.log('createPeerConnection is finished')
      if (this.isInitiator) {
        await this.sendOffer();
      }
    }
  }

  async getUserMedia(): Promise<void> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(config)
      this.addLocalStream(stream);
      await this.sendMessage('got user media')
      if (this.isInitiator) {
        await this.tryInitiateCall();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`getUserMedia() error: ${e.name}: ${e.message}`);
    }
  }

  sendOffer = async (): Promise<void> => {
    this.addTransceivers();
    const sdp = await this.peerConnection!.createOffer()
    await this.peerConnection!.setLocalDescription(sdp)
    await this.sendMessage(sdp)
    console.log('offer is send')
  }

  sendAnswer = async (): Promise<void> => {
    this.addTransceivers();
    const sdp = await this.peerConnection!.createAnswer()
    await this.peerConnection!.setLocalDescription(sdp)
    await this.sendMessage(sdp)
    console.log('answer is send')
  }

  setRemoteDescription = async (message: RTCSessionDescriptionInit): Promise<void> => {
    const sdp = new RTCSessionDescription(message)
    await this.peerConnection!.setRemoteDescription(sdp)
    console.log('remoteDescription is set')
  }

  addTransceivers = (): void => {
    const init = { direction: 'recvonly', streams: [], sendEncodings: [] } as RTCRtpTransceiverInit;
    this.peerConnection!.addTransceiver('audio', init);
    this.peerConnection!.addTransceiver('video', init);
  }

  addLocalStream(stream: MediaStream): void {
    this.localStream = stream;
  }

  addRemoteStream(stream: MediaStream): void {
    this.remoteStream = stream;
  }

  addIceCandidate = async (candidate: RTCIceCandidate): Promise<void> => {
    await this.peerConnection!.addIceCandidate(candidate);
  }

  sendIceCandidate = async (event: RTCPeerConnectionIceEvent): Promise<void> => {
    await this.sendMessage(event.candidate)
  }

  @action
  hangup = async (): Promise<void> => {
    this.handleHangup()
    await this.sendMessage('disconnect');
  }

  handleHangup(): void {
    this.stopPeerConnection();
    this.stopMediaTracking()
    this.chatHub?.invoke('LeaveRoom', this.room);
  }

  stopPeerConnection(): void {
    this.isStarted = false;
    this.isChannelReady = false;
    this.isCalling = false;
    this.callingChats = [];
    this.isInitiator = false;
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = undefined;
    }
  }

  stopMediaTracking() {
    if (this.localStream && this.localStream.active) {
      this.localStream.getTracks().forEach((track) => { track.stop(); });
    }
    if (this.remoteStream && this.remoteStream.active) {
      this.remoteStream.getTracks().forEach((track) => { track.stop(); });
    }
    this.localStream = undefined
    this.remoteStream = undefined
  }

  createOrJoinRoom(): Promise<string[]> {
    return this.chatHub!.invoke('CreateOrJoinRoom', this.room)
  }

  async sendMessage(message: unknown): Promise<void> {
    await this.chatHub!.invoke('SendMessage', message, this.room);
  }

  private isSignalRConnected = () : boolean => abp.signalr?.hubs?.common.state === HubConnectionState.Connected
}

export default WebRTCStore;