import React from "react";
import PropTypes from "prop-types";
import {List} from "immutable";
import defaultQrCode from "../../../../catalog/attributes/defaultQrCode.json";
import {UNIT_CENTIMETER, UNITS_LENGTH, WALL_LEFT, WALL_TOP} from "../../../../constants";
import {toFixedFloat} from "../../../../utils/math";
import convert from "convert-units";
import PropertyStyle from "../../../../catalog/properties/shared-property-style";
import FormNumberInput from "../../../style/form-number-input";
import {FormLabel, FormSelect} from "../../../style/export";
import FormTextInput from "../../../style/form-text-input";
import {COLORS} from "../../../../shared-style";
import isStateReadOnly from "../../../../../../utils/isStateReadOnly";
import {
  QrCodeGridCell, QrCodeHeightSelect, QrCodeMarginSelect,
  QrCodes,
  QrCodesGrid,
  QrCodesGridHeaderCell,
  QrCodesHeader,
  QrCodeSideSelect,
  QrCodeActions, AddQrCodeButton, DownloadAllQrCodesButton,
} from "./qrcodes-editor.styled";
import getUnit from "../../../../../../utils/getUnit";
import LengthInput from "../../../../../../components/LengthInput/LengthInput";

const firstTdStyle = { width: '81px', color: COLORS.font, };
const buttonStyle = {backgroundColor: 'black',};
const internalTableStyle = {borderCollapse: 'collapse'};
const secondTdStyle = {padding: 0};
const unitContainerStyle = {width: '5em'};

const QrCodesEditor = ({
  attributeFormData,
  element,
  onUpdate,
  state,
  translator,
  ...rest
}) => {
  const isReadOnly = isStateReadOnly(state);
  const qrCodes = attributeFormData.has('qrCodes') ? attributeFormData.get('qrCodes') : new List();
  const unit = getUnit(state);

  const addQrCode = () => {
    const newQrCode = {...defaultQrCode};
    newQrCode.left = newQrCode.left.defaultValue;
    newQrCode.top = newQrCode.top.defaultValue;
    newQrCode.right = newQrCode.right.defaultValue;
    newQrCode.bottom = newQrCode.bottom.defaultValue;
    newQrCode.leftOrRight = 'left';
    newQrCode.topOrBottom = 'top';
    newQrCode.id = newQrCode.id.defaultValue;
    newQrCode.number = newQrCode.number.defaultValue;
    newQrCode.wall = newQrCode.wall.defaultValue;
    const updatedQrCodes = qrCodes.push(newQrCode);
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const deleteQrCode = (qrCodeKey) => {
    const updatedQrCodes = qrCodes.delete(qrCodeKey);
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateLengthProperty = (value, unit = UNIT_CENTIMETER) => {
    const newLength = toFixedFloat(value);
    return {
      _length: value,
      _unit: unit,
      unit: UNIT_CENTIMETER,
      length: unit !== UNIT_CENTIMETER
        ? convert(newLength).from(unit).to(UNIT_CENTIMETER)
        : newLength,
    };
  };

  const updateQrCodeLeft = (qrCodeKey, value, unit = UNIT_CENTIMETER) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'left'], updateLengthProperty(value, unit));
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateQrCodeTop = (qrCodeKey, value, unit) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'top'], updateLengthProperty(value, unit));
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateQrCodeRight = (qrCodeKey, value, unit = UNIT_CENTIMETER) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'right'], updateLengthProperty(value, unit));
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateQrCodeBottom = (qrCodeKey, value, unit) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'bottom'], updateLengthProperty(value, unit));
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateQrCodeId = (qrCodeKey, value) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'id'], value);
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateQrCodeNumber = (qrCodeKey, value) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'number'], value);
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateQrCodeLeftOrRight = (qrCodeKey, value) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'leftOrRight'], value);
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateQrCodeTopOrBottom = (qrCodeKey, value) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'topOrBottom'], value);
    return onUpdate('qrCodes', updatedQrCodes);
  };

  const updateQrCodeWall = (qrCodeKey, value) => {
    const updatedQrCodes = qrCodes.setIn([qrCodeKey, 'wall'], value);
    return onUpdate('qrCodes', updatedQrCodes);
  };

  return (
    <div className="qr-codes">
      <QrCodes>
        <QrCodesHeader>
          QR-коды навигации ({element.name})
        </QrCodesHeader>
        <QrCodesGrid>
          <QrCodesGridHeaderCell>{translator.t('Identifier')}</QrCodesGridHeaderCell>
          <QrCodesGridHeaderCell>{translator.t('Side')}</QrCodesGridHeaderCell>
          <QrCodesGridHeaderCell>{translator.t('Margin')}, {translator.t(unit)}</QrCodesGridHeaderCell>
          <QrCodesGridHeaderCell>{translator.t('Height')}, {translator.t(unit)}</QrCodesGridHeaderCell>
          <QrCodesGridHeaderCell>act</QrCodesGridHeaderCell>
          {!!qrCodes.size && qrCodes.map(
            (qrCode, qrCodeKey) => (
              <React.Fragment key={`qrcode-${qrCodeKey}`}>
                <QrCodeGridCell>
                  <FormTextInput
                    state={state}
                    style={{
                      height: '34px',
                    }}
                    value={qrCode.id}
                    onChange={event => updateQrCodeId(qrCodeKey, event.target.value)}
                  />
                </QrCodeGridCell>
                <QrCodeGridCell>
                  <QrCodeSideSelect
                    value={qrCode.wall ?? WALL_LEFT}
                    onChange={(wall) => {updateQrCodeWall(qrCodeKey, wall)}}
                    translator={translator}
                  />
                </QrCodeGridCell>
                <QrCodeGridCell>
                  <QrCodeMarginSelect
                    onMarginChange={(margin) => updateQrCodeLeftOrRight(qrCodeKey, margin)}
                    onLengthChange={
                      (length) => qrCode.leftOrRight === WALL_LEFT
                        ? updateQrCodeLeft(qrCodeKey, length, qrCode.left._unit)
                        : updateQrCodeRight(qrCodeKey, length, qrCode.right._unit)
                    }
                    state={state}
                    translator={translator}
                    lengthValue={
                      qrCode.leftOrRight === WALL_LEFT
                        ? qrCode.left._length
                        : qrCode.right._length
                    }
                    marginValue={qrCode.leftOrRight}
                  />
                </QrCodeGridCell>
                <QrCodeGridCell>
                  <QrCodeHeightSelect
                    onHeightChange={(height) => updateQrCodeTopOrBottom(qrCodeKey, height)}
                    onLengthChange={
                      (length) => qrCode.topOrBottom === WALL_TOP
                        ? updateQrCodeTop(qrCodeKey, length, qrCode.top._unit)
                        : updateQrCodeBottom(qrCodeKey, length, qrCode.bottom._unit)
                    }
                    state={state}
                    translator={translator}
                    lengthValue={
                      qrCode.topOrBottom === WALL_TOP
                        ? qrCode.top._length
                        : qrCode.bottom._length
                    }
                    heightValue={qrCode.topOrBottom}
                  />
                </QrCodeGridCell>
                <QrCodeGridCell alignSelf={'center'}>
                  <QrCodeActions
                    translator={translator}
                    onDelete={() => deleteQrCode(qrCodeKey)}
                  />
                </QrCodeGridCell>
              </React.Fragment>
            )
          )}
          <AddQrCodeButton
            onAddQrCode={addQrCode}
            translator={translator}
          />
        </QrCodesGrid>
        <div style={{
          flexGrow: 1
        }} />
        <DownloadAllQrCodesButton
          onClick={() => {}}
          translator={translator}
        />
      </QrCodes>
      {/*<table className="attributes-table">
        <tbody>
        {!!qrCodes.size && (
          <tr>
            <td colSpan={2}>{ translator.t('QR Codes') }</td>
          </tr>
        )}
        {!!qrCodes.size && qrCodes.map(
          (qrCode, qrCodeKey) => (
            <tr key={qrCodeKey}>
              <td colSpan={2}>
                <table style={PropertyStyle.tableStyle}>
                  <tbody>
                  <tr>
                    <td style={firstTdStyle}>
                      <FormSelect
                        state={state}
                        value={qrCode.leftOrRight}
                        onChange={event => updateQrCodeLeftOrRight(qrCodeKey, event.target.value) }
                      >
                        <option key={'left'} value={'left'}>{translator.t('left')}</option>
                        <option key={'right'} value={'right'}>{translator.t('right')}</option>
                      </FormSelect>
                    </td>
                    <td style={secondTdStyle}>
                      <table style={internalTableStyle}>
                        <tbody>
                        <tr>
                          {qrCode.leftOrRight === 'left' && (
                            <>
                              <td>
                                <FormNumberInput
                                  state={state}
                                  value={qrCode.left._length}
                                  onChange={event => updateQrCodeLeft(qrCodeKey, event.target.value, qrCode.left._unit)}
                                  {...rest}

                                />
                              </td>
                              <td style={unitContainerStyle}>
                                <FormSelect
                                  state={state}
                                  value={qrCode.left._unit}
                                  onChange={event => updateQrCodeLeft(qrCodeKey, qrCode.left._length, event.target.value) }
                                >
                                  {
                                    UNITS_LENGTH.map(el => <option key={el} value={el}>{translator.t(el)}</option>)
                                  }
                                </FormSelect>
                              </td>
                            </>
                          )}
                          {qrCode.leftOrRight === 'right' && (
                            <>
                              <td>
                                <FormNumberInput
                                  state={state}
                                  value={qrCode.right._length}
                                  onChange={event => updateQrCodeRight(qrCodeKey, event.target.value, qrCode.right._unit)}
                                  {...rest}
                                />
                              </td>
                              <td style={unitContainerStyle}>
                                <FormSelect
                                  state={state}
                                  value={qrCode.right._unit}
                                  onChange={event => updateQrCodeRight(qrCodeKey, qrCode.right._length, event.target.value) }
                                >
                                  {
                                    UNITS_LENGTH.map(el => <option key={el} value={el}>{translator.t(el)}</option>)
                                  }
                                </FormSelect>
                              </td>
                            </>
                          )}
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={firstTdStyle}>

                      <FormSelect
                        state={state}
                        value={qrCode.topOrBottom}
                        onChange={event => updateQrCodeTopOrBottom(qrCodeKey, event.target.value) }
                      >
                        <option key={'top'} value={'top'}>{translator.t('top')}</option>
                        <option key={'bottom'} value={'bottom'}>{translator.t('bottom')}</option>
                      </FormSelect>
                    </td>
                    <td style={secondTdStyle}>
                      <table style={internalTableStyle}>
                        <tbody>
                        <tr>
                          {qrCode.topOrBottom === 'top' && (
                            <>
                              <td>
                                <FormNumberInput
                                  state={state}
                                  value={qrCode.top._length}
                                  onChange={event => updateQrCodeTop(qrCodeKey, event.target.value, qrCode.top._unit)}
                                  {...rest}
                                />
                              </td>
                              <td style={unitContainerStyle}>
                                <FormSelect
                                  state={state}
                                  value={qrCode.top._unit}
                                  onChange={event => updateQrCodeTop(qrCodeKey, qrCode.top._length, event.target.value) }
                                >
                                  {
                                    UNITS_LENGTH.map(el => <option key={el} value={el}>{translator.t(el)}</option>)
                                  }
                                </FormSelect>
                              </td>
                            </>
                          )}
                          {qrCode.topOrBottom === 'bottom' && (
                            <>
                              <td>
                                <FormNumberInput
                                  state={state}
                                  value={qrCode.bottom._length}
                                  onChange={event => updateQrCodeBottom(qrCodeKey, event.target.value, qrCode.bottom._unit)}
                                  {...rest}
                                />
                              </td>
                              <td style={unitContainerStyle}>
                                <FormSelect
                                  state={state}
                                  value={qrCode.bottom._unit}
                                  onChange={event => updateQrCodeBottom(qrCodeKey, qrCode.bottom._length, event.target.value) }
                                >
                                  {
                                    UNITS_LENGTH.map(el => <option key={el} value={el}>{translator.t(el)}</option>)
                                  }
                                </FormSelect>
                              </td>
                            </>
                          )}
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={firstTdStyle}><FormLabel>{translator.t('id')}</FormLabel></td>
                    <td>
                      <FormTextInput
                        state={state}
                        value={qrCode.id}
                        onChange={event => updateQrCodeId(qrCodeKey, event.target.value)}/>
                    </td>
                  </tr>
                  <tr>
                    <td style={firstTdStyle}><FormLabel>{translator.t('number')}</FormLabel></td>
                    <td>
                      <FormTextInput
                        state={state}
                        value={qrCode.number}
                        onChange={event => updateQrCodeNumber(qrCodeKey, event.target.value)}/>
                    </td>
                  </tr>
                  {!isReadOnly && (
                    <tr>
                      <td style={{textAlign: 'center',}} colSpan={2}>
                        <button style={ buttonStyle } onClick={ () => deleteQrCode(qrCodeKey) }>
                          { translator.t('Delete') }
                        </button>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </td>
            </tr>
          )
        )}
        {!isReadOnly && (
          <tr>
            <td colSpan={2} style={{textAlign: 'center',}}>
              <button style={buttonStyle} onClick={addQrCode}>
                {translator.t('Add QR Code')}
              </button>
            </td>
          </tr>
        )}
        </tbody>
      </table>*/}
    </div>
  );
};

QrCodesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};

export default QrCodesEditor;
