/**
 * This function can return true for the NaN value only
 *
 * @param {any} value
 * @returns {boolean}
 */
// eslint-disable-next-line no-self-compare
const valueIsEqualToNaN = (value) => value !== value;

const checkIsNumber: (value: any) => boolean = (value) => {
    return !valueIsEqualToNaN(value)
        && (
            typeof value === 'number'
            || value instanceof Number
        )
    ;
};

export default checkIsNumber;
