import WarehouseTopologyLineCollection from "./WarehouseTopologyLineCollection";
import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopologySection {
  constructor(
    readonly name: string | undefined,
    readonly lines: WarehouseTopologyLineCollection,
  ) {
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologySection {
    return new WarehouseTopologySection(
      this.name,
      this.lines.withoutCells(removingCells),
    );
  }
}
