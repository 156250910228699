import React from "react";
import {InputNumber} from "antd";
import convert from "convert-units";
import {UNIT_CENTIMETER, UNIT_METER, UNIT_MILLIMETER} from "../../react-planner/src/constants";
import {State} from "../../react-planner/src/models";
import getUnit from "../../utils/getUnit";
import getPrecision from "../../utils/getPrecision";

interface LengthInputProps {
  addonBefore?: React.ReactNode,
  addonAfter?: React.ReactNode,
  disabled?: boolean;
  onChange: (value: number) => void;
  showUnit?: boolean;
  state: State;
  translator: {
    t: (value: string) => string;
  };
  unit?: string;
  value: number;
}

const LengthInput: React.FC<LengthInputProps> = (
  {
    addonBefore = undefined,
    addonAfter = undefined,
    disabled = false,
    onChange,
    state,
    showUnit = true,
    translator,
    unit = UNIT_CENTIMETER,
    value,
  }
) => {
  const projectUnit = getUnit(state);
  const projectUnitTranslated = translator.t(projectUnit);
  let sizeInputStep = 0.1;
  const precision = getPrecision(projectUnit);
  let regexp = /(\d+([\.\,])?(\d{0,2})?)/;
  if (projectUnit === UNIT_CENTIMETER) {
    sizeInputStep = 0.1;
    regexp = /(\d+([\.\,])?(\d{0,1})?)/;
  } else if (projectUnit === UNIT_MILLIMETER) {
    sizeInputStep = 1;
    regexp = /(\d+)/;
  } else if (projectUnit === UNIT_METER) {
    sizeInputStep = 0.01;
    regexp = /(\d+([\.\,])?(\d{0,2})?)/;
  }
  const length = convert(value).from(unit).to(projectUnit).toFixed(precision);
  const onLengthUpdate = (length: string | number | undefined) => {
    if (!length) {
      onChange(0);
      return;
    }
    const newDimension = parseFloat(length.toString());
    onChange(convert(newDimension).from(projectUnit).to(unit));
  };

  const formatValue = showUnit
    ? val => `${val} ${projectUnitTranslated}`
    : val => val
  ;

  const parseValue = (inputValue: string | undefined): string => {
    if (!inputValue) {
      return '';
    }

    const matchResult = inputValue.match(regexp);
    return !matchResult?.length
      ? ''
      : matchResult[0]
    ;
  };

  return (
    <div className="length-input">
      {addonBefore && (<>{addonBefore}</>)}
      <InputNumber
        disabled={disabled}
        defaultValue={0}
        value={length}
        min={0}
        step={sizeInputStep}
        onChange={onLengthUpdate}
        prefix={translator.t(projectUnit)}
        formatter={formatValue}
        parser={parseValue}
      />
      {addonAfter && (<>{addonAfter}</>)}
    </div>
  );
};

export default LengthInput;
