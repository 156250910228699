import {TaskStateEnum, TaskStateHistoryDto} from "../../api/swagger";

class TaskStateHistoryModel {
  id: string
  taskId: string
  userId: number
  state: TaskStateEnum
  updateTime: Date

  constructor(taskStateHistory: TaskStateHistoryDto) {
    this.id = taskStateHistory.id!
    this.taskId = taskStateHistory.taskId!
    this.userId = taskStateHistory.userId!
    this.state = taskStateHistory.state!
    this.updateTime = taskStateHistory.updateTime!
  }
}

export default TaskStateHistoryModel;
