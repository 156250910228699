import React, {useState} from "react";
import {Button, Modal, Select} from "antd";
import DropdownButton from "./DropdownButton";
import './DropdownButton.less';
import StairsIcon from "../Svg/StairsIcon";
import LadderIcon from "../Svg/LadderIcon";
import RackIcon from "../Svg/RackIcon";
import RoundColumnIcon from "../Svg/RoundColumnIcon";
import FloorAreaBorderIcon from "../Svg/FloorAreaBorderIcon";
import WallIcon from "../Svg/WallIcon";
import SquareColumnIcon from "../Svg/SquareColumnIcon";
import DoorIcon from "../Svg/DoorIcon";
import WindowIcon from "../Svg/WindowIcon";
import HamburgerButton from "./HamburgerButton";
import {Project} from "../../scenes/Planner/react-planner/src/class/export";
import {browserDownload, browserUpload} from "../../scenes/Planner/react-planner/src/utils/browser";
import {State} from "../../scenes/Planner/react-planner/src/models";
import {setAlterateState, setReadOnly} from "../../scenes/Planner/react-planner/src/actions/project-actions";
import connect from "../../stores/connect";
import EditWarehouseSchemaJsonInputDto from "../../stores/dto/EditWarehouseSchemaJsonInputDto";
import {WarehouseSchemaDto} from "../../api/swagger";
import WarehouseSchema from "../../models/Warehouses/WarehouseSchema";
import isStateReadOnly from "../../scenes/Planner/utils/isStateReadOnly";
import Warehouse from "../../models/Warehouses/Warehouse";
import WarehouseTopologyCollection from "../../models/Warehouses/WarehouseTopologyCollection";
import WarehouseTopology from "../../models/Warehouses/WarehouseTopology";
import ProjectSettingsModal from "../../scenes/Planner/components/ProjectSettingsModal/ProjectSettingsModal";
import ArrowIcon from "../Svg/ArrowIcon";
import {MODE_IDLE} from "../../scenes/Planner/react-planner/src/constants";
import {selectTool3DFirstPerson} from "../../scenes/Planner/react-planner/src/actions/viewer3d-actions";

interface ISkladarToolbarProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  catalog: object;
  createWarehouseSchemaDraft: (schema: WarehouseSchema) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  holesActions: object;
  isCreatingWarehouseSchemaDraft: boolean;
  isSettingWarehouseSchemaActive: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  itemsActions: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  linesActions: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  projectActions: object;
  saveWarehouseSchemaJson: (input: EditWarehouseSchemaJsonInputDto) => Promise<WarehouseSchemaDto>;
  setWarehouseSchema: (schema: WarehouseSchema|undefined) => void;
  setWarehouseSchemaActive: (schema: WarehouseSchema|undefined) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  store: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  state: State;
  // eslint-disable-next-line @typescript-eslint/ban-types
  viewer3DActions: object,
  warehouse: Warehouse;
  warehouseSchema: WarehouseSchema;
}

const SkladarToolbar: (props: ISkladarToolbarProps) => JSX.Element = ({
  catalog,
  createWarehouseSchemaDraft,
  holesActions,
  isCreatingWarehouseSchemaDraft,
  isSettingWarehouseSchemaActive,
  itemsActions,
  linesActions,
  projectActions,
  saveWarehouseSchemaJson,
  setWarehouseSchema,
  setWarehouseSchemaActive,
  store,
  state,
  viewer3DActions,
  warehouse,
  warehouseSchema,
}) => {
  // @ts-ignore
  const rootCategory = catalog.getCategory('root');
  // @ts-ignore
  const doors = catalog.getCategory('doors');
  // @ts-ignore
  const windows = catalog.getCategory('windows');
  const elementsToDisplay = {
    'root': rootCategory.elements.filter(element => element.info.visibility ? element.info.visibility.catalog : true ),
    'doors': doors.elements.filter(element => element.info.visibility ? element.info.visibility.catalog : true ),
    'windows': windows.elements.filter(element => element.info.visibility ? element.info.visibility.catalog : true ),
  };

  const findElement = (elementName: string, categoryName = 'root') => elementsToDisplay[categoryName].find(
    (item) => item.name === elementName
  );

  const roundColumn = findElement('round column');
  const rack = findElement('rack');
  const wall = findElement('wall');
  const floorAreaBorder = findElement('PoleAreaBorder');
  const squareColumn = findElement('square column');
  const simpleStair = findElement('simple-stair');
  const door = findElement('door', 'doors');
  const window = findElement('window', 'windows');
  const moveDirection = findElement('move direction');

  const toggleProjectAlterateState = () => {
    // @ts-ignore
    store.dispatch(setAlterateState());
  };

  const toggleReadOnly = () => {
    const isReadOnly = state.get('readOnly');
    // @ts-ignore
    projectActions.unselectAll();
    // @ts-ignore
    store.dispatch(setReadOnly(!isReadOnly));
  };

  const run2dView = () => {
    // @ts-ignore
    store.dispatch(projectActions.setMode( MODE_IDLE ));
  };
  const run3dView = () => {
    // @ts-ignore
    store.dispatch(viewer3DActions.selectTool3DView());
  };
  const run3dViewFirstPerson = () => {
    // @ts-ignore
    store.dispatch(viewer3DActions.selectTool3DFirstPerson());
  };

  const addElement = (element) => {
    // @ts-ignore
    switch (element.prototype) {
      case 'lines':
        // @ts-ignore
        linesActions.selectToolDrawingLine(element.name);
        break;
      case 'items':
        // @ts-ignore
        itemsActions.selectToolDrawingItem(element.name);
        break;
      case 'holes':
        // @ts-ignore
        holesActions.selectToolDrawingHole(element.name);
        break;
      default:
        break;
    }
  };

  const addRoundColumn = () => {
    addElement(roundColumn);
  };
  const addRack = () => {
    addElement(rack);
  };

  const saveProjectToFile = e => {
    e.preventDefault();
    state = Project.unselectAll( state ).updatedState;
    browserDownload(state.get('scene').toJS());
  };

  const loadProjectFromFile = event => {
    event.preventDefault();
    browserUpload().then((data) => {
      // @ts-ignore
      projectActions.loadProject(JSON.parse(data));
    });
  };

  const saveProjectToBackend = async (event) => {
    event.preventDefault();

    state = Project.unselectAll( state ).updatedState;
    const json = state.get('scene').toJS();
    await saveWarehouseSchemaJson({
      warehouseSchema: (warehouseSchema as unknown as WarehouseSchemaDto),
      json
    });
  };

  const closeProject = () => {
    setWarehouseSchema(undefined);
  };

  const setActive = async () => {
    await setWarehouseSchemaActive(warehouseSchema);
    closeProject();
  };

  const [isConfirmMakeActiveModalOpen, setConfirmMakeActiveModalOpen] = useState(false);
  const [isMadeActiveModalOpen, setMadeActiveModalOpen] = useState(false);

  const startMakingActive = () => {
    setConfirmMakeActiveModalOpen(true);
  };

  const processMakingActive = async () => {
    await setWarehouseSchemaActive(warehouseSchema);
    // @ts-ignore
    projectActions.setReadOnly(true);
    setConfirmMakeActiveModalOpen(false);
    setMadeActiveModalOpen(true);
  };

  const [isCreateDraftModalOpen, setCreateDraftModalOpen] = useState(false);
  const startCreatingDraft = () => {
    setCreateDraftModalOpen(true);
  };

  const processCreatingDraft = async () => {
    await createWarehouseSchemaDraft(warehouseSchema);
    // @ts-ignore
    projectActions.setReadOnly(false);
    // @ts-ignore
    projectActions.unselectAll();
    setCreateDraftModalOpen(false);
  };

  const [isProjectSettingsModalOpen, setProjectSettingsModalOpen] = useState(false);

  const startLinkingWarehouseToTopology = () => {
    setProjectSettingsModalOpen(true);
  };

  const isReadOnly = isStateReadOnly(state);

  return (
    <div className="skladar-toolbar">
      <HamburgerButton dropdownWidth={350}>
        <button type="button" className="button" onClick={saveProjectToBackend}>Сохранить</button>
        <button type="button" className="button" onClick={closeProject}>Закрыть</button>
        <button type="button" className="button" onClick={saveProjectToFile}>Скачать JSON</button>
        <button type="button" className="button" onClick={loadProjectFromFile}>Загрузить из JSON</button>
        {!warehouseSchema?.isActive && (
          <button type="button" className="button" onClick={startMakingActive}>Опубликовать</button>
        )}
        {isReadOnly && (
          <button type="button" className="button" onClick={startCreatingDraft}>Редактировать</button>
        )}
        <button type="button" className="button">История версий</button>
        <div className="divider" />
        <div className="button">
          <label htmlFor="alterate-state-checkbox" className="for-checkbox">
            <input
              id="alterate-state-checkbox"
              type="checkbox"
              className="checkbox"
              checked={state.get('alterate')}
              onChange={toggleProjectAlterateState}
            />
            Выделять несколько объектов
          </label>
        </div>
        <div className="divider" />
        <div className="button">
          <label htmlFor="readonly-state-checkbox" className="for-checkbox">
            <input
              id="readonly-state-checkbox"
              type="checkbox"
              className="checkbox"
              checked={state.get('readOnly')}
              onChange={toggleReadOnly}
            />
            Только для чтения
          </label>
        </div>
        <div className="divider" />
        <button type="button" className="button">Дублировать как новый план</button>
        <button onClick={startLinkingWarehouseToTopology} type="button" className="button">Настройки</button>
        <div className="divider" />
        <button onClick={run2dView} type="button" className="button">2D режим</button>
        <button onClick={run3dView} type="button" className="button">3D режим</button>
        <button onClick={run3dViewFirstPerson} type="button" className="button">3D от первого лица</button>
        <div className="divider" />
        <button type="button" className="button delete-version-button">Удалить</button>
      </HamburgerButton>

      <div className="project-name">
        <input readOnly value={warehouse?.name || ''} type="text" className="input project-name-input" />
        {warehouseSchema?.isActive && (
          <div className="project-name-status active">Используется</div>
        )}
        {!warehouseSchema?.isActive && warehouseSchema?.isDraft && (
          <div className="project-name-status draft">Черновик</div>
        )}
        {!warehouseSchema?.isActive && !warehouseSchema?.isDraft && (
          <div className="project-name-status draft">Не используется</div>
        )}
      </div>
      <div className="placeholder" />
      {!isReadOnly && (
        <>
          <button type="button" onClick={addRack} className="button back-button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_645_2805)">
                <mask id="mask0_645_2805" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <path d="M14.5743 7.30522H6.06827L9.24096 4.13253L8.10843 3L3 8.10843L8.10843 13.2169L9.24096 12.0843L6.06827 8.91165H14.5743C17.2329 8.91165 19.3936 11.0723 19.3936 13.7309C19.3936 16.3896 17.2329 18.5502 14.5743 18.5502H11.3614V20.1566H14.5743C18.1165 20.1566 21 17.2731 21 13.7309C21 10.1888 18.1165 7.30522 14.5743 7.30522Z" fill="black" />
                </mask>
                <g mask="url(#mask0_645_2805)">
                  <rect width="24" height="24" fill="black" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_645_2805">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button type="button" onClick={addRoundColumn} className="button next-button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_645_2812)">
                <mask id="mask0_645_2812" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <path d="M9.4257 7.30522H17.9317L14.759 4.13253L15.8916 3L21 8.10843L15.8916 13.2169L14.759 12.0843L17.9317 8.91165H9.4257C6.76707 8.91165 4.60643 11.0723 4.60643 13.7309C4.60643 16.3896 6.76707 18.5502 9.4257 18.5502H12.6386V20.1566H9.4257C5.88353 20.1566 3 17.2731 3 13.7309C3 10.1888 5.88353 7.30522 9.4257 7.30522Z" fill="black" />
                </mask>
                <g mask="url(#mask0_645_2812)">
                  <rect width="24" height="24" fill="black" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_645_2812">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <div className="placeholder" />
          <DropdownButton dropdownWidth="300px" type="wall">
            <button type="button" className="button" onClick={() => addElement(floorAreaBorder)}>
              <FloorAreaBorderIcon />
              <div>Граница зоны напольного хранения</div>
            </button>
            <button type="button" className="button" onClick={() => addElement(wall)}>
              <WallIcon />
              <div>Стена</div>
            </button>
          </DropdownButton>
          <DropdownButton type="obstacle" dropdownWidth="200px">
            <button type="button" className="button" onClick={() => addElement(roundColumn)}>
              <RoundColumnIcon />
              <div>Круглая колонна</div>
            </button>
            <button type="button" className="button" onClick={() => addElement(squareColumn)}>
              <SquareColumnIcon />
              <div>Квадратная колонна</div>
            </button>
          </DropdownButton>
          <DropdownButton dropdownWidth="220px" type="stairs">
            <button type="button" className="button">
              <StairsIcon />
              <div>Марш</div>
            </button>
            <button type="button" className="button" onClick={() => addElement(simpleStair)}>
              <LadderIcon />
              <div>Лестница</div>
            </button>
            <button type="button" className="button" onClick={() => addElement(moveDirection)}>
              <ArrowIcon />
              <div>Направление движения</div>
            </button>
          </DropdownButton>
          <DropdownButton type="hole" dropdownWidth={'100px'}>
            <button type="button" className="button" onClick={() => addElement(door)}>
              <DoorIcon />
              <div>Дверь</div>
            </button>
            <button type="button" className="button" onClick={() => addElement(window)}>
              <WindowIcon />
              <div>Окно</div>
            </button>
          </DropdownButton>
          <DropdownButton type="rack">
            <button type="button" className="button" onClick={() => addElement(rack)}>
              <RackIcon />
              <div>Стеллаж</div>
            </button>
          </DropdownButton>
        </>
      )}
      <div className="placeholder-full" />
      <div className="skladar-toolbar-additional">
        {warehouseSchema?.isActive && (
          <Button type="primary" onClick={startCreatingDraft} ghost>
            Редактировать
          </Button>
        )}
        {!warehouseSchema?.isActive && (
          <Button type="primary" ghost onClick={startMakingActive}>
            Опубликовать
          </Button>
        )}
        <Modal
          // @ts-ignore
          visible={isConfirmMakeActiveModalOpen}
          onCancel={() => setConfirmMakeActiveModalOpen(false)}
          title="Опубликовать актуальную версию плана?"
          footer={[
            <Button key="ok" type="primary" loading={isSettingWarehouseSchemaActive} onClick={processMakingActive}>
              Опубликовать
            </Button>,
            <Button key="cancel" onClick={() => setConfirmMakeActiveModalOpen(false)}>Отменить</Button>
          ]}
        />
        <Modal
          // @ts-ignore
          visible={isMadeActiveModalOpen}
          onCancel={() => setMadeActiveModalOpen(false)}
          title="Готово."
          footer={[
            <Button type="primary" onClick={() => setMadeActiveModalOpen(false)}>Понятно</Button>
          ]}
        >
          Теперь эта версия используется в процессах автоматизации склада. Чтобы внести изменения, создайте черновик
          на основе актуальной версии.
        </Modal>
        <Modal
          // @ts-ignore
          visible={isCreateDraftModalOpen}
          onCancel={() => {setCreateDraftModalOpen(false)}}
          title="Создать черновик на основе текущей версии плана?"
          footer={[
            <Button key="ok" type="primary" loading={isCreatingWarehouseSchemaDraft} onClick={processCreatingDraft}>
              Создать
            </Button>,
            <Button key="cancel" onClick={() => setCreateDraftModalOpen(false)}>Отменить</Button>
          ]}
        />
        <ProjectSettingsModal
          isOpen={isProjectSettingsModalOpen}
          setOpen={setProjectSettingsModalOpen}
          projectActions={projectActions}
          state={state}
        />
      </div>
    </div>
  );
};

export default connect(({store}) => ({
  createWarehouseSchemaDraft: store.warehouseStore.createWarehouseSchemaDraft,
  isCreatingWarehouseSchemaDraft: store.warehouseStore.isCreatingWarehouseSchemaDraft,
  isSettingWarehouseSchemaActive: store.warehouseStore.isSettingWarehouseSchemaActive,
  saveWarehouseSchemaJson: store.warehouseStore.saveWarehouseSchemaJson,
  setWarehouseSchema: store.warehouseStore.setCurrentWarehouseSchema,
  setWarehouseSchemaActive: store.warehouseStore.setWarehouseSchemaActive,
  warehouse: store.warehouseStore.currentWarehouse,
  warehouseSchema: store.warehouseStore.currentWarehouseSchema,
}))(SkladarToolbar);
