import WarehouseTopologyShelfCollection from "./WarehouseTopologyShelfCollection";
import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopologyRack {
  constructor(
    readonly warehouseId: string | undefined,
    readonly name: string | undefined,
    readonly systemName: string | undefined,
    readonly workingArea: string | undefined,
    readonly section: string | undefined,
    readonly line: string | undefined,
    readonly shelves: WarehouseTopologyShelfCollection,
  ) {

  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologyRack {
    return new WarehouseTopologyRack(
      this.warehouseId,
      this.name,
      this.systemName,
      this.workingArea,
      this.section,
      this.line,
      this.shelves.withoutCells(removingCells),
    );
  }

}
