import React from "react";
import {Input, InputNumber, Radio, Select, Switch} from "antd";
import {UNIT_CENTIMETER, UNIT_METER, UNIT_MILLIMETER} from "../../react-planner/es/constants";
import WarehouseTopologyCollection from "../../../../models/Warehouses/WarehouseTopologyCollection";
import WarehouseTopology from "../../../../models/Warehouses/WarehouseTopology";
import {WarehouseType} from "../../../../api/swagger";

interface ContainerProps {
  children: React.ReactNode;
}

export const ProjectSettings: React.FC<ContainerProps> = ({
  children,
}) => {
  return (
    <div className="project-settings-modal">
      {children}
    </div>
  );
};

export const ProjectSettingsModalRow: React.FC<ContainerProps> = (
  {
    children,
  }
) => {
  return (
    <div className="project-settings-modal-row">
      {children}
    </div>
  );
};

export const ProjectSettingsModalRowItem: React.FC<ContainerProps> = (
  {
    children,
  }
) => {
  return (
    <div className="project-settings-modal-row-item">
      {children}
    </div>
  );
};

export const ProjectSettingsModalRowItemTitle: React.FC<ContainerProps> = ({
  children,
}) => {
  return (
    <div className="project-settings-modal-row-item-title">
      {children}
    </div>
  );
};

export const ProjectSettingsModalRowItemContent: React.FC<ContainerProps> = (
  {
    children,
  }
) => {
  return (
    <div className="project-settings-modal-row-item-content">
      {children}
    </div>
  );
};

interface ProjectSettingsModalTypeInputProps<T> {
  value: T;
  onChange: (value: T) => void;
}

type ProjectSettingsModalInputProps = ProjectSettingsModalTypeInputProps<boolean>

export const ProjectSettingsModalNameRow: React.FC<ProjectSettingsModalTypeInputProps<string | undefined>> = ({
  value,
  onChange,
}) => {
  return (
    <ProjectSettingsModalRowItem>
      <ProjectSettingsModalRowItemTitle>
        Название
      </ProjectSettingsModalRowItemTitle>
      <ProjectSettingsModalRowItemContent>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </ProjectSettingsModalRowItemContent>
    </ProjectSettingsModalRowItem>
  );
};

export const ProjectSettingsModalSourceRow: React.FC<ProjectSettingsModalInputProps> = ({
  value,
  onChange,
}) => {
  return (
    <Radio.Group
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="project-settings-modal-row warehouse-source"
    >
      <Radio.Button className="warehouse-source-raw" value={false}>
        Новый склад
      </Radio.Button>
      <Radio.Button className="warehouse-source-topology" value>
        Из топологии 1С
      </Radio.Button>
    </Radio.Group>
  );
};

export const ProjectSettingsModalUnitRow: React.FC<ProjectSettingsModalTypeInputProps<string>> = (
  {
    value,
    onChange,
  }
) => {
  return (
    <ProjectSettingsModalRowItem>
      <ProjectSettingsModalRowItemTitle>
        Единицы измерения
      </ProjectSettingsModalRowItemTitle>
      <ProjectSettingsModalRowItemContent>
        <Radio.Group
          onChange={(e) => onChange(e.target.value)}
          defaultValue={UNIT_CENTIMETER}
          value={value}
          buttonStyle="solid"
        >
          <Radio.Button value={UNIT_MILLIMETER}>мм</Radio.Button>
          <Radio.Button value={UNIT_CENTIMETER}>см</Radio.Button>
          <Radio.Button value={UNIT_METER}>м</Radio.Button>
        </Radio.Group>
      </ProjectSettingsModalRowItemContent>
    </ProjectSettingsModalRowItem>
  );
};

interface ProjectSettingsSizeItemProps {
  height: number;
  onHeightChange: (value: number) => void;
  onWidthChange: (value: number) => void;
  sizeInputStep: number;
  width: number;
}

export const ProjectSettingsModalSizeItem: React.FC<ProjectSettingsSizeItemProps> = (
  {
    height,
    onHeightChange,
    onWidthChange,
    sizeInputStep,
    width,
  }
) => {
  const onSizeDimensionChange = (value: string | number | undefined, setFn: (size: number) => void) => {
    if (!value) {
      setFn(0);
      return;
    }
    const newDimension = parseFloat(value.toString());
    setFn(newDimension);
  };

  const handleWidthChange = (value: string | number | undefined) => onSizeDimensionChange(value, onWidthChange);
  const handleHeightChange = (value: string | number | undefined) => onSizeDimensionChange(value, onHeightChange);
  return (
    <ProjectSettingsModalRowItem>
      <ProjectSettingsModalRowItemTitle>
        Размер
      </ProjectSettingsModalRowItemTitle>
      <ProjectSettingsModalRowItemContent>
        <div>
          <InputNumber
            defaultValue={3000}
            value={width}
            min={0}
            step={sizeInputStep}
            onChange={handleWidthChange}
          />
        </div>
        <div>
          x
        </div>
        <div>
          <InputNumber
            defaultValue={2000}
            value={height}
            min={0}
            step={sizeInputStep}
            onChange={handleHeightChange}
          />
        </div>
      </ProjectSettingsModalRowItemContent>
    </ProjectSettingsModalRowItem>
  );
};

interface ProjectSettingsModalSizeUnitProps {
  height: number;
  onHeightChange: (value: number) => void;
  onUnitChange: (value: string) => void;
  onWidthChange: (value: number) => void;
  unit: string,
  width: number;
}

export const ProjectSettingsModalSizeUnit: React.FC<ProjectSettingsModalSizeUnitProps> = (
  {
    height,
    onHeightChange,
    onUnitChange,
    onWidthChange,
    unit,
    width,
  }
) => {
  let sizeInputStep = 0.1;
  if (unit === UNIT_CENTIMETER) {
    sizeInputStep = 0.1;
  } else if (unit === UNIT_MILLIMETER) {
    sizeInputStep = 1;
  } else if (unit === UNIT_METER) {
    sizeInputStep = 0.01;
  }

  return (
    <>
      <ProjectSettingsModalRowItem>
        <ProjectSettingsModalUnitRow value={unit} onChange={onUnitChange} />
      </ProjectSettingsModalRowItem>
      <ProjectSettingsModalSizeItem
        height={height}
        onHeightChange={onHeightChange}
        onWidthChange={onWidthChange}
        sizeInputStep={sizeInputStep}
        width={width}
      />
    </>
  )
};

interface ProjectSettingsModalWarehouseTopologyRowProps extends ProjectSettingsModalTypeInputProps<string | undefined> {
  warehouseTopologies: WarehouseTopologyCollection;
}

export const ProjectSettingsModalWarehouseTopologyItem: React.FC<ProjectSettingsModalWarehouseTopologyRowProps> = (
  {
    value,
    onChange,
    warehouseTopologies,
  }
) => {
  return (
    <ProjectSettingsModalRowItem>
      <ProjectSettingsModalRowItemTitle>
        Связь с 1С
      </ProjectSettingsModalRowItemTitle>
      <ProjectSettingsModalRowItemContent>
        <Select
          value={value}
          style={{width: '100%'}}
          onChange={onChange}
          options={[
            {
              value: undefined,
              label: 'Отвязан от склада 1C'
            },
            ...warehouseTopologies.map(
              (topology: WarehouseTopology) => ({
                value: topology.externalId,
                label: topology.warehouseName
              })
            )
          ]}
        />
      </ProjectSettingsModalRowItemContent>
    </ProjectSettingsModalRowItem>
  );
};

export const ProjectSettingsModalHasTemperatureConditionsItem: React.FC<ProjectSettingsModalInputProps> = (
  {
    value,
    onChange,
  }
) => {
  return (
    <ProjectSettingsModalRowItem>
      <ProjectSettingsModalRowItemContent>
        <Switch
          onChange={onChange}
          checked={value}
        />
        Температурный режим
      </ProjectSettingsModalRowItemContent>
    </ProjectSettingsModalRowItem>
  );
};

export const ProjectSettingsModalWarehouseTypeItem: React.FC<ProjectSettingsModalTypeInputProps<WarehouseType | undefined>> = (
  {
    value,
    onChange,
  }
) => {
  return (
    <ProjectSettingsModalRowItem>
      <ProjectSettingsModalRowItemTitle>
        Тип склада
      </ProjectSettingsModalRowItemTitle>
      <ProjectSettingsModalRowItemContent>
        <Select
          value={value}
          onChange={onChange}
          style={{width: '100%'}}
          options={[
            {
              value: WarehouseType.Indoor,
              label: 'Помещение',
            },
            {
              value: WarehouseType.Outdoor,
              label: 'Уличный',
            },
            {
              value: WarehouseType.Tented,
              label: 'Тентованный',
            }
          ]}
        />
      </ProjectSettingsModalRowItemContent>
    </ProjectSettingsModalRowItem>
  );
};

interface ProjectSettingsModalTemperatures {
  hasTemperatureCondition: boolean;
  onHasTemperatureConditionUpdate: (value: boolean) => void;
  onTemperatureFromChange: (value: number) => void;
  onTemperatureToChange: (value: number) => void;
  temperatureFrom: number;
  temperatureTo: number;
}

export const ProjectSettingsModalTemperatures: React.FC<ProjectSettingsModalTemperatures> = (
  {
    hasTemperatureCondition,
    onHasTemperatureConditionUpdate,
    onTemperatureFromChange,
    onTemperatureToChange,
    temperatureFrom,
    temperatureTo,
  }
) => {
  return (
    <ProjectSettingsModalRowItem>
      <ProjectSettingsModalRowItemTitle>
        <ProjectSettingsModalHasTemperatureConditionsItem
          value={hasTemperatureCondition}
          onChange={onHasTemperatureConditionUpdate}
        />
      </ProjectSettingsModalRowItemTitle>
      {hasTemperatureCondition && (
        <ProjectSettingsModalRowItemContent>
          <div>
            <InputNumber
              defaultValue={0}
              step={1}
              onChange={
                (value: number | string | undefined) => onTemperatureFromChange(value as number || 0)
              }
              value={temperatureFrom}
              min={-50}
              max={temperatureTo}
            />
          </div>
          <div>
            <InputNumber
              defaultValue={0}
              step={1}
              onChange={
                (value: number | string | undefined) => onTemperatureToChange(value as number || 0)
              }
              value={temperatureTo}
              min={temperatureFrom}
              max={50}
            />
          </div>
        </ProjectSettingsModalRowItemContent>
      )}
    </ProjectSettingsModalRowItem>
  );
};
