import React from "react";
import WarehouseSchema from "../../models/Warehouses/WarehouseSchema";
import WarehouseSchemaActiveIcon from "./WarehouseSchemaActiveIcon";
import WarehouseSchemaDraftIcon from "./WarehouseSchemaDraftIcon";
import WarehouseSchemaNonActiveIcon from "./WarehouseSchemaNonActiveIcon";

interface WarehouseSchemaIconProps {
  schema: WarehouseSchema;
}

const WarehouseSchemaIcon: React.FC<WarehouseSchemaIconProps> = ({
  schema,
}) => {
  return (
    <div className="warehouse-schema-description-info-data-icon">
      {schema.isActive && <WarehouseSchemaActiveIcon />}
      {!schema.isActive && schema.isDraft && <WarehouseSchemaDraftIcon />}
      {!schema.isDraft && !schema.isActive && <WarehouseSchemaNonActiveIcon />}
    </div>
  );
};

export default WarehouseSchemaIcon;
