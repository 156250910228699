import React from "react";
import Planner from "../../scenes/Planner";
import './SkladarLayout.less';
import WarehouseList from "../../scenes/Planner/components/WarehouseList";
import connect from "../../stores/connect";
import EditWarehouseSchemaJsonInputDto from "../../stores/dto/EditWarehouseSchemaJsonInputDto";
import {CreateWarehouseSchemaRequestDto, WarehouseSchemaDto} from "../../api/swagger";
import WarehouseSchema from "../../models/Warehouses/WarehouseSchema";
import SiderMenu from "../SiderMenu";
import {CreatingWarehouseParams} from "../../stores/warehouseStore";

interface SkladarLayoutProps {
  createWarehouseSchema: (dto: CreateWarehouseSchemaRequestDto) => void;
  creatingWarehouseParams: CreatingWarehouseParams | undefined;
  saveWarehouseSchemaJson: (input: EditWarehouseSchemaJsonInputDto) => Promise<WarehouseSchemaDto>;
  setCreatingWarehouseParams: (params: CreatingWarehouseParams | undefined) => void;
  warehouseSchema: WarehouseSchema | undefined;
  history: any;
}

const SkladarLayout: React.FC<SkladarLayoutProps> = (
  {
    createWarehouseSchema,
    creatingWarehouseParams,
    saveWarehouseSchemaJson,
    setCreatingWarehouseParams,
    warehouseSchema,
    history
  }
) => {
  const saveSchema = async (scene) => {
    const input: EditWarehouseSchemaJsonInputDto = {
      warehouseSchema: warehouseSchema as unknown as WarehouseSchemaDto,
      json: scene
    };
    await saveWarehouseSchemaJson(input);
  };
  const isLocalModeEnabled = false;

  return (

    <div className="skladar-layout">
      <SiderMenu history={history} />
      <div className="skladar-layout-content">
        {!isLocalModeEnabled && !warehouseSchema && <WarehouseList />}
        {(!!warehouseSchema || isLocalModeEnabled) && (
          <Planner
            createWarehouseSchema={createWarehouseSchema}
            creatingWarehouseParams={creatingWarehouseParams}
            isLocalModeEnabled={isLocalModeEnabled}
            saveWarehouseSchema={saveSchema}
            setCreatingWarehouseParams={setCreatingWarehouseParams}
            warehouseSchema={warehouseSchema}
          />
        )}
      </div>
    </div>
  )
};

export default connect(({store}) => ({
  createWarehouseSchema: store.warehouseStore.createWarehouseSchema,
  creatingWarehouseParams: store.warehouseStore.creatingWarehouseParams,
  saveWarehouseSchemaJson: store.warehouseStore.saveWarehouseSchemaJson,
  setCreatingWarehouseParams: store.warehouseStore.setCreatingWarehouseParams,
  warehouseSchema: store.warehouseStore.currentWarehouseSchema,
}))(SkladarLayout);