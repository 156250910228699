import * as Three from 'three';
import React from 'react';
import getItemStrokeColor from "../../../utils/getItemStrokeColor";

const material = new Three.MeshLambertMaterial({color: 0xf5f4f4});

export default {
  name: 'move direction',
  prototype: 'items',

  info: {
    tag: ['structure'],
    title: 'move direction',
    description: 'arrow',
    image: require('./square_column.png')
  },

  properties: {
    width:{
      label: 'width',
      type: 'length-measure',
      defaultValue: {
        length: 50,
        unit: 'cm'
      }
    },
    depth:{
      label: 'depth',
      type: 'length-measure',
      defaultValue: {
        length: 50,
        unit: 'cm'
      }
    }
  },

  render2D: function (element, layer, scene) {

    let width = element.properties.get('width').get('length');
    let depth = element.properties.get('depth').get('length');

    let angle = element.rotation + 90;

    let textRotation = 0;
    if (Math.sin(angle * Math.PI / 180) < 0) {
      textRotation = 180;
    }

    const strokeWidthPixels = 6;

    const color = getItemStrokeColor(
        element,
        {
          defaultColor: 'rgb(146,186,240)'
        }
    );

    let lineStyleStyle = {
      stroke: color,
      strokeWidth: `${strokeWidthPixels}px`,
      fill: 'transparent'
    };
    const circleStyle = {
      strokeWidth: 0,
      fill: color,
    };

    return (
      <g
          fillRule="evenodd"
          clipRule="evenodd"
          transform={`translate(${-width / 2},${-depth / 2})`}>
        <path
            style={lineStyleStyle}
            key="1"
            d={`M 0 ${depth / 2} L ${width} ${depth / 2} M ${width - depth / 2} 0 L ${width} ${depth / 2} M ${width - depth / 2} ${depth} L ${width} ${depth / 2}`}
        />
        <circle
          key="2"
          cx={0}
          cy={depth / 2}
          r={strokeWidthPixels / 2}
          style={circleStyle}
        />
        <circle
          key="3"
          cx={width - depth / 2}
          cy={0}
          r={strokeWidthPixels / 2}
          style={circleStyle}
        />
        <circle
          key="4"
          cx={width - depth / 2}
          cy={depth}
          r={strokeWidthPixels / 2}
          style={circleStyle}
        />
        <circle
          key="5"
          cx={width}
          cy={depth / 2}
          r={strokeWidthPixels / 2}
          style={circleStyle}
        />
      </g>
    )
  },


  render3D: function (element, layer, scene) {

    let HEIGHT = element.properties.get('height').get('length');
    let width = element.properties.get('width').get('length');
    let depth = element.properties.get('depth').get('length');
    let newAltitude = element.properties.get('altitude').get('length');

    let column = new Three.Object3D();

    let object = new Three.Mesh(new Three.BoxGeometry(width,HEIGHT,depth, 32), material);

    column.add(object);

    if (element.selected) {
      let bbox = new Three.BoxHelper(column, 0x99c3fb);
      bbox.material.linewidth = 10;
      bbox.renderOrder = 5000;
      bbox.material.depthTest = false;
      column.add(bbox);
    }

    column.position.y += HEIGHT / 2 + newAltitude;
    column.position.x += width / 2;


    return Promise.resolve(column);

  }
};
