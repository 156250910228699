import { Layer, Group } from './export';
import {
  IDBroker,
  NameGenerator
} from '../utils/export';
import {Map, fromJS, List} from 'immutable';

import {
  MODE_IDLE,
  MODE_DRAWING_ITEM,
  MODE_DRAGGING_ITEM,
  MODE_ROTATING_ITEM, UNIT_CENTIMETER
} from '../constants';
import ListUtils from "../utils/listUtils";
import {Item as ItemCatalogElement} from "../models";
import {getSelectedItems} from "../../../utils/getSelectedElements";
import {getCopiedItemList} from "../../../utils/getCopiedElements";
import updateMultipleItemsAreaId from "../../../utils/updateMultipleItemsAreaId";

class Item{

  static create( state, layerID, type, x, y, width, height, rotation, initialProps = null ) {
    let itemID = IDBroker.acquireID();

    let item = state.catalog.factoryElement(type, {
      id: itemID,
      name: NameGenerator.generateName('items', state.catalog.getIn(['elements', type, 'info', 'title'])),
      type,
      height,
      width,
      x,
      y,
      rotation
    }, initialProps);

    state = state.setIn(['scene', 'layers', layerID, 'items', itemID], item);
    return { updatedState: state, item };
  }

  static copy(state, layerId, id) {
    /** @type ItemCatalogElement */
    const originalItem = state.getIn(['scene', 'layers', layerId, 'items', id]);
    const originalItemProperties = originalItem.get('properties');
    let {updatedState, item: createdItem} = Item.create(
      state,
      layerId,
      originalItem.get('type'),
      originalItem.get('x') + 10,
      originalItem.get('y') + 10,
      originalItemProperties.get('width'),
      originalItemProperties.get('height'),
      originalItem.get('rotation'),
      originalItemProperties
    );
    updatedState = this.unselect(updatedState, layerId, id).updatedState;
    updatedState = this.select(updatedState, layerId, createdItem.id).updatedState;
    updatedState = updatedState.merge({
      mode: MODE_DRAWING_ITEM,
      drawingSupport: new Map({
        copy: true,
        type: createdItem.name,
        currentID: createdItem.id
      })
    });
    return {updatedState, item: createdItem};
  }

  static copyMultiple(state, layerId, itemIds) {
    const itemsToCopy = state
      .getIn(['scene', 'layers', layerId, 'items'])
      .filter(
        (item) => itemIds.indexOf(item.id) !== -1
      )
      .sort(
        (firstItem, secondItem) => {
          if (firstItem.id < secondItem.id) {
            return -1;
          } else if (firstItem.id > secondItem.id) {
            return 1;
          }
          return 0;
        }
      )
    ;
    state = state.deleteIn(['clipboard', 'items']).setIn(['clipboard', 'items'], itemsToCopy);

    return {updatedState: state};
  }

  static pasteMultiple(state, layerId) {
    const copiedItemList = getCopiedItemList(state);
    let updatedState = state;
    const createdItems = [];
    copiedItemList.map(
      (originalItem) => {
        const originalItemProperties = originalItem.get('properties');
        const {updatedState: newState, item: createdItem} = Item.create(
          updatedState,
          layerId,
          originalItem.get('type'),
          originalItem.get('x'),
          originalItem.get('y'),
          originalItemProperties.get('width'),
          originalItemProperties.get('height'),
          originalItem.get('rotation'),
          originalItemProperties
        );
        updatedState = newState;
        createdItems.push(createdItem);
      }
    );
    const centerX = createdItems.reduce(
      (previousValue, currentValue, currentIndex) => previousValue + currentValue.x,
      0
    ) / createdItems.length;
    const centerY = createdItems.reduce(
      (previousValue, currentValue, currentIndex) => previousValue + currentValue.y,
      0
    ) / createdItems.length;
    const x0 = Math.ceil(centerX);
    const y0 = Math.ceil(centerY);

    const relativeCoordinates = {};
    createdItems.forEach(
      (item) => {
        const id = item.id;
        relativeCoordinates[id] = {
          initialX: item.x,
          initialY: item.y,
          deltaX: x0 - item.x,
          deltaY: y0 - item.y,
        };
      }
    );

    updatedState = updatedState.merge({
      mode: MODE_DRAWING_ITEM,
      drawingSupport: new Map({
        copyMultiple: true,
        items: new Map({
          data: new List(createdItems),
          metadata: {
            initialCenter: {
              x: x0,
              y: y0,
            },
            relativeCoordinates,
          },
        })
      }),
    });
    return {updatedState};
  }

  static select( state, layerID, itemID ){
    state = Layer.select( state, layerID ).updatedState;
    state = Layer.selectElement( state, layerID, 'items', itemID ).updatedState;

    return {updatedState: state};
  }

  static remove( state, layerID, itemID ) {
    state = this.unselect( state, layerID, itemID ).updatedState;
    state = Layer.removeElement( state, layerID, 'items', itemID ).updatedState;

    state.getIn(['scene', 'groups']).forEach( group => state = Group.removeElement(state, group.id, layerID, 'items', itemID).updatedState );

    return { updatedState: state };
  }

  static unselect( state, layerID, itemID ) {
    state = Layer.unselect( state, layerID, 'items', itemID ).updatedState;

    return { updatedState: state };
  }

  static selectToolDrawingItem(state, sceneComponentType) {
    state = state.merge({
      mode: MODE_DRAWING_ITEM,
      drawingSupport: new Map({
        copy: false,
        type: sceneComponentType
      })
    });

    return { updatedState: state };
  }

  static updateDrawingItem(state, layerID, x, y) {
    if (state.hasIn(['drawingSupport','currentID'])) {
      state = state.updateIn(
        ['scene', 'layers', layerID, 'items', state.getIn(['drawingSupport','currentID'])],
        item => item.merge({x, y})
      );
    } else if (state.hasIn(['drawingSupport', 'copyMultiple'])) {
      const createdItemIds = state.getIn(['drawingSupport', 'items', 'data'])?.map((item) => item.id);
      const relativeCoordinates = state.getIn(['drawingSupport', 'items', 'metadata', 'relativeCoordinates']);
      createdItemIds.forEach((createdItemId) => {
        const newX = x - relativeCoordinates[createdItemId]?.deltaX;
        const newY = y - relativeCoordinates[createdItemId]?.deltaY;
        state = state.updateIn(
          ['scene', 'layers', layerID, 'items', createdItemId],
          (item) => item.merge({x: newX, y: newY})
        );
      });
    } else {
      let { updatedState: stateI, item } = this.create( state, layerID, state.getIn(['drawingSupport','type']), x, y, 200, 100, 0);
      state = Item.select( stateI, layerID, item.id ).updatedState;
      state = state.setIn(['drawingSupport','currentID'], item.id);
    }

    return { updatedState: state };
  }

  static endDrawingItem(state, layerID, x, y) {
    let catalog = state.catalog;
    state = this.updateDrawingItem(state, layerID, x, y, catalog).updatedState;
    state = Layer.unselectAll( state, layerID ).updatedState;
    const drawingSupport = state.get('drawingSupport');
    if (drawingSupport.has('copyMultiple') && drawingSupport.has('items')) {
      state = state
        .setIn(['mode'], MODE_IDLE)
        .deleteIn(['drawingSupport', 'copyMultiple'])
        .deleteIn(['drawingSupport', 'items'])
      ;
    } else if (drawingSupport.has('copy') && drawingSupport.has('currentID')) {
      const copyingItemId = drawingSupport.get('currentID');
      state = state.merge(this.copy(state, layerID, copyingItemId).updatedState);
    } else {
      state =  state.merge({
        drawingSupport: Map({
          type: state.drawingSupport.get('type')
        })
      });
    }
    state = updateMultipleItemsAreaId(state, layerID, state.getIn(['scene', 'layers', layerID, 'items']));

    return { updatedState: state };
  }

  static beginDraggingItem(state, layerID, itemID, x, y) {

    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);

    /** @type {ItemCatalogElement[]} */
    const draggingItems = getSelectedItems(state);
    const draggingItemsJson = draggingItems.map(
      /**
       * @param {ItemCatalogElement} draggingItem
       * @returns {{startPointY: *, startPointX: *, originalY: *, originalX: *, id: *}}
       */
      (draggingItem) => {
        return {
          id: draggingItem.get('id'),
          originalX: draggingItem.get('x'),
          originalY: draggingItem.get('y'),
        };
      }
    );
    state = state.merge({
      mode: MODE_DRAGGING_ITEM,
      modes: state.get('modes').push(MODE_DRAGGING_ITEM),
      draggingSupport: Map({
        layerID,
        itemID,
        startPointX: x,
        startPointY: y,
        originalX: item.x,
        originalY: item.y,
        items: draggingItemsJson,
      })
    });

    return { updatedState: state };
  }

  static updateDraggingItem(state, x, y) {
    let {draggingSupport, scene} = state;

    let layerID = draggingSupport.get('layerID');
    let startPointX = draggingSupport.get('startPointX');
    let startPointY = draggingSupport.get('startPointY');

    let diffX = startPointX - x;
    let diffY = startPointY - y;

    const draggingItems = draggingSupport.get('items');
    draggingItems.forEach(
      /**
       * @param {object} draggingItemData
       */
      (draggingItemData) => {
        const draggingItemOriginal = scene.getIn(['layers', layerID, 'items', draggingItemData.id]);
        const draggingItemUpdated = draggingItemOriginal.merge({
          x: draggingItemData.originalX - diffX,
          y: draggingItemData.originalY - diffY,
        });
        state = state.mergeIn(['scene', 'layers', layerID, 'items', draggingItemData.id], draggingItemUpdated);
      }
    );

    return { updatedState: state };
  }

  static endDraggingItem(state, x, y) {
    state = this.updateDraggingItem(state, x, y).updatedState;
    const {draggingSupport} = state;

    let layerID = draggingSupport.get('layerID');
    const draggingItemsData = draggingSupport.get('items');
    const allDraggingItems = [];

    draggingItemsData.forEach(
      (draggingItemData) => {
        const id = draggingItemData.id;
        const item = state.getIn(['scene', 'layers', layerID, 'items', id]);
        allDraggingItems.push(item);
      }
    );
    state = updateMultipleItemsAreaId(state, layerID, allDraggingItems);

    state = state.merge({
      mode: MODE_IDLE,
      modes: ListUtils.withoutScalar(state.get('modes'), MODE_DRAGGING_ITEM),
    });

    return { updatedState: state };
  }

  static beginRotatingItem(state, layerID, itemID, x, y) {
    const item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    state = state.merge({
      mode: MODE_ROTATING_ITEM,
      modes: ListUtils.withScalar(state.get('modes'), MODE_ROTATING_ITEM),
      rotatingSupport: Map({
        layerID,
        itemID,
        originalRotation: item.rotation,
        items: getSelectedItems(state).map(
          (rotatingItem) => ({
            id: rotatingItem.id,
            originalRotation: rotatingItem.rotation,
          })
        )
      })
    });

    return { updatedState: state };
  }

  static updateRotatingItem(state, x, y) {
    let {rotatingSupport, scene} = state;

    let layerID = rotatingSupport.get('layerID');

    let itemID = rotatingSupport.get('itemID');
    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);

    let deltaX = x - item.x;
    let deltaY = y - item.y;
    let newRotation = Math.atan2(deltaY, deltaX) * 180 / Math.PI - 90;
    let newRotationNormalized = newRotation + 270;
    let originalRotationNormalized = rotatingSupport.get('originalRotation') + 270;
    const deltaRotation = (newRotationNormalized - originalRotationNormalized);

    if (rotatingSupport.has('items')) {
      const rotatingItems = rotatingSupport.get('items');
      const allRotatingItems = [];
      rotatingItems.forEach(
        (rotatingItemData) => {
          const itemOriginalRotationNormalized = rotatingItemData.originalRotation + 270;
          let rotation = (itemOriginalRotationNormalized + deltaRotation + 360) % 360 - 270;
          if (-5 < rotation && rotation < 5) rotation = 0;
          if (-95 < rotation && rotation < -85) rotation = -90;
          if (-185 < rotation && rotation < -175) rotation = -180;
          if (85 < rotation && rotation < 90) rotation = 90;
          if (-270 < rotation && rotation < -265) rotation = 90;
          let rotatingItem = state.getIn(['scene', 'layers', layerID, 'items', rotatingItemData.id]);
          rotatingItem = rotatingItem.merge({
            rotation
          });
          state = state.mergeIn(['scene', 'layers', layerID, 'items', rotatingItemData.id], rotatingItem);
          allRotatingItems.push(rotatingItem);
        }
      );
      state = updateMultipleItemsAreaId(state, layerID, allRotatingItems);
    }

    return { updatedState: state };
  }

  static endRotatingItem(state, x, y) {
    state = this.updateRotatingItem(state, x, y).updatedState;
    state = state.merge({
      mode: MODE_IDLE,
      modes: ListUtils.withoutScalar(state.get('modes'), MODE_ROTATING_ITEM),
    });

    return { updatedState: state };
  }

  static setProperties( state, layerID, itemID, properties ) {
    state = state.mergeIn(['scene', 'layers', layerID, 'items', itemID, 'properties'], properties);
    state = updateMultipleItemsAreaId(state, layerID, state.getIn(['scene', 'layers', layerID, 'items']));

    return { updatedState: state };
  }

  static setJsProperties( state, layerID, itemID, properties ) {
    return this.setProperties( state, layerID, itemID, fromJS(properties) );
  }

  static updateProperties( state, layerID, itemID, properties) {
    properties.forEach( ( v, k ) => {
      if( state.hasIn(['scene', 'layers', layerID, 'items', itemID, 'properties', k]) )
        state = state.mergeIn(['scene', 'layers', layerID, 'items', itemID, 'properties', k], v);
    });

    return { updatedState: state };
  }

  static updateJsProperties( state, layerID, itemID, properties) {
    return this.updateProperties( state, layerID, itemID, fromJS(properties) );
  }

  static setAttributes( state, layerID, itemID, itemAttributes) {
    state = state.mergeIn(['scene', 'layers', layerID, 'items', itemID], itemAttributes);
    state = updateMultipleItemsAreaId(state, layerID, state.getIn(['scene', 'layers', layerID, 'items']));
    return { updatedState: state };
  }

  static setJsAttributes( state, layerID, itemID, itemAttributes) {
    itemAttributes = fromJS(itemAttributes);
    return this.setAttributes(state, layerID, itemID, itemAttributes);
  }

}

export { Item as default };
