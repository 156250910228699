import {ProductToTaskDto, TaskDto, TaskStateEnum, TaskType} from "../../api/swagger";

class TaskModel {
  id: string | undefined;
  warehouseId: string | undefined;
  warehouseSchemaId: string | undefined;
  userId: number | undefined;
  taskNumber: number | undefined;
  creationTime: Date;
  deadline: Date | undefined;
  externalId: string | undefined;
  counterparty: string | undefined;
  state: TaskStateEnum;
  taskType: TaskType;
  productsForTask: ProductToTaskDto[];

  constructor(task: TaskDto | undefined = undefined) {
    this.id = task?.id
    this.warehouseId = task?.warehouseId
    this.warehouseSchemaId = task?.warehouseSchemaId
    this.userId = task?.userId
    this.taskNumber = task?.taskNumber
    this.creationTime = task?.creationTime ?? new Date()
    this.deadline = task?.deadline
    this.externalId = task?.externalId
    this.counterparty = task?.counterparty
    this.state = task?.state ?? TaskStateEnum.New
    this.taskType = task?.taskType ?? TaskType.Picking
    this.productsForTask = task?.productsForTask ?? []
  }
}

export default TaskModel;
