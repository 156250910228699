type IteratorOf<T> = (item: T, index?: number, all?: Array<T>) => any;

export default class Collection<CollectionItemType> {
  protected items: Array<CollectionItemType> = [];

  constructor(items: Array<CollectionItemType>) {
    this.items = items;
  }

  getAll(): Array<CollectionItemType> {
    return this.items;
  }

  count(): number {
    return this.getAll().length;
  }

  isEmpty(): boolean {
    return this.count() === 0;
  }

  findIndex(fn: IteratorOf<CollectionItemType>): number {
    return this.getAll().findIndex(fn);
  }

  has(fn: (item: CollectionItemType, index: number) => any): boolean {
    return this.findIndex(fn as IteratorOf<CollectionItemType>) !== -1;
  }

  find(
    fn: IteratorOf<CollectionItemType>
  ): CollectionItemType | undefined {
    return this.getAll().find(fn);
  }

  map(fn: IteratorOf<CollectionItemType>) {
    return this.getAll().map(fn);
  }
}
