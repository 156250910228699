const AppConsts = {
  userManagement: {
    defaultAdminUserName: 'admin',
  },
  localization: {
    defaultLocalizationSourceName: 'skladar',
  },
  authorization: {
    encryptedAuthTokenName: 'enc_auth_token',
    authTokenName: 'auth_token',
  },
  appBaseUrl: process.env.REACT_APP_APP_BASE_URL!,
  remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL!,

  turnServer: process.env.REACT_APP_TURN_SERVER!,
  turnSecret: process.env.REACT_APP_TURN_SECRET!,
  stunServer: process.env.REACT_APP_STUN_SERVER!,
};
export default AppConsts;
