import * as React from 'react';
import { Avatar, Tooltip, Col, Layout, Menu } from 'antd';
// eslint-disable-next-line import/no-cycle
import utils from '../../utils/utils';
import Logo from "../Svg/Logo";
import {
    AndroidIcon,
    IosIcon,
    HelpIcon,
    SettingsIcon,
    PlannerIcon,
    DashboardIcon,
} from "../SkladarSidebar/SkladarSidebar";
import ProtectedMenuItem, {ProtectedMenuItemProps} from "./ProtectedMenuItem";




const { Sider } = Layout;

export interface ISiderMenuProps {
    history: any;
}

const SiderMenu: React.FC<ISiderMenuProps> = props => {
    const {history} = props;
    const currentRoute = utils.getRoute(history.location.pathname);
    const customRoutes: ProtectedMenuItemProps[] = [
      {
        path: '/dashboard',
        title: 'Dashboard',
        icon: <DashboardIcon highlight={currentRoute?.path === '/dashboard'} />,
        history,
        permission: ''
      },
      {path: '/planner', title: 'Planner', icon: <PlannerIcon />, history, permission: 'Pages.Planner'},
      {
        path: '/users',
        title: 'User',
        icon: <SettingsIcon highlight={currentRoute?.path === '/user'} />,
        history,
        permission: 'Pages.Users'
      },
    ];

    return (
      <Sider
        trigger={null}
        className="sidebar"
        width={64}
        theme='light'
        style={{
          borderRight: 'solid 1px rgba(217, 217, 217, 1)',
          position: 'sticky',
          height: '100vh',
          overflow: 'hidden',
          top: 0,
          transition: 'disable'
        }}
      >
        <Col style={{ textAlign: 'center', marginTop: 19, marginBottom: 60}}>
          <Avatar shape="square" className="iconHover" style={{ height: 48, width: 48, background: 'none' }} icon={<Logo width={48} height={48} />} />
        </Col>

        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[currentRoute ? currentRoute.path : '']}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
              paddingBottom: '150px',
        }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {customRoutes.map((route) => <ProtectedMenuItem key={route.path} {...route} />)}


          <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Menu.Item
              onItemHover={() => {}}
              style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '24px',
                    }}
              onClick={() => console.log('Button 1 pressed')}
            >
              <Tooltip title="Button 1">
                <Avatar shape="square" className="iconHover" style={{ height: 32, width: 44, background: 'none', marginLeft: 0 }} icon={<AndroidIcon />} />
              </Tooltip>
            </Menu.Item>
            <Menu.Item
              onItemHover={() => {}}
              style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '24px',
                    }}
              onClick={() => console.log('Button 2 pressed')}
            >
              <Tooltip title="Button 2">
                <Avatar shape="square" className="iconHover" style={{ height: 32, width: 50, background: 'none', marginLeft: 0 }} icon={<IosIcon />} />
              </Tooltip>
            </Menu.Item>
            <Menu.Item
              onItemHover={() => {}}
              style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
              onClick={() => console.log('Button 3 pressed')}
            >
              <Tooltip title="Button 3">
                <Avatar shape="square" className="iconHover" style={{ height: 32, width: 44, background: 'none', marginLeft: 0 }} icon={<HelpIcon />} />
              </Tooltip>
            </Menu.Item>
          </div>

        </Menu>
      </Sider>
    );
};

export default SiderMenu;
