import Collection from "../../domain/collection";
import WarehouseTopologyCellAddress from "./WarehouseTopologyCellAddress";

export default class WarehouseTopologyCellAddressCollection extends Collection<WarehouseTopologyCellAddress> {
  findByCellName(name: string): WarehouseTopologyCellAddress | undefined {
    return this.find(
      (cellAddress) => cellAddress.value === name
    );
  }

  // @ts-ignore
  has(cell: WarehouseTopologyCellAddress): boolean {
    return this.findByCellName(cell.value) !== undefined;
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologyCellAddressCollection {
    const remainingCells: WarehouseTopologyCellAddress[] = [];

    this.map(
      // eslint-disable-next-line array-callback-return
      (cell: WarehouseTopologyCellAddress) => {
        if (!removingCells.has(cell)) {
          remainingCells.push(cell);
        }
      }
    );

    return new WarehouseTopologyCellAddressCollection(remainingCells);
  }
}
