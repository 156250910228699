import {action, observable} from 'mobx';

import {HubConnection, HubConnectionState} from "@microsoft/signalr/dist/esm/HubConnection";
import {ArgsProps} from "antd/lib/notification";
import {GetCurrentLoginInformations} from '../services/session/dto/getCurrentLoginInformations';
import sessionService from '../services/session/sessionService';
import SignalRModel from "../models/ChatModels/signalRModel";
import UserInfo from "../models/ChatModels/userInfo";
import MessageModel from "../models/ChatModels/messageModel";
import ChatModel from "../models/ChatModels/chatModel";
import {apiClient} from "../services/apiClient";
import {MessageType, TaskDto, TaskStateEnum} from "../api/swagger";
import {L} from "../lib/abpUtility";
// eslint-disable-next-line import/no-cycle
import utils from "../utils/utils";

declare let abp: any;

class SessionStore {
  @observable currentLogin: GetCurrentLoginInformations = new GetCurrentLoginInformations();
  @observable signalR: SignalRModel | undefined = undefined;
  @observable chatHub: HubConnection | undefined = undefined;
  @observable chats: ChatModel[] = [];
  @observable isChatHubConnected = false;
  @observable alert: ArgsProps | undefined = undefined;

  @action
  async getCurrentLoginInformation() : Promise<void> {
    this.currentLogin = await sessionService.getCurrentLoginInformations();
  }

  @action
  async setSignalRConnection(attempt = 1): Promise<void> {
    if (abp.signalr) {
      this.signalR = abp.signalr
      await this.chatHubInitialization()
      this.registerInvokedMethods()
      const connectedUsers = await this.chatHub?.invoke('newUser', this.signalR!.user?.userName, this.signalR!.user?.name) ?? []
      this.isChatHubConnected = true
      await this.initChats(connectedUsers)
    } else if (attempt < 10) {
      await utils.wait(3000)
      await this.setSignalRConnection(attempt + 1)
    } else {
      console.log('Fail init SignalR connection')
    }
    console.log('setSignalRConnection done')
  }

  @action
  async sendMessage(message: string, userName: string, taskId: string, messageType?: MessageType) : Promise<void> {
    const type: MessageType = messageType ?? MessageType.Text
    const messageModel: MessageModel = {
      id: this.getNextMessageId(taskId),
      userName: userName,
      message: message,
      type: type,
      date: undefined,
      isSelf: true,
      isSent: false,
      hasSeen : true
    }
    const result: MessageModel = await this.chatHub!.invoke('sendTaskMessage', message, taskId, type)
    if (result) {
      messageModel.isSent = true
      messageModel.message = result.message
    }
    this.setMessage(messageModel, taskId)
  }

  @action
  async newTaskChatUser(taskId: string) : Promise<UserInfo[]> {
    return await this.chatHub?.invoke<UserInfo[]>('NewTaskChatUser', taskId) ?? []
  }

  private setMessage = (message: MessageModel, taskId: string): void => {
    const taskChatIndex = this.getChatIndex(taskId)
    this.chats[taskChatIndex].messageStack = [...this.chats[taskChatIndex].messageStack, message]
    this.chats = [...this.chats]
  }

  private async chatHubInitialization(): Promise<void> {
    if (this.signalR!.hubs?.common.state === HubConnectionState.Connected) {
      this.chatHub = this.signalR!.hubs?.common
    } else if (this.signalR!.hubs?.common.state === HubConnectionState.Disconnected){
      this.signalR!.connect()
      await utils.wait(3000)
      await this.chatHubInitialization()
    } else {
      await utils.wait(3000)
      await this.chatHubInitialization()
    }
  }

  private registerInvokedMethods(): void {
    this.chatHub?.on('newUserConnected', async (connectedUser: UserInfo) => {
      const chats = this.chats.map(chat => {
        const userIndex = chat.users.findIndex(x => x.userId === connectedUser.userId)
        if (userIndex !== -1) {
          chat.users[userIndex] = connectedUser
          chat.users = [...chat.users]
        }
        return chat
      })
      this.chats = [...chats]
    });
    this.chatHub?.on('userDisconnect', (disconnectedUser: UserInfo) => {
      this.chats = this.chats.map(chat => {
        const user = chat.users.find(x => x.connectionId === disconnectedUser.connectionId)
        if (user) user.connectionId = ''
        return chat
      })
    });
    this.chatHub?.on('onTaskMessage', (message: MessageModel, taskId: string) => {
      this.setMessage({...message, id: this.getNextMessageId(taskId), isSelf: false, hasSeen: false}, taskId)
    });
    this.chatHub?.on('onAlert', (title: string, description: string) => {
      this.currentLogin.alerts.unshift({isActive: true, title: title, description: description})
      this.alert = {
        message: title,
        description: description
      }
      setTimeout(() => { this.alert = undefined }, 1000)
    })
    this.chatHub?.onclose(error => {
      console.log('hub closed', error)
    })
  }

  private async initChats(connectedUsers: UserInfo[]) {
    console.log('connected Users:', connectedUsers)
    const result: TaskDto[] = await apiClient.getTasksAll()
    const taskChats = await Promise.all(result.filter(x => x.chatMessages?.length).map(async x => this.mapTaskChat(x)))
    console.log("RESULT", taskChats)
    this.chats = [...taskChats]
  }

  private async mapTaskChat(task: TaskDto): Promise<ChatModel> {
    const taskUsers = await this.newTaskChatUser(task.id!)
    return <ChatModel>{
      id: task.id!,
      name: `${task.taskNumber} ${L(task.taskType!)}`,
      counterparty: task.counterparty,
      isActive: task.state === TaskStateEnum.Accepted || task.state === TaskStateEnum.Begun,
      user: taskUsers.find(x => x.userId === task.userId),
      users: taskUsers,
      messageStack: task.chatMessages!.map((x, i): MessageModel => ({
        id: (i + 1),
        userName: x.user!.userName,
        message: x.message!,
        type: x.type!,
        date: x.creationTime!,
        isSelf: this.currentLogin.user.id === x.userId,
        isSent: true,
        hasSeen: true
      }))
    }
  }

  getNextMessageId = (chatId: string): number => (this.chats[this.getChatIndex(chatId)].messageStack.length + 1)

  getChatIndex = (taskId: string): number => {
    const taskChatIndex = this.chats.findIndex(x => x.id === taskId)
    return (taskChatIndex === -1) ? 0 : taskChatIndex
  }
}

export default SessionStore;
