import {action, observable} from "mobx";

import DeviceModel from "../models/Devices/deviceModel";
import {apiClient} from "../services/apiClient";
import {GetAllRoleOutput} from "../services/role/dto/getAllRoleOutput";
import {GuidEntityDto} from "../api/swagger";

class DeviceStore {
  @observable devices!: DeviceModel[];
  @observable deviceModel: DeviceModel = new DeviceModel()
  @observable isDevicesLoaded = false

  @action async getAll(): Promise<void> {
    const result = await apiClient.getDevices()
    this.devices = result.map(device => new DeviceModel(device))
    this.isDevicesLoaded = true
  }

  @action get = (item: DeviceModel): void => {
    this.deviceModel = item
  };

  @action async editDevice(device: DeviceModel, userId: number | undefined = undefined): Promise<DeviceModel> {
    const result = await apiClient.editDevice({
      id: device.id,
      userId: userId,
      name: device.name,
      key: undefined,
      externalKey: undefined,
      kind: device.kind,
      status: device.status
    })

    this.deviceModel = new DeviceModel(result)
    return this.deviceModel
  }

  @action
  async delete(entityDto: GuidEntityDto) {
    await apiClient.deleteDevice(entityDto);
    this.devices = this.devices.filter(x => x.id !== entityDto.id);
  }
}

export default DeviceStore;