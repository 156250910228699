import React from "react";

const RackIcon: React.FC = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_726_1797)">
    <mask id="mask0_726_1797" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path d="M20.25 21V3.75H3.75V21" className="stroke" strokeWidth="1.5"/>
      <path d="M3.75 8.75H20.25" className="stroke" strokeWidth="1.5"/>
      <path d="M3.75 13.75H20.25" className="stroke" strokeWidth="1.5"/>
      <path d="M3.75 18.75H20.25" className="stroke" strokeWidth="1.5"/>
    </mask>
    <g mask="url(#mask0_726_1797)">
      <rect width="24" height="24" className="fill"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_726_1797">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export default RackIcon;
