// @ts-ignore
import React from "react";
import {inject, observer} from "mobx-react";
import {RootStore} from "./storeInitializer";

type Store = {
  store: RootStore;
};

// This is a custom MobX function that injects and observes a component with type safety
export default function connect<MappedProps>(mapStoreToProps: (store: Store) => MappedProps) {
  return function <WrappedProps>(
    WrappedComponent:
      | React.ComponentClass<WrappedProps>
      | React.FC<React.PropsWithChildren<WrappedProps>>
  ) {
    return inject(mapStoreToProps)(observer(WrappedComponent)) as unknown as React.ComponentClass<
      Partial<WrappedProps>
    >;
  };
}
