const saveElementProperties = (
    newValue,
    onUpdate,
    hook,
    sourceElement,
    internalState,
    state,
) => {
    if (hook) {
        return hook(newValue, sourceElement, internalState, state)
            .then(
                (val) => onUpdate(val)
            )
        ;
    }

    return onUpdate(newValue);
}

export default saveElementProperties;
