import {POLE_AREA_BORDER_TYPE} from "../../../catalog/constants";

export const getLineByVerticesIds = (vertexAID, vertexBID, state, layerID) => {
  const linesIndexedByLineId = Object
    .fromEntries(
      state
        .getIn(['scene', 'layers', layerID, 'lines'])
        .toArray()
    );
  let chosenLine = null;
  Object.keys(linesIndexedByLineId).forEach(
    (lineId) => {
      const line = linesIndexedByLineId[lineId];
      const verticesIds = line.vertices.toArray();
      if (verticesIds[0] === vertexAID && verticesIds[1] === vertexBID
       || verticesIds[1] === vertexAID && verticesIds[0] === vertexBID
      ) {
        chosenLine = line;
      }
    }
  );
  return chosenLine;
};

export const mapCycleVerticesToCycleLines = (verticesIds, state, layerID) => {
  const lines = [];
  const checkLine = (vertexAID, vertexBID) => {
    const line = getLineByVerticesIds(vertexAID, vertexBID, state, layerID);
    if (line !== null) {
      lines.push(line);
    }
  };
  for (let i = 0; i < verticesIds.length; i++) {
    const vertexAID = verticesIds[i === 0 ? verticesIds.length - 1 : i - 1];
    const vertexBID = verticesIds[i];
    checkLine(vertexAID, vertexBID);
  }

  return lines;
};

export const isCyclePoleArea = (verticesIds, state, layerID) => {
  const lines = mapCycleVerticesToCycleLines(verticesIds, state, layerID);
  return lines.filter(
    (line) => line.type === POLE_AREA_BORDER_TYPE
  ).length === verticesIds.length;
};
