import getAreaPolygons from "./getAreaPolygons";
import getItemPolygon from "./getItemPolygon";
import getPolygonAreaId from "./getPolygonAreaId";
import getPolygonAreaIntersections from "./getPolygonAreaIntersections";

const updateItemAreaId = (
  state,
  layerID,
  item,
  vertices = null,
  correctAreaPolygons: object|null = null,
  incorrectAreaPolygons: object|null = null
) => {
  const drawingSupport = state.get('drawingSupport');
  const drawingId = drawingSupport.get('currentID');
  if (!vertices) {
    vertices = state.getIn(['scene', 'layers', layerID, 'vertices']).toJS();
  }
  const allAreas = state.getIn(['scene', 'layers', layerID, 'areas']);
  if (!correctAreaPolygons) {
    correctAreaPolygons = getAreaPolygons(
      state,
      layerID,
      allAreas.filter(
        (area) => area.get('type') === 'area'
      ),
      vertices
    );
  }
  if (!incorrectAreaPolygons) {
    incorrectAreaPolygons = getAreaPolygons(
      state,
      layerID,
      allAreas.filter(
        (area) => area.get('type') !== 'area'
      ),
      vertices
    );
  }
  const itemPolygon = getItemPolygon(item);
  if (itemPolygon?.length) {
    const wrongAreaId = getPolygonAreaId(itemPolygon, incorrectAreaPolygons);
    if (wrongAreaId === null) {
      // item is NOT inside a floor area, etc.
      const wrongAreaIntersections = getPolygonAreaIntersections(itemPolygon, incorrectAreaPolygons);
      if (!wrongAreaIntersections.length) {
        // item has NO intersections with floor areas, etc.
        const anotherItems = state
          .getIn(['scene', 'layers', layerID, 'items'])
          .filter(filteringItem => filteringItem.id !== item.id && filteringItem.id !== drawingId)
        ;
        const anotherItemPolygons = anotherItems
          .map(anotherItem => getItemPolygon(anotherItem))
          .toArray()
          .map(([itemId, itemPolygon]) => itemPolygon)
        ;
        const anotherItemIntersections = getPolygonAreaIntersections(itemPolygon, anotherItemPolygons);
        if (!anotherItemIntersections.length) {
          // item has NO intersections with another items
          const areaId = getPolygonAreaId(itemPolygon, correctAreaPolygons);
          if (areaId !== null) {
            // item is inside a building but not inside on any incorrect area
            const draggingItemUpdated = item.merge({
              misc: {
                areaId,
              }
            });
            state = state.mergeIn(['scene', 'layers', layerID, 'items', item.id], draggingItemUpdated);
          }
        }
      }
    }
  }
  return state;
};

export default updateItemAreaId;
