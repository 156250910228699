import { action, observable } from 'mobx';

import { EntityDto } from '../services/dto/entityDto';
import {TaskDto} from '../api/swagger';
import { apiClient } from '../services/apiClient';
import TaskStateHistoryModel from "../models/Tasks/taskStateHistoryModel";
import TaskModel from "../models/Tasks/taskModel";

class TaskStore {
  private sourceTasks!: TaskModel[];
  @observable tasks!: TaskModel[];
  @observable taskStateHistory!: TaskStateHistoryModel[];
  @observable editTaskModel: TaskModel = new TaskModel()
  @observable isTasksLoaded = false

  @action createTask() : void {
    this.editTaskModel = new TaskModel();
  }

  @action async editTask(task: TaskModel): Promise<TaskModel> {
    const result = await apiClient.editTask({
      taskId: task.id,
      userId: task.userId,
      warehouseId: task.warehouseId,
      counterparty: task.counterparty,
      deadline: task.deadline,
      state: task.state,
      taskType: task.taskType,
      productsForTask: task.productsForTask
    })

    this.editTaskModel = new TaskModel(result)
    return this.editTaskModel
  }

  @action async delete(entityDto: EntityDto<string>): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('delete task')
    this.tasks = this.tasks.filter((x: TaskDto) => x.id! !== entityDto.id!);
  }

  @action get = (task: TaskModel): void => {
    this.editTaskModel = task;
  };

  @action async getAll(): Promise<void> {
    const result = await apiClient.getTasksAll()
    this.sourceTasks = result.map(task => new TaskModel(task))
    this.tasks = this.sourceTasks
    this.isTasksLoaded = true
  }

  @action getStateHistory = async (): Promise<TaskStateHistoryModel[]> => {
    const result = await apiClient.getTaskStateHistory({
      taskId: undefined,
      userId: undefined,
      states: undefined,
      begTime: undefined,
      endTime: undefined,
      skip: undefined,
      take: undefined
    })
    return result.map(stateHistory => new TaskStateHistoryModel(stateHistory))
  };

  @action filterTasks = (predicate: (task: TaskModel) => boolean = () => true): void => {
    this.tasks = this.sourceTasks.filter(predicate)
  }
}

export default TaskStore;
