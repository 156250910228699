import React from "react";

const WarehouseSchemaDraftIcon: React.FC = () => {
  return (
    <svg className="warehouse-schema-button-icon-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_259_2919)">
        <path d="M17.4749 8.43752C16.8749 7.80002 16.2374 7.16252 15.5999 6.54377C15.4687 6.41252 15.3187 6.33752 15.1499 6.33752C14.9812 6.33752 14.8124 6.39377 14.6999 6.52502L7.63118 13.5375C7.51868 13.65 7.44368 13.7813 7.38743 13.9125L6.35618 17.0625C6.29993 17.2125 6.33743 17.3625 6.41243 17.475C6.50618 17.5875 6.63743 17.6625 6.80618 17.6625H6.88118L10.0874 16.5938C10.2374 16.5375 10.3687 16.4625 10.4624 16.35L17.4937 9.33752C17.6062 9.22502 17.6812 9.05627 17.6812 8.88752C17.6812 8.71877 17.6062 8.56877 17.4749 8.43752ZM9.86243 15.7688C9.84368 15.7875 9.82493 15.7875 9.80618 15.8063L7.38743 16.6125L8.19368 14.1938C8.19368 14.175 8.21243 14.1563 8.23118 14.1375L13.3874 9.00002L15.0187 10.6313L9.86243 15.7688ZM15.5999 10.0313L13.9687 8.40002L15.1124 7.25627C15.6562 7.78127 16.1999 8.34377 16.7249 8.88752L15.5999 10.0313Z" fill="#707070"/>
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#707070" />
      <defs>
        <clipPath id="clip0_259_2919">
          <rect width="12" height="12" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarehouseSchemaDraftIcon;
