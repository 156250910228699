const geometric = require("geometric");

const getPolygonAreaIntersections = (targetPolygon, areaPolygons): string[] => {
  let result: string[] = [];
  Object.keys(areaPolygons).map(
    // @ts-ignore
    (areaId) => {
      if (geometric.polygonIntersectsPolygon(targetPolygon, areaPolygons[areaId])) {
        result.push(areaId);
      }
    }
  );

  return result;
};

export default getPolygonAreaIntersections;
