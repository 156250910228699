import AppConsts from './appconst';
import Util from '../utils/utils';
import UserLoginInfoDto from "../services/session/dto/userLoginInfoDto";

declare let abp: any;

class SignalRAspNetCoreHelper {
  initSignalR(user: UserLoginInfoDto | undefined) {
    const authToken = abp.utils.getCookieValue(AppConsts.authorization.authTokenName);
    const encryptedAuthToken = abp.utils.getCookieValue(AppConsts.authorization.encryptedAuthTokenName);

    abp.signalr = {
      autoConnect: true,
      connect: undefined,
      user: user,
      hubs: undefined,
      qs: `${AppConsts.authorization.encryptedAuthTokenName}=${encodeURIComponent(encryptedAuthToken)}`,
      remoteServiceBaseUrl: AppConsts.remoteServiceBaseUrl,
      url: '/signalrtc'
    };

    Util.loadScript(`${AppConsts.appBaseUrl}/dist/abp.signalr-client.js`);
  }
}
export default new SignalRAspNetCoreHelper();
