import axios from "axios";
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from "qs";
import AppConsts from "../lib/appconst";
import {WSApi} from "../api/swagger";
import {setAxiosErrorResponseConfig, setAxiosRequestConfig} from "./httpService";

const httpApiClient = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

httpApiClient.interceptors.request.use(
  (config) => {
    const requestConfig = setAxiosRequestConfig(config)
    requestConfig.transformResponse = (data: any) => data;

    return requestConfig;
  },
  error => Promise.reject(error)
);

httpApiClient.interceptors.response.use(
  response => response,
  error => setAxiosErrorResponseConfig(error)
);

export const apiClient = new WSApi(undefined, httpApiClient)