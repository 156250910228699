import WarehouseTopologyShelf from "./WarehouseTopologyShelf";
import Collection from "../../domain/collection";
import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopologyShelfCollection extends Collection<WarehouseTopologyShelf>{
  findByName(name: string): WarehouseTopologyShelf | undefined {
    return this.find(
      (shelf) => shelf.name === name
    );
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologyShelfCollection {
    return new WarehouseTopologyShelfCollection(
      this
        .getAll()
        .map(
          (shelf: WarehouseTopologyShelf) => shelf.withoutCells(removingCells)
        )
        .filter(
          (shelf: WarehouseTopologyShelf) => !shelf.cellAddresses.isEmpty()
        )
    );
  }
}
