import React from "react";

const WindowIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.13755 1.27502H3.37505C2.10005 1.27502 1.05005 2.32502 1.05005 3.60002V8.36252C1.05005 9.63752 2.10005 10.6875 3.37505 10.6875H8.13755C9.41255 10.6875 10.4625 9.63752 10.4625 8.36252V3.63752C10.5 2.32502 9.45005 1.27502 8.13755 1.27502ZM8.81255 8.40002C8.81255 8.77502 8.51255 9.07502 8.13755 9.07502H3.37505C3.00005 9.07502 2.70005 8.77502 2.70005 8.40002V3.63752C2.70005 3.26252 3.00005 2.96252 3.37505 2.96252H8.13755C8.51255 2.96252 8.81255 3.26252 8.81255 3.63752V8.40002Z"
    className="fill"
  />
  <path
    d="M20.6251 1.27502H15.8626C14.5876 1.27502 13.5376 2.32502 13.5376 3.60002V8.36252C13.5376 9.63752 14.5876 10.6875 15.8626 10.6875H20.6251C21.9001 10.6875 22.9501 9.63752 22.9501 8.36252V3.63752C22.9501 2.32502 21.9001 1.27502 20.6251 1.27502ZM21.3001 8.40002C21.3001 8.77502 21.0001 9.07502 20.6251 9.07502H15.8626C15.4876 9.07502 15.1876 8.77502 15.1876 8.40002V3.63752C15.1876 3.26252 15.4876 2.96252 15.8626 2.96252H20.6251C21.0001 2.96252 21.3001 3.26252 21.3001 3.63752V8.40002Z"
    className="fill"
  />
  <path
    d="M8.13755 13.2375H3.37505C2.10005 13.2375 1.05005 14.2875 1.05005 15.5625V20.325C1.05005 21.6 2.10005 22.65 3.37505 22.65H8.13755C9.41255 22.65 10.4625 21.6 10.4625 20.325V15.6C10.5 14.2875 9.45005 13.2375 8.13755 13.2375ZM8.81255 20.3625C8.81255 20.7375 8.51255 21.0375 8.13755 21.0375H3.37505C3.00005 21.0375 2.70005 20.7375 2.70005 20.3625V15.6C2.70005 15.225 3.00005 14.925 3.37505 14.925H8.13755C8.51255 14.925 8.81255 15.225 8.81255 15.6V20.3625Z"
    className="fill"
  />
  <path
    d="M20.6251 13.2375H15.8626C14.5876 13.2375 13.5376 14.2875 13.5376 15.5625V20.325C13.5376 21.6 14.5876 22.65 15.8626 22.65H20.6251C21.9001 22.65 22.9501 21.6 22.9501 20.325V15.6C22.9501 14.2875 21.9001 13.2375 20.6251 13.2375ZM21.3001 20.3625C21.3001 20.7375 21.0001 21.0375 20.6251 21.0375H15.8626C15.4876 21.0375 15.1876 20.7375 15.1876 20.3625V15.6C15.1876 15.225 15.4876 14.925 15.8626 14.925H20.6251C21.0001 14.925 21.3001 15.225 21.3001 15.6V20.3625Z"
    className="fill"
  />
</svg>
;

export default WindowIcon;
