import React, {useState} from 'react';
import PropTypes, {element} from 'prop-types';
import connect from "../../../../../../stores/connect";
import convert from "convert-units";
import {Map, List} from "immutable";
import {FormSelect} from '../../components/style/export';
import PropertyStyle from './shared-property-style';
import {UNIT_CENTIMETER, WALL_LEFT, WALL_RIGHT, WALL_BACK, WALL_FRONT} from "../../constants";
import {toFixedFloat} from "../../utils/math";
import saveElementProperties from "../../../../utils/saveElementProperties";
import defaultShelf from "../../../../catalog/items/shelving/defaultShelf.json";
import defaultSection from "../../../../catalog/items/shelving/defaultSection.json";
import {COLORS} from "../../shared-style";
import isStateReadOnly from "../../../../utils/isStateReadOnly";
import getBusyWarehouseTopologyCells from "../../../../utils/getBusyWarehouseTopologyCells";
import WarehouseTopology from "../../../../../../models/Warehouses/WarehouseTopology";
import WarehouseTopologySection from "../../../../../../models/Warehouses/WarehouseTopologySection";
import {Alert, Button, Modal, Select, Space} from "antd";
import {State} from "../../models";
import ToggleButton from "../../../../../../components/ToggleButton/ToggleButton";
import {
  FrontWallIcon,
  LeftWallIcon,
  NoWallsIcon,
  RightWallIcon,
  BackWallIcon
} from "../../../../../../components/Svg/Walls";
import EnableEditingIcon from "../../../../../../components/Svg/EnableEditingIcon";
import ShelfEditor from "../../../../../../components/ShelfEditor/ShelfEditor";
import LengthInput from "../../../../components/LengthInput/LengthInput";
import getUnit from "../../../../utils/getUnit";
import getPrecision from "../../../../utils/getPrecision";
import CountInput from "../../../../components/CountInput/CountInput";

const tableStyle = {width: "100%", borderSpacing: "2px 0", marginBottom: "2px"};
const internalTableStyle = {borderCollapse: 'collapse'};
const firstTdStyle = {width: '81px', color: COLORS.font, textTransform:'capitalize'};
// const secondTdStyle = {padding: 0};
const secondTdStyle = {};
const unitContainerStyle = {width: '5em'};
const buttonStyle = {backgroundColor: 'black',};

/**
 * @param value
 * @param onUpdate
 * @param onValid
 * @param configs
 * @param sourceElement
 * @param internalState
 * @param state
 * @param translator
 * @param {WarehouseTopology} warehouseTopology
 * @returns {JSX.Element}
 * @constructor
 */
const DisconnectedPropertyShelvingConfig = (
  {
    value,
    onUpdate,
    onValid,
    configs,
    sourceElement,
    internalState,
    state,
    translator,
    warehouseTopology,
  }
) => {
  let { hook, label, ...configRest} = configs;
  const isReadOnly = isStateReadOnly(state);
  const saveElement = (valueMerged) => saveElementProperties(
    valueMerged,
    onUpdate,
    hook,
    sourceElement,
    internalState,
    state
  );
  const updateShelfHeight = (shelfIndex, lengthInput, unitInput = UNIT_CENTIMETER) => {
    const newLength = toFixedFloat(lengthInput);
    const height = value.getIn(['shelves', shelfIndex, 'height']);

    const heightMerged = height.merge({
      length: unitInput !== UNIT_CENTIMETER
        ? convert(newLength).from(unitInput).to(UNIT_CENTIMETER)
        : newLength
      ,
      _length: lengthInput,
      _unit: unitInput,
    });

    const valueMerged = value.setIn(['shelves', shelfIndex, 'height'], heightMerged);

    return saveElement(valueMerged);
  };

  const updateSectionWidth = (shelfKey, sectionKey, lengthInput, unitInput = UNIT_CENTIMETER) => {
    const newWidth = toFixedFloat(lengthInput);
    const width = value.getIn(['shelves', shelfKey, 'sections', sectionKey, 'width']);

    const widthMerged = width.merge({
      length: unitInput !== UNIT_CENTIMETER
        ? convert(newWidth).from(unitInput).to(UNIT_CENTIMETER)
        : newWidth
      ,
      _length: lengthInput,
      _unit: unitInput,
    });

    const valueMerged = value.setIn(['shelves', shelfKey, 'sections', sectionKey, 'width'], widthMerged);

    return saveElement(valueMerged);
  };

  const addShelf = () => {
    const newShelf = Map(defaultShelf)
      .set('height', Map(defaultShelf.height.defaultValue))
      .set('thickness', Map(defaultShelf.thickness.defaultValue))
      .set('sections', List([]))
      .set('topologyShelf', null)
    ;
    const shelves = value.get('shelves');

    const shelvesMerged = shelves.push(newShelf);
    const valueMerged = value.set('shelves', shelvesMerged);

    return saveElement(valueMerged);
  };

  const addCell = (shelfKey) => {
    const newCell = Map(defaultSection)
      .set('width', Map(defaultSection.width.defaultValue))
      .set('topologyCell', null)
    ;

    const cells = value.getIn(['shelves', shelfKey, 'sections']);

    const cellsMerged = cells.push(newCell);

    const valueMerged = value.setIn(['shelves', shelfKey, 'sections'], cellsMerged);

    return saveElement(valueMerged);
  };

  const deleteShelf = (shelfIndex) => {
    const updatedShelves = value.get('shelves').delete(shelfIndex);
    const valueMerged = value.set('shelves', updatedShelves);

    return saveElement(valueMerged);
  };

  const deleteCell = (shelfKey, cellKey) => {
    const updatedCells = value.getIn(['shelves', shelfKey, 'sections']).delete(cellKey);
    const valueMerged = value.setIn(['shelves', shelfKey, 'sections'], updatedCells);

    return saveElement(valueMerged);
  };

  const busyCells = getBusyWarehouseTopologyCells(state);
  const freeToUseTopology = warehouseTopology && warehouseTopology.withoutCells(busyCells);

  const removeWarehouseTopologyShelfCellAddresses = (shelfKey, valueMerged = value) => {
    const cells = valueMerged.getIn(['shelves', shelfKey, 'sections']);
    cells && cells.map(
      (cell, cellKey) => {
        valueMerged = valueMerged.setIn(['shelves', shelfKey, 'sections', cellKey, 'topologyCell'], null);
      }
    );
    return valueMerged;
  };

  const removeWarehouseTopologyShelves = (valueMerged = value) => {
    const shelves = valueMerged.get('shelves');
    shelves && shelves.map(
      (shelf, shelfKey) => {
        valueMerged = removeWarehouseTopologyShelfCellAddresses(shelfKey, valueMerged);
        valueMerged = valueMerged.setIn(['shelves', shelfKey, 'topologyShelf'], null);
      }
    );
    return valueMerged;
  };

  const removeWarehouseTopologyRack = (valueMerged = value) => {
    valueMerged = removeWarehouseTopologyShelves(valueMerged);
    valueMerged = valueMerged.setIn(['topology', 'rackName'], null);
    return valueMerged;
  };

  const removeWarehouseTopologyLine = (valueMerged = value) => {
    valueMerged = removeWarehouseTopologyRack(valueMerged);
    valueMerged = valueMerged.setIn(['topology', 'line'], null);
    return valueMerged;
  };

  /**
   * @param {string} section
   */
  const updateWarehouseTopologySection = (section) => {
    let valueMerged = value
      .setIn(['topology', 'section'], section)
    ;
    valueMerged = removeWarehouseTopologyLine(valueMerged);

    return saveElement(valueMerged);
  };

  /**
   * @param {string} line
   */
  const updateWarehouseTopologyLine = (line) => {
    let valueMerged = value
      .setIn(['topology', 'line'], line)
    ;
    valueMerged = removeWarehouseTopologyRack(valueMerged);

    return saveElement(valueMerged);
  };

  const updateWarehouseTopologyRackName = (rackName) => {
    let valueMerged = value
      .setIn(['topology', 'rackName'], rackName)
    ;
    valueMerged = removeWarehouseTopologyShelves(valueMerged);

    return saveElement(valueMerged);
  };

  const warehouseTopologySectionName = value.getIn(['topology', 'section']);
  const warehouseTopologySection = warehouseTopology?.sections.findByName(warehouseTopologySectionName);
  const freeToUseSection = freeToUseTopology?.sections?.findByName(warehouseTopologySectionName);
  const topologySectionOptions = freeToUseTopology?.sections.map(
    (section) => section.name
  );
  !!warehouseTopologySectionName
    && !topologySectionOptions.includes(warehouseTopologySectionName)
    && topologySectionOptions.unshift(warehouseTopologySectionName)
  ;

  const topologyLineOptions = warehouseTopologySectionName
    ? (
      freeToUseSection?.lines.map(
        (line) => line.name
      ) ?? []
    )
    : []
  ;
  const warehouseTopologyLineName = value.getIn(['topology', 'line']);
  const warehouseTopologyLine = warehouseTopologyLineName && warehouseTopologySection
    ? warehouseTopologySection.lines.findByName(warehouseTopologyLineName)
    : null
  ;
  !!warehouseTopologyLineName
    && !topologyLineOptions.includes(warehouseTopologyLineName)
    && topologyLineOptions.unshift(warehouseTopologyLineName)
  ;
  const freeToUseLine = warehouseTopologyLine && freeToUseSection?.lines.findByName(
    warehouseTopologyLineName
  );
  const topologyRackOptions = freeToUseLine
    ? freeToUseLine.racks.map(
      (rack) => rack.name
    )
    : []
  ;
  const warehouseTopologyRackName = value.getIn(['topology', 'rackName']);
  const warehouseTopologyRack = warehouseTopologyLine && warehouseTopologyRackName
    ? warehouseTopologyLine.racks.findByName(warehouseTopologyRackName)
    : null
  ;
  !!warehouseTopologyRackName
    && !topologyRackOptions.includes(warehouseTopologyRackName)
    && topologyRackOptions.unshift(warehouseTopologyRackName)
  ;

  const freeToUseRack = freeToUseLine && warehouseTopologyRackName && freeToUseLine.racks.findByName(
    warehouseTopologyRackName
  );

  const updateWarehouseTopologyShelfName = (shelfIndex, shelfName) => {
    let valueMerged = value.setIn(['shelves', shelfIndex, 'topologyShelf'], shelfName);
    valueMerged = removeWarehouseTopologyShelfCellAddresses(shelfIndex, valueMerged);

    return saveElement(valueMerged);
  };

  const updateWarehouseTopologyCellAddress = (shelfIndex, cellIndex, cellAddress) => {
    const valueMerged = value.setIn(['shelves', shelfIndex, 'sections', cellIndex, 'topologyCell'], cellAddress);
    return saveElement(valueMerged);
  };

  const [is1sModalOpen, set1sModalOpen] = useState(false);
  const [modalRackChoice, setModalRackChoice] = useState(warehouseTopologyRackName);
  const show1sModal = () => {
    setModalRackChoice(warehouseTopologyRackName);
    set1sModalOpen(true)
  };
  const hide1sModal = () => set1sModalOpen(false);
  const confirmModalRackChoice = () => {
    updateWarehouseTopologyRackName(modalRackChoice);
    if (!!modalRackChoice) {
      let valueMerged = value.merge();
      valueMerged = valueMerged
        .setIn(['topology', 'rackName'], modalRackChoice)
      ;
      valueMerged.get('shelves').map(
        (shelf, shelfKey) => {
          const updatedShelves = valueMerged.get('shelves').delete(shelfKey);
          valueMerged = valueMerged.set('shelves', updatedShelves);
        }
      );
      const rackHeight = sourceElement.getIn(['properties', 'height']);
      const rackWidth = sourceElement.getIn(['properties', 'width']);
      const rackHeightLength = convert(rackHeight.get('length')).from(rackHeight.get('unit')).to(UNIT_CENTIMETER);
      const rackWidthLength = convert(rackWidth.get('length')).from(rackWidth.get('unit')).to(UNIT_CENTIMETER);
      const rack = warehouseTopology.racks.findByName(modalRackChoice);
      if (rack && !rack.shelves.isEmpty()) {
        rack.shelves.map(
          (shelf) => {
            const shelfHeight = defaultShelf.height.defaultValue;
            shelfHeight.length = rackHeightLength / rack.shelves.count();
            shelfHeight._length = rackHeightLength / rack.shelves.count();

            let newShelf = Map(defaultShelf)
              .set('height', Map(shelfHeight))
              .set('sections', List([]))
              .set('topologyShelf', shelf.name)
            ;
            if (!shelf.cellAddresses.isEmpty()) {
              shelf.cellAddresses.map(
                (cellAddress) => {
                  const cellWidth = defaultSection.width.defaultValue;
                  cellWidth.length = rackWidthLength / shelf.cellAddresses.count();
                  cellWidth._length = rackWidthLength / shelf.cellAddresses.count();

                  const newCell = Map(defaultSection)
                    .set('width', Map(cellWidth))
                    .set('topologyCell', cellAddress.value)
                  ;

                  const cells = newShelf.get('sections');

                  const cellsMerged = cells.push(newCell);

                  newShelf = newShelf.set('sections', cellsMerged);
                }
              );
            }
            const shelves = valueMerged.get('shelves');

            const shelvesMerged = shelves.push(newShelf);
            valueMerged = valueMerged.set('shelves', shelvesMerged);
          }
        );
      }
      saveElement(valueMerged);
    }
    hide1sModal();
  };

  const walls = value.has('walls')
    ? value.get('walls')
    : Map({
        [WALL_FRONT]: false,
        [WALL_LEFT]: false,
        [WALL_RIGHT]: false,
        [WALL_BACK]: false,
      })
  ;

  const toggleWall = (name) => {
    const wallsMerged = walls.set(name, !walls.get(name));
    const valueMerged = value.set('walls', wallsMerged);
    saveElement(valueMerged);
  };

  const removeAllWalls = () => {
    const wallsMerged = walls
      .set(WALL_LEFT, false)
      .set(WALL_RIGHT, false)
      .set(WALL_BACK, false)
      .set(WALL_FRONT, false)
    ;
    const valueMerged = value.set('walls', wallsMerged);
    saveElement(valueMerged);
  };


  const isManuallyEditingShelves = value.has('manuallyEditingShelves')
    ? value.get('manuallyEditingShelves')
    : false
  ;

  let allShelvesHeight;
  let allShelvesThickness;
  let allSectionsWidth;
  let allSectionsThickness;
  let canGroupEditShelves = true;
  const unit = getUnit(state);
  const precision = getPrecision(unit);

  const shelves = value.get('shelves');
  shelves.map(
    (shelf) => {
      if (canGroupEditShelves) {
        const height = shelf.getIn(['height', '_length']).toFixed(precision);
        const thickness = shelf.getIn(['thickness', '_length'])?.toFixed(precision) || defaultShelf.thickness;
        if (!allShelvesHeight) {
          allShelvesHeight = height;
        } else if (height !== allShelvesHeight) {
          canGroupEditShelves = false;
        }
        if (!allShelvesThickness) {
          allShelvesThickness = thickness;
        } else if (thickness !== allShelvesThickness) {
          canGroupEditShelves = false;
        }

        const sections = shelf.get('sections');
        canGroupEditShelves && sections.map(
          (section) => {
            const width = section.getIn(['width', '_length']).toFixed(precision);
            const sectionThickness = section.getIn(['thickness', '_length'])?.toFixed(precision) || defaultSection.thickness;
            if (!allSectionsWidth) {
              allSectionsWidth = width;
            } else if (width !== allSectionsWidth) {
              canGroupEditShelves = false;
            }
            if (!allSectionsThickness) {
              allShelvesThickness = sectionThickness;
            } else if (sectionThickness !== allSectionsThickness) {
              canGroupEditShelves = false;
            }
          }
        );
      }
    }
  );

  const rackType = sourceElement.getIn(['properties', 'type']);
  const canBeDoubleSide = rackType !== 'shelf-rack';
  const isDoubleSide = value.get('doubleSideRack');
  const setDoubleSide = (v) => {
    const valueMerged = value.set('doubleSideRack', v);
    saveElement(valueMerged);
  }

  const toggleManualEditingShelves = () => {
    const willManuallyEditShelves = !isManuallyEditingShelves;
    if (!willManuallyEditShelves && !canGroupEditShelves) {
      // cannot switch back to the default shelf group editing mode
      return;
    }
    const valueMerged = value.set('manuallyEditingShelves', !isManuallyEditingShelves);
    saveElement(valueMerged);
  };


  return (
    <div className="attributes">
      {canBeDoubleSide && (
        <div className="attributes-row">
          <div className="attributes-row-title" style={{flexGrow: 0, width: '81px'}}>
            {translator.t('Sides')}
          </div>
          <div className="attributes-row-value" style={{
            flexGrow: 1,
          }}>
            <ToggleButton
              isActive={!isDoubleSide}
              isDisabled={isReadOnly}
              onActivate={() => setDoubleSide(false)}
              onDeactivate={() => {}}
              style={{
                width: 'calc(50% - 1px)'
              }}
            >
              {translator.t('1 side')}
            </ToggleButton>
            <ToggleButton
              isActive={isDoubleSide}
              isDisabled={isReadOnly}
              onActivate={() => setDoubleSide(true)}
              onDeactivate={() => {}}
              style={{
                width: 'calc(50% - 1px)'
              }}
            >
              {translator.t('2 sides')}
            </ToggleButton>
          </div>
        </div>
      )}
      <div className="attributes-row">
        <div className="attributes-row-title">
          {translator.t('Walls')}
        </div>
        <div className="attributes-row-value walls-attributes">
          <ToggleButton
            isActive={
              !walls.get(WALL_LEFT)
              && !walls.get(WALL_RIGHT)
              && !walls.get(WALL_BACK)
              && !walls.get(WALL_FRONT)
            }
            isDisabled={isReadOnly}
            onActivate={removeAllWalls}
            onDeactivate={removeAllWalls}
          >
            <NoWallsIcon />
          </ToggleButton>
          <ToggleButton
            isActive={!!walls.get(WALL_LEFT)}
            isDisabled={isReadOnly}
            onActivate={() => toggleWall(WALL_LEFT)}
            onDeactivate={() => toggleWall(WALL_LEFT)}
          >
            <LeftWallIcon />
          </ToggleButton>
          <ToggleButton
            isActive={!!walls.get(WALL_BACK)}
            isDisabled={isReadOnly}
            onActivate={() => toggleWall(WALL_BACK)}
            onDeactivate={() => toggleWall(WALL_BACK)}
          >
            <BackWallIcon />
          </ToggleButton>
          <ToggleButton
            isActive={!!walls.get(WALL_RIGHT)}
            isDisabled={isReadOnly}
            onActivate={() => toggleWall(WALL_RIGHT)}
            onDeactivate={() => toggleWall(WALL_RIGHT)}
          >
            <RightWallIcon />
          </ToggleButton>
          <ToggleButton
            isActive={!!walls.get(WALL_FRONT)}
            isDisabled={isReadOnly}
            onActivate={() => toggleWall(WALL_FRONT)}
            onDeactivate={() => toggleWall(WALL_FRONT)}
          >
            <FrontWallIcon />
          </ToggleButton>
        </div>
      </div>
      <div className="shelves-and-cells">
        <div className="shelves-and-cells-header">
          <div className="attributes-row-title shelves-and-cells-header-title">
            {translator.t('SectionsAndCells')}
          </div>
          <div className="shelves-and-cells-header-divider">
            <hr size="1px" />
          </div>
          <div className="shelves-and-cells-header-toggle-editing">
            <ToggleButton
              isActive={isManuallyEditingShelves}
              isDisabled={isReadOnly}
              onActivate={toggleManualEditingShelves}
              onDeactivate={toggleManualEditingShelves}
            >
              <EnableEditingIcon />
            </ToggleButton>
          </div>
        </div>
        <div className="shelves-and-cells-body">
          {!isManuallyEditingShelves && (
            <div className="shelves-and-cells-body-auto">
              <div className="shelves-and-cells-body-auto-row">
                <div className="shelves-and-cells-body-auto-row-count shelves-and-cells-body-auto-row-value">
                  <div className="shelves-and-cells-body-auto-row-value-title">{translator.t('Shelves')}</div>
                  <CountInput onChange={() => {}} translator={translator} value={0} />
                </div>
                <div className="shelves-and-cells-body-auto-row-thickness shelves-and-cells-body-auto-row-value">
                  <div className="shelves-and-cells-body-auto-row-value-title">{translator.t('Thickness')}</div>
                  <LengthInput onChange={() => {}} state={state} translator={translator} value={0} />
                </div>
                <div className="shelves-and-cells-body-auto-row-size shelves-and-cells-body-auto-row-value">
                  <div className="shelves-and-cells-body-auto-row-value-title">{translator.t('Height (auto)')}</div>
                  <LengthInput disabled={true} onChange={() => {}} state={state} translator={translator} value={0} />
                </div>
              </div>
            </div>
          )}
          {isManuallyEditingShelves && (
            <div className="shelves-and-cells-body-manual">
              <div className="shelves-and-cells-body-manual-add">
                <button onClick={addShelf} disabled={isReadOnly} className="shelves-and-cells-body-manual-add-button">
                  {translator.t('Add Shelf')}
                </button>
              </div>
              {value.has('shelves') && value.get('shelves').reverse().map((shelf, shelfKey) => {
                return (
                  <ShelfEditor
                    key={shelfKey}
                    onValid={onValid}
                    save={saveElement}
                    shelf={shelf}
                    shelfKey={shelfKey}
                    state={state}
                    totalShelves={value.get('shelves').size}
                    translator={translator}
                    value={value}
                  />
                );
              })}
            </div>
          )}
        </div>

      </div>
      <table className="attributes-table" style={PropertyStyle.tableStyle}>
        <tbody>
        {!!freeToUseTopology && (
          <>
            <tr>
              <td colSpan={2}>
                {translator.t('Warehouse')}
              </td>
            </tr>
            <tr>
              <td style={firstTdStyle}>
                { translator.t('Section1s') }
              </td>
              <td>
                <FormSelect
                  state={state}
                  value={value.getIn(['topology', 'section']) || ''}
                  onChange={event => updateWarehouseTopologySection(event.target.value) }
                >
                  {
                    [
                      <option key="" value=""></option>,
                      ...topologySectionOptions.map(
                        /**
                         * @param {string} section
                         * @returns {JSX.Element}
                         */
                        section => <option key={section} value={section}>{section}</option>
                      )
                    ]

                  }
                </FormSelect>
              </td>
            </tr>
            <tr>
              <td style={firstTdStyle}>
                { translator.t('Line1s') }
              </td>
              <td>
                <FormSelect
                  disabled={!warehouseTopologySectionName || isStateReadOnly(state)}
                  state={state}
                  value={warehouseTopologyLineName || ''}
                  onChange={event => updateWarehouseTopologyLine(event.target.value) }
                >
                  {[
                    <option key="" value=""></option>,
                    ...topologyLineOptions.map(
                      /**
                       * @param {string} lineName
                       * @returns {JSX.Element}
                       */
                      lineName => <option key={lineName} value={lineName}>{lineName}</option>
                    )
                  ]}
                </FormSelect>
              </td>
            </tr>
            <tr>
              <td style={firstTdStyle}>
                { translator.t('Rack1s') }
              </td>
              <td>
                <FormSelect
                  disabled={!warehouseTopologySectionName || !warehouseTopologyLineName || isStateReadOnly(state)}
                  state={state}
                  value={warehouseTopologyRackName || ''}
                  onChange={event => updateWarehouseTopologyRackName(event.target.value) }
                >
                  {[
                    <option key="" value=""></option>,
                    ...topologyRackOptions.map(
                      /**
                       * @param {string} rackName
                       * @returns {JSX.Element}
                       */
                      rackName => <option key={rackName} value={rackName}>{rackName}</option>
                    )
                  ]}
                </FormSelect>
              </td>
            </tr>
            {!!warehouseTopologySectionName && !!warehouseTopologyLineName && (
              <tr>
                <td style={firstTdStyle}>
                  { translator.t('Rack1s') }
                </td>
                <td>
                  <Button
                    disabled={isStateReadOnly(state)}
                    type="primary"
                    onClick={show1sModal}
                    style={{width: '100%'}}
                  >
                    {!!warehouseTopologyRackName && !!warehouseTopologyRack && (
                      <>{warehouseTopologyRack.systemName}</>
                    )}
                    {!warehouseTopologyRackName && (
                      <>{ translator.t('Select') }</>
                    )}
                  </Button>
                </td>
              </tr>
            )}
          </>
        )}
        <tr>
          <td colSpan={2}>
            {translator.t('Shelves')}
          </td>
        </tr>
        {value.has('shelves') && value.get('shelves').map(
          (shelf, shelfKey) => {
            const topologyShelfName = shelf.get('topologyShelf');
            const warehouseTopologyShelf = topologyShelfName
              ? warehouseTopologyRack?.shelves?.findByName(topologyShelfName)
              : null
            ;
            const freeToUseShelf = topologyShelfName
              && freeToUseRack
              && freeToUseRack.shelves.findByName(topologyShelfName)
            ;
            const topologyShelfOptions = freeToUseRack?.shelves?.map(
              (shelf) => shelf.name
            ) || [];

            !!topologyShelfName
              && !topologyShelfOptions.includes(topologyShelfName)
              && topologyShelfOptions.unshift(topologyShelfName)
            ;

            return (
              <tr key={ shelfKey }>
                <td colSpan={2}>
                  <table style={PropertyStyle.tableStyle}>
                    <tbody>
                    <tr>
                      <td style={{textAlign: 'center',}} colSpan={2}>
                        &darr;
                        { translator.t('Shelf') }
                        &darr;
                        {/*{!isReadOnly && (*/}
                        {/*  <button style={ buttonStyle } onClick={ () => deleteShelf(shelfKey) }>*/}
                        {/*    { translator.t('Delete') }*/}
                        {/*  </button>*/}
                        {/*)}*/}
                      </td>
                    </tr>
                    <tr>
                      <td style={firstTdStyle}>
                        { translator.t('height') }
                      </td>
                      <td style={secondTdStyle}>
                        <LengthInput
                          disabled={true}
                          state={state}
                          value={shelf.getIn(['height', '_length'])}
                          unit={shelf.getIn(['height', '_unit'])}
                          translator={translator}
                          onChange={(centimeters) => updateShelfHeight(shelfKey, centimeters, UNIT_CENTIMETER)}
                        />
                      </td>
                    </tr>
                    {!!freeToUseTopology && (
                      <tr>
                        <td style={firstTdStyle}>
                          { translator.t('Shelf1s') }
                        </td>
                        <td>
                          <FormSelect
                            disabled={
                              true
                              // !warehouseTopologySectionName
                              // || !warehouseTopologyLineName
                              // || !warehouseTopologyRackName
                              // || isStateReadOnly(state)
                            }
                            state={state}
                            value={topologyShelfName || ''}
                            onChange={event => updateWarehouseTopologyShelfName(shelfKey, event.target.value) }
                          >
                            {[
                              <option key="" value=""></option>,
                              ...topologyShelfOptions.map(
                                /**
                                 * @param {string} shelfName
                                 * @returns {JSX.Element}
                                 */
                                shelfName => <option key={shelfName} value={shelfName}>{shelfName}</option>
                              )
                            ]}
                          </FormSelect>
                        </td>
                      </tr>
                    )}

                    { shelf.has('sections') && shelf.get('sections').map(
                      (cell, cellKey) => {
                        const topologyCellAddress = cell.get('topologyCell');
                        const topologyCellOptions = freeToUseShelf?.cellAddresses?.map(
                            (cellAddress) => cellAddress.value
                          ) || []
                        ;
                        !!topologyCellAddress
                          && !topologyCellOptions.includes(topologyCellAddress)
                          && topologyCellOptions.unshift(topologyCellAddress)
                        ;
                        return (
                          <tr key={`${shelfKey}-${cellKey}`}>
                            <td colSpan={2}>
                              <table style={PropertyStyle.tableStyle}>
                                <tbody>
                                <tr>
                                  <td style={{textAlign: 'center',}} colSpan={2}>
                                    &darr;
                                    { translator.t('Cell') }
                                    &darr;
                                    {/*{!isReadOnly && (*/}
                                    {/*  <button style={ buttonStyle } onClick={ () => deleteCell(shelfKey, cellKey) }>*/}
                                    {/*    { translator.t('Delete') }*/}
                                    {/*  </button>*/}
                                    {/*)}*/}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={firstTdStyle}>
                                    { translator.t('width') }
                                  </td>
                                  <td style={secondTdStyle}>
                                    <LengthInput
                                      disabled={true}
                                      onChange={centimeters => updateSectionWidth(shelfKey, cellKey, centimeters, UNIT_CENTIMETER)}
                                      state={state}
                                      translator={translator}
                                      unit={shelf.getIn(['width', '_unit'])}
                                      value={cell.getIn(['width', '_length'])}
                                    />
                                  </td>
                                </tr>
                                {!!freeToUseTopology && (
                                  <tr>
                                    <td style={firstTdStyle}>
                                      { translator.t('Cell1s') }
                                    </td>
                                    <td>
                                      <FormSelect
                                        disabled={
                                          true
                                          // !warehouseTopologySectionName
                                          // || !warehouseTopologyLineName
                                          // || !warehouseTopologyRackName
                                          // || !warehouseTopologyShelf
                                          // || isStateReadOnly(state)
                                        }
                                        state={state}
                                        value={topologyCellAddress || ''}
                                        onChange={
                                          event => updateWarehouseTopologyCellAddress(
                                            shelfKey,
                                            cellKey,
                                            event.target.value
                                          )
                                        }
                                      >
                                        {[
                                          <option key="" value=""></option>,
                                          ...topologyCellOptions.map(
                                            /**
                                             * @param {string} cellAddress
                                             * @returns {JSX.Element}
                                             */
                                            cellAddress => <option key={cellAddress} value={cellAddress}>{cellAddress}</option>
                                          )
                                        ]}
                                      </FormSelect>
                                    </td>
                                  </tr>
                                )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        );
                      }
                    ) }
                    {/*{!isReadOnly && (*/}
                    {/*  <tr>*/}
                    {/*    <td style={{textAlign: 'center',}} colSpan={2}>*/}
                    {/*      <button style={ buttonStyle } onClick={ () => addCell(shelfKey) }>*/}
                    {/*        { translator.t('Add Cell') }*/}
                    {/*      </button>*/}
                    {/*    </td>*/}
                    {/*  </tr>*/}
                    {/*)}*/}
                    </tbody>
                  </table>
                </td>
              </tr>
            );
          }
        )}
        {/*{!isReadOnly && (*/}
        {/*  <tr>*/}
        {/*    <td style={{textAlign: 'center',}} colSpan={2}>*/}
        {/*      <button style={ buttonStyle } onClick={ addShelf }>*/}
        {/*        { translator.t('Add Shelf') }*/}
        {/*      </button>*/}
        {/*    </td>*/}
        {/*  </tr>*/}
        {/*)}*/}
        </tbody>
      </table>
      <Modal
        visible={is1sModalOpen}
        title={translator.t("Rack1s")}
        onCancel={hide1sModal}
        footer={[
          <Button key="ok" type="primary" loading={false} onClick={confirmModalRackChoice}>
            {!modalRackChoice && translator.t("Rack1sNotSelected")}
            {!!modalRackChoice && modalRackChoice === warehouseTopologyRackName && translator.t("Confirm")}
            {!!modalRackChoice && modalRackChoice !== warehouseTopologyRackName && translator.t("Rack1sConfirm")}
          </Button>,
          <Button key="cancel" onClick={hide1sModal}>
            {translator.t("Cancel")}
          </Button>
        ]}
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div style={{ display: 'flex', gap: '10px', flexDirection: "column" }}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px', flexDirection: "row"}}>
              <div>{translator.t('Rack')}</div>
              <Select
                defaultValue={modalRackChoice}
                style={{width: '100%', flexGrow: 1}}
                onChange={setModalRackChoice}
                options={[
                  {
                    value: "",
                    label: translator.t("Rack1sNotSelected")
                  },
                  ...topologyRackOptions.map(
                    /**
                     * @param {string} rackName
                     * @returns {{label: string, value: string}}
                     */
                    (rackName) => ({
                      value: rackName,
                      label: rackName,
                    })
                  )
                ]}
              />
            </div>
          </div>
          {!!modalRackChoice && modalRackChoice !== warehouseTopologyRackName && (
            <Alert message={translator.t('Rack1sMessage')} type="warning" style={{width: '100%'}} />
          )}
        </Space>
      </Modal>
    </div>
  );
}

DisconnectedPropertyShelvingConfig.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired,
  warehouseTopology: PropTypes.instanceOf(WarehouseTopology),
};

export default connect(({store}) => ({
  warehouseTopology: store.warehouseStore.currentWarehouseTopology,
}))(DisconnectedPropertyShelvingConfig);
