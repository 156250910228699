const geometric = require("geometric");

const getPolygonAreaId = (targetPolygon, areaPolygons): string|null => {
  let result: string|null = null;
  Object.keys(areaPolygons).map(
    // @ts-ignore
    (areaId) => {
      if (geometric.polygonInPolygon(targetPolygon, areaPolygons[areaId])) {
        result = areaId;
      }
    }
  );

  return result;
};

export default getPolygonAreaId;
