import React from "react";

const EnableEditingIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.8401 2.56C11.8001 2.42 11.6199 2.38 11.5001 2.48L9.48012 4.5C9.31992 4.66 9.08012 4.66 8.91992 4.5L7.49992 3.08C7.33992 2.92 7.33992 2.68 7.49992 2.52L9.53992 0.48C9.63992 0.38 9.59992 0.2 9.45992 0.14C9.12012 0.0600002 8.76012 0 8.40012 0C6.28012 0 4.58012 1.84 4.82012 4C4.86012 4.34 4.94012 4.64 5.06012 4.94L0.400117 9.62C-0.139883 10.16 -0.139883 11.06 0.400117 11.6C0.680117 11.88 1.04012 12.02 1.40012 12.02C1.76012 12.02 2.12012 11.8802 2.40012 11.6L7.06012 6.94C7.36012 7.06 7.68032 7.14 8.00012 7.18C10.1801 7.42 12.0001 5.72 12.0001 3.6C12.0001 3.24 11.9401 2.88 11.8401 2.56Z"
                className="fill"
            />
        </svg>

    );
};

export default EnableEditingIcon;
