import updateItemAreaId from "./updateItemAreaId";
import getAreaPolygons from "./getAreaPolygons";
import Item from "../react-planner/src/class/item";
import {Map} from "immutable";

const updateMultipleItemsAreaId = (
  state,
  layerID,
  items: (Map<string, Item>)[] | Map<string, Item>,
) => {
  if (Map.isMap(items)) {
    items = items.toArray().map(([itemId, item]) => item) as Map<string, Item>[];
  }
  const vertices = state.getIn(['scene', 'layers', layerID, 'vertices']).toJS();
  const allAreas = state.getIn(['scene', 'layers', layerID, 'areas']);
  const correctAreaPolygons = getAreaPolygons(
    state,
    layerID,
    allAreas.filter(
      (area) => area.get('type') === 'area'
    ),
    vertices
  );
  const incorrectAreaPolygons = getAreaPolygons(
    state,
    layerID,
    allAreas.filter(
      (area) => area.get('type') !== 'area'
    ),
    vertices
  );
  items.forEach(
    (item) => {
      const draggingItemWithoutAreaId = item.deleteIn(['misc', 'areaId']);
      state = state.mergeIn(['scene', 'layers', layerID, 'items', item.get('id')], draggingItemWithoutAreaId);
      state = updateItemAreaId(state, layerID, item, vertices, correctAreaPolygons, incorrectAreaPolygons);
    }
  );
  return state;
};

export default updateMultipleItemsAreaId;
