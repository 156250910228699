import React from "react";

const WallItemsIcon: React.FC = ({}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_645_2036)">
    <mask id="mask0_645_2036" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path
        d="M21.3751 11.175H20.2501V8.8875C20.2501 7.6125 19.2001 6.5625 17.9251 6.5625H6.11255C4.83755 6.5625 3.78755 7.6125 3.78755 8.8875V11.175H2.62505C1.53755 11.175 0.675049 12.075 0.675049 13.125V15.4875C0.675049 16.575 1.57505 17.4375 2.62505 17.4375H21.3751C22.4626 17.4375 23.3251 16.5375 23.3251 15.4875V13.125C23.3251 12.0375 22.4626 11.175 21.3751 11.175ZM18.5626 8.8875V11.175H12.8251V8.25H17.8876C18.2626 8.2125 18.5626 8.5125 18.5626 8.8875ZM14.9626 12.825V15.75H9.03755V12.825H14.9626ZM5.43755 8.8875C5.43755 8.5125 5.73755 8.2125 6.11255 8.2125H11.175V11.1375H5.43755V8.8875ZM2.32505 15.4875V13.125C2.32505 12.975 2.43755 12.825 2.62505 12.825H7.35005V15.75H2.62505C2.47505 15.7875 2.32505 15.6375 2.32505 15.4875ZM21.6751 15.4875C21.6751 15.6375 21.5626 15.7875 21.3751 15.7875H16.6501V12.8625H21.3751C21.5251 12.8625 21.6751 12.975 21.6751 13.1625V15.4875Z"
        className="fill" />
    </mask>
    <g mask="url(#mask0_645_2036)">
      <rect width="24" height="24" className="fill"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_645_2036">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export default WallItemsIcon;
