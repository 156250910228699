import {List} from "immutable";

export default class ListUtils {
  /**
   * @param {List} list
   * @param {string|number|boolean} element
   */
  static withScalar(list, element) {
    return ListUtils.withoutScalar(list, element).push(element);
  }

  /**
   @param {List} list
   @param {string|number|boolean} element
   */
  static withoutScalar(list, element) {
    return list.filter(
      (item) => item !== element
    );
  }
}
