import {Map} from "immutable";
import {State} from "../../scenes/Planner/react-planner/src/models";
import React, {useState} from "react";
import defaultShelf from "../../scenes/Planner/catalog/items/shelving/defaultShelf.json";
import defaultSection from "../../scenes/Planner/catalog/items/shelving/defaultSection.json";
import {UNIT_CENTIMETER, UNITS_LENGTH} from "../../scenes/Planner/react-planner/src/constants";
import {toFixedFloat} from "../../scenes/Planner/react-planner/es/utils/math";
import convert from "convert-units";
import classNames from "classnames";
import ToggleButton from "../ToggleButton/ToggleButton";
import CollapsedIcon from "../Svg/CollapsedIcon";
import ExpandedIcon from "../Svg/ExpandedIcon";
import ShelfHeightIcon from "../Svg/ShelfHeightIcon";
import SectionWidthIcon from "../Svg/SectionWidthIcon";
import {FormNumberInput, FormSelect} from "../../scenes/Planner/react-planner/src/components/style/export";
import ShelfThicknessIcon from "../Svg/ShelfThicknessIcon";
import SectionThicknessIcon from "../Svg/SectionThicknessIcon";
import isStateReadOnly from "../../scenes/Planner/utils/isStateReadOnly";
import DeleteRowIcon from "../Svg/DeleteRowIcon";
import {Modal} from "antd";
import LengthInput from "../../scenes/Planner/components/LengthInput/LengthInput";

interface ShelfSectionEditorProps {
    onValid: () => void;
    save: (config: Map<unknown, unknown>) => void;
    section: Map<unknown, unknown>;
    sectionKey: number;
    shelfKey: number;
    state: State;
    totalSections: number;
    translator: {
        t: (value: string) => string;
    };
    value: Map<unknown, unknown>;
}

const ShelfSectionEditor: React.FC<ShelfSectionEditorProps> = (
    {
        onValid,
        save,
        section,
        sectionKey,
        shelfKey,
        state,
        totalSections,
        translator,
        value,
    }
) => {
    const sectionIndex = totalSections - sectionKey - 1;

    const width = section.has('width')
        ? section.get('width')
        : Map(defaultSection.width)
    ;

    const updateSectionWidth = (lengthInput, unitInput = UNIT_CENTIMETER) => {
        const newWidth = toFixedFloat(lengthInput);
        // @ts-ignore
        const widthMerged = width.merge({
            length: unitInput !== UNIT_CENTIMETER
                ? convert(newWidth).from(unitInput).to(UNIT_CENTIMETER)
                : newWidth
            ,
            _length: lengthInput,
            _unit: unitInput,
        });
        const valueMerged = value.setIn(['shelves', shelfKey, 'sections', sectionIndex, 'width'], widthMerged);
        save(valueMerged);
    };

    const thickness = section.has('thickness')
        ? section.get('thickness')
        : Map(defaultSection.thickness)
    ;

    const updateSectionThickness = (lengthInput, unitInput = UNIT_CENTIMETER) => {
        const newThickness = toFixedFloat(lengthInput);
        // @ts-ignore
        const thicknessMerged = thickness.merge({
            length: unitInput !== UNIT_CENTIMETER
                ? convert(newThickness).from(unitInput).to(UNIT_CENTIMETER)
                : newThickness
            ,
            _length: lengthInput,
            _unit: unitInput,
        });

        const valueMerged = value.setIn(['shelves', shelfKey, 'sections', sectionIndex, 'thickness'], thicknessMerged);

        save(valueMerged);
    };

    //@ts-ignore
    const boxesCount: number = section.has('boxes')
        ? section.get('boxes')
        : defaultSection.boxes
    ;

    const updateBoxesCount = (count) => {
        if (count < 0) {
            return;
        }

        const valueMerged = value.setIn(['shelves', shelfKey, 'sections', sectionIndex, 'boxes'], count);
        save(valueMerged);
    };

    const increaseBoxesCount = () => updateBoxesCount(boxesCount + 1);
    const decreaseBoxesCount = () => updateBoxesCount(boxesCount - 1);

    const deleteSection = () => {
        // @ts-ignore
        const updatedSections = value.getIn(['shelves', shelfKey, 'sections']).delete(sectionIndex);
        const valueMerged = value.setIn(['shelves', shelfKey, 'sections'], updatedSections);
        save(valueMerged);
    };

    const [isCollapsed, setCollapsed] = useState<boolean>(true);
    const [isShownDeleteDialog, setShownDeleteDialog] = useState<boolean>(false);
    const showDeleteDialog = () => setShownDeleteDialog(true);
    const hideDeleteDialog = () => setShownDeleteDialog(false);
    const processDeleteSection = () => {
        deleteSection();
        hideDeleteDialog();
    };
    const expand = () => setCollapsed(false);
    const collapse = () => setCollapsed(true);
    const isReadOnly = isStateReadOnly(state);

    return (
        <div
            className={classNames({
                'shelves-and-cells-body-manual-shelf': true,
                'expanded': !isCollapsed,
            })}
        >
            <div className="shelves-and-cells-body-manual-shelf-settings">
                <ToggleButton isActive={!isCollapsed} onActivate={expand} onDeactivate={collapse}>
                    {isCollapsed && <CollapsedIcon />}
                    {!isCollapsed && <ExpandedIcon />}
                </ToggleButton>
                <div className="shelves-and-cells-body-manual-shelf-number">
                    {sectionIndex + 1}
                </div>
                <div className="shelves-and-cells-body-manual-shelf-height-icon">
                    <SectionWidthIcon />
                </div>
                <div className="shelves-and-cells-body-manual-shelf-height-value">
                    <LengthInput
                      showUnit={false}
                      onChange={(centimeter) => updateSectionWidth(centimeter, UNIT_CENTIMETER)}
                      state={state}
                      translator={translator}
                      unit={
                        // @ts-ignore
                        width.get('_unit')
                      }
                      value={
                        // @ts-ignore
                        width.get('_length')
                      }
                    />
                </div>
                <div className="shelves-and-cells-body-manual-shelf-thickness-icon">
                    <SectionThicknessIcon />
                </div>
                <div className="shelves-and-cells-body-manual-shelf-thickness-value">
                    <LengthInput
                      showUnit={false}
                      onChange={(centimeter) => updateSectionThickness(centimeter, UNIT_CENTIMETER)}
                      state={state}
                      translator={translator}
                      unit={
                        //@ts-ignore
                        thickness.get('_unit')
                      }
                      value={
                        //@ts-ignore
                        thickness.get('_length')
                      }
                    />
                </div>
                <div className="shelves-and-cells-body-manual-shelf-divider" />
                <div className="shelves-and-cells-body-manual-shelf-delete-icon">
                    <ToggleButton
                        isActive={isShownDeleteDialog}
                        isDisabled={isStateReadOnly(state)}
                        onActivate={showDeleteDialog}
                        onDeactivate={hideDeleteDialog}
                    >
                        <DeleteRowIcon />
                    </ToggleButton>
                </div>
            </div>
            {!isCollapsed && (
                <>
                    <div className="shelves-and-cells-body-manual-shelf-section-boxes">
                        <div className="shelves-and-cells-body-manual-shelf-section-boxes-title">
                            {translator.t('boxes')}
                        </div>
                        <div className="shelves-and-cells-body-manual-shelf-section-boxes-count">
                            <FormNumberInput
                                state={state}
                                value={boxesCount}
                                onChange={
                                    // @ts-ignore
                                    event => updateBoxesCount(event.target.value)
                                }
                                onValid={onValid}
                                style={{
                                    color: 'var(--color-sidebar-btn-default)',
                                    lineHeight: '100%',
                                    padding: '1px 1px 0',
                                    textAlign: 'center',
                                }}
                                updateImmediately={true}
                            />
                        </div>
                        <div className="shelves-and-cells-body-manual-shelf-section-boxes-buttons">
                            <ToggleButton
                                isActive={boxesCount > 0}
                                isDisabled={isReadOnly}
                                onActivate={() => {}}
                                onDeactivate={decreaseBoxesCount}
                            >
                                -
                            </ToggleButton>
                            <ToggleButton
                                isActive={true}
                                isDisabled={isReadOnly}
                                onActivate={increaseBoxesCount}
                                onDeactivate={increaseBoxesCount}
                            >
                                +
                            </ToggleButton>
                        </div>
                    </div>
                </>
            )}
            <Modal
                visible={isShownDeleteDialog}
                onOk={processDeleteSection}
                onCancel={hideDeleteDialog}
                title={translator.t('Delete cell and all Elements')}
                okText={translator.t('Delete')}
                cancelText={translator.t('Cancel')}
                okButtonProps={{danger: true}}
            />
        </div>
    );
};

export default ShelfSectionEditor;
