import * as React from 'react';
import {legacy_createStore} from "redux";
/* eslint-disable no-param-reassign */
import {Plugins as PlannerPlugins,} from './react-planner/src';
import ReactPlanner from "./ReactPlanner";
import rootReducer from "../../reducers";
import MyCatalog from "./catalog/mycatalog";
import defaultState from "./state";
import { useState, useEffect } from 'react';
import {CreatingWarehouseParams} from "../../stores/warehouseStore";
import WarehouseSchema from "../../models/Warehouses/WarehouseSchema";

const reduxStore = legacy_createStore(rootReducer, defaultState);


const Planner = (
    {
        createWarehouseSchema,
        creatingWarehouseParams,
        isLocalModeEnabled,
        saveWarehouseSchema,
        setCreatingWarehouseParams,
        warehouseSchema,
    }
) => {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const autosaveKey = 'react-planner_v0';

    const plugins = [
        // eslint-disable-next-line import/no-named-as-default-member
        PlannerPlugins.Keyboard(),
        // eslint-disable-next-line import/no-named-as-default-member
        PlannerPlugins.Autosave(autosaveKey),
        // eslint-disable-next-line import/no-named-as-default-member
        PlannerPlugins.ConsoleDebugger(),
    ];

    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <ReactPlanner
            autosaveKey={autosaveKey}
            isLocalModeEnabled={isLocalModeEnabled}
            creatingWarehouseParams={creatingWarehouseParams}
            setCreatingWarehouseParams={setCreatingWarehouseParams}
            catalog={MyCatalog}
            width={dimensions.width - 65}
            height={dimensions.height - 65}
            plugins={plugins}
            createWarehouseSchema={createWarehouseSchema}
            saveWarehouseSchema={saveWarehouseSchema}
            stateExtractor={
              (storeOrState) => {
                  return storeOrState['react-planner'];
              }
            }
            store={reduxStore}
            warehouseSchema={warehouseSchema}
        />
    );

}

export default Planner;
