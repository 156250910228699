import {UNIT_CENTIMETER, UNIT_METER, UNIT_MILLIMETER} from "../react-planner/src/constants";

const getPrecision = (unit: string): number => {
  switch (unit) {
    case UNIT_CENTIMETER:
      return 1;
    case UNIT_METER:
      return 2;
    case UNIT_MILLIMETER:
    default:
      return 0;
  }
};

export default getPrecision;
