import * as Three from 'three';
import React from 'react';
import Translator from "../../../react-planner/lib/translator/translator";
import defaultShelf from "./defaultShelf.json";
import getItemStrokeColor from "../../../utils/getItemStrokeColor";
import convert from "convert-units";
import {
    UNIT_CENTIMETER
} from "react-planner/lib/constants";

const translator = new Translator();

const material = new Three.MeshLambertMaterial({color: 0xf5f4f4});

const SHELF_RACK = 'shelf-rack';
const CANTILEVER_RACK = 'cantilever-rack';
const CELLULAR_RACK = 'cellular-rack';
const BOX_RACK = 'box-rack';
const SHELVING_RACK = 'shelving-rack';
const PALLET_RACK = 'pallet-rack';

const RACK_TYPES = {
    [SHELF_RACK]: 'Shelf Rack',
    [CANTILEVER_RACK]: 'Cantilever Rack',
    [CELLULAR_RACK]: 'Cellular Rack',
    [BOX_RACK]: 'Box Rack',
    [SHELVING_RACK]: 'Shelving Rack',
    [PALLET_RACK]: 'Pallet Rack',
};

export default {
    name: 'rack',
    prototype: 'items',

    info: {
        tag: ['structure'],
        title: translator.t('Rack'),
        description: translator.t('Rack'),
        image: require('./square_column.png')
    },

    properties: {
        altitude: {
            label: 'altitude',
            type: 'length-measure',
            defaultValue: {
                length: 0,
                unit: 'cm'
            }
        },
        height:{
            label: 'height',
            type: 'length-measure',
            defaultValue: {
                length: 300,
                unit: 'cm'
            }
        },
        width:{
            label: 'width',
            type: 'length-measure',
            defaultValue: {
                length: 50,
                unit: 'cm'
            }
        },
        depth:{
            label: 'depth',
            type: 'length-measure',
            defaultValue: {
                length: 50,
                unit: 'cm'
            }
        },
        rib:{
            label: 'rib',
            type: 'length-measure',
            defaultValue: {
                length: 5,
                unit: 'cm'
            }
        },
        type: {
            label: 'type',
            type: 'enum',
            defaultValue: RACK_TYPES[SHELF_RACK],
            values: RACK_TYPES,
        },
        config: {
            label: 'config',
            type: 'shelving-config',
            defaultValue: {
                manuallyEditingShelves: false,
                doubleSideRack: false,
                shelves: [
                    // defaultShelf,
                ],
                topology: {
                    section: undefined,
                    line: undefined,
                    rackName: undefined,
                },
                walls: {
                    bottom: false,
                    left: false,
                    right: false,
                    top: false,
                },
            },
        }
    },

    render2D: function (element, layer, scene) {

        let width = element.properties.get('width').get('length');
        let depth = element.properties.get('depth').get('length');
        const widthUnit = element.properties.get('width').get('unit');
        const depthUnit = element.properties.get('depth').get('unit');

        let angle = element.rotation + 90;

        let textRotation = 0;
        if (Math.sin(angle * Math.PI / 180) < 0) {
            textRotation = 180;
        }

        const color = getItemStrokeColor(
            element,
            {
                defaultColor: 'rgb(144, 155, 161)',
                colorIfSelected: 'rgb(47, 128, 237)',
            }
        );
        let outerLineStyle = {
            stroke: color,
            strokeWidth: '2px',
            fill: '#fff'
        };

        const paddingRelative = element.properties.get('rib')?.get('length') ?? 0;
        const widthCm = convert(width).from(widthUnit).to(UNIT_CENTIMETER);
        const depthCm = convert(depth).from(depthUnit).to(UNIT_CENTIMETER);
        const innerWidth = widthCm - paddingRelative * 2;
        const innerDepth = depthCm - paddingRelative * 2;
        const widthPadding = paddingRelative;
        const depthPadding = paddingRelative;

        const elementFilterId = `element-filter-${element.get('id')}`;

        let inner;

        const rackType = element.get('properties')?.get('type') ?? SHELF_RACK;

        if (rackType === SHELF_RACK) {
            inner = (
                <rect
                    key='inner'
                    x={widthPadding}
                    y={depthPadding}
                    width={innerWidth}
                    height={innerDepth}
                    style={{
                        ...outerLineStyle,
                        strokeDasharray: '4',
                    }}
                />
            );
        } else {
            const isDoubleSideRack = !!element.properties.getIn(['config', 'doubleSideRack']);
            const cellStepCm = 50;
            const cellDepth = isDoubleSideRack
                ? depthCm / 2 - paddingRelative * 2
                : innerDepth
            ;
            const oneSideCellCount = ((innerWidth - (innerWidth % cellStepCm)) / cellStepCm);
            const oneSideCellWidth = innerWidth / oneSideCellCount;

            let innerSeparator = null;
            if (isDoubleSideRack) {
                innerSeparator = (
                    <line
                        key="inner-side-separator"
                        x1={0}
                        x2={width}
                        y1={depth / 2}
                        y2={depth / 2}
                        style={{
                            ...outerLineStyle,
                            strokeWidth: '4px',
                        }}
                    />
                );
            }

            const bordersBetweenCells = [];
            let widthCounter = widthPadding;
            if (oneSideCellCount > 0) {
                for (let i = 1; i < oneSideCellCount; i++) {
                    widthCounter += oneSideCellWidth;
                    bordersBetweenCells.push(widthCounter);
                }
            }

            let innerCells = null;

            if (rackType === CANTILEVER_RACK) {
                const strokeWidthCm = 4;
                const cellLineStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm}px`,
                };
                if (isDoubleSideRack) {
                    innerCells = (
                        <>
                            <line
                                key="front-side-back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depth / 2 - depthPadding}
                                y2={depth / 2 - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depth / 2 + depthPadding}
                                y2={depth / 2 + depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 - depthPadding}
                                y2={depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 - depthPadding}
                                y2={depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 + depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 + depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            {bordersBetweenCells.map(
                                (x, i) => (
                                    <React.Fragment key={`cell-borders-${i}`}>
                                        <line
                                            key={`front-cell-border-${i}`}
                                            x1={x}
                                            x2={x}
                                            y1={depth / 2 - depthPadding}
                                            y2={depthPadding}
                                            style={cellLineStyle}
                                        />
                                        <line
                                            key={`back-cell-border-${i}`}
                                            x1={x}
                                            x2={x}
                                            y1={depth / 2 + depthPadding}
                                            y2={depth - depthPadding}
                                            style={cellLineStyle}
                                        />
                                    </React.Fragment>
                                )
                            )}
                        </>
                    );
                } else {
                    innerCells = (
                        <>
                            <line
                                key="back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depth - depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            {bordersBetweenCells.map(
                                (x, i) => (
                                    <line
                                        key={`cell-border-${i}`}
                                        x1={x}
                                        x2={x}
                                        y1={depthPadding}
                                        y2={depth - depthPadding}
                                        style={cellLineStyle}
                                    />
                                )
                            )}
                        </>
                    );
                }
            } else if (rackType === CELLULAR_RACK) {
                const strokeWidthCm = 4;
                const cellLineStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm}px`,
                };
                const cellContentStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm / 4}px`,
                    strokeDasharray: strokeWidthCm,
                };
                if (isDoubleSideRack) {
                    innerCells = (
                        <>
                            <line
                                key="front-side-back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depth / 2 - depthPadding}
                                y2={depth / 2 - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depth / 2 + depthPadding}
                                y2={depth / 2 + depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 - depthPadding * 2}
                                y2={depthPadding * 2}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 - depthPadding * 2}
                                y2={depthPadding * 2}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 + depthPadding * 2}
                                y2={depth - depthPadding * 2}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 + depthPadding * 2}
                                y2={depth - depthPadding * 2}
                                style={cellLineStyle}
                            />
                            {!bordersBetweenCells.length ? (
                                <React.Fragment key={`cells-inner`}>
                                    <polygon
                                        key={`back-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthCm - depthPadding * 2} ${widthCm - widthPadding * 2},${depthCm - depthPadding * 2} ${widthCm - widthPadding * 2},${depthCm / 2 + depthPadding * 2} ${widthPadding * 2},${depthCm / 2 + depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />
                                    <polygon
                                        key={`front-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthPadding * 2} ${widthCm - widthPadding * 2},${depthPadding * 2} ${widthCm - widthPadding * 2},${depthCm / 2 - depthPadding * 2} ${widthPadding * 2},${depthCm / 2 - depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />

                                </React.Fragment>
                            ) : (
                                <React.Fragment key={`cells-inner`}>
                                    <polygon
                                        key={`back-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthCm - depthPadding * 2} ${oneSideCellWidth},${depthCm - depthPadding * 2} ${oneSideCellWidth},${depthCm / 2 + depthPadding * 2} ${widthPadding * 2},${depthCm / 2 + depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />
                                    <polygon
                                        key={`front-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthPadding * 2} ${oneSideCellWidth},${depthPadding * 2} ${oneSideCellWidth},${depthCm / 2 - depthPadding * 2} ${widthPadding * 2},${depthCm / 2 - depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />
                                    {bordersBetweenCells.map(
                                        (x, i) => (
                                            <React.Fragment key={`cell-borders-${i}`}>
                                                <line
                                                    key={`front-cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthCm / 2 - depthPadding * 2}
                                                    y2={depthPadding * 2}
                                                    style={cellLineStyle}
                                                />
                                                <line
                                                    key={`back-cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthCm / 2 + depthPadding * 2}
                                                    y2={depthCm - depthPadding * 2}
                                                    style={cellLineStyle}
                                                />
                                                <polygon
                                                    key={`back-cell-inner-${i}`}
                                                    points={`${x + widthPadding},${depthCm - depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthCm - depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthCm / 2 + depthPadding * 2} ${x + widthPadding},${depthCm / 2 + depthPadding * 2}`}
                                                    style={cellContentStyle}
                                                />
                                                <polygon
                                                    key={`front-cell-inner-${i}`}
                                                    points={`${x + widthPadding},${depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthCm / 2 - depthPadding * 2} ${x + widthPadding},${depthCm / 2 - depthPadding * 2}`}
                                                    style={cellContentStyle}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </>
                    );
                } else {
                    innerCells = (
                        <>
                            <line
                                key="back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depthCm - depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depthPadding * 2}
                                y2={depthCm - depthPadding * 2}
                                style={cellLineStyle}
                            />
                            <line
                                key="right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depthPadding * 2}
                                y2={depthCm - depthPadding * 2}
                                style={cellLineStyle}
                            />
                            {!bordersBetweenCells.length ? (
                                <polygon
                                    key={`cell-inner-left`}
                                    points={`${widthPadding * 2},${depthCm - depthPadding * 2} ${widthCm - widthPadding * 2},${depthCm - depthPadding * 2} ${widthCm - widthPadding * 2},${depthPadding * 2} ${widthPadding * 2},${depthPadding * 2}`}
                                    style={cellContentStyle}
                                />
                            ) : (
                                <React.Fragment key={`cells-inner`}>
                                    <polygon
                                        key={`back-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthCm - depthPadding * 2} ${oneSideCellWidth},${depthCm - depthPadding * 2} ${oneSideCellWidth},${depthPadding * 2} ${widthPadding * 2},${depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />
                                    {bordersBetweenCells.map(
                                        (x, i) => (
                                            <React.Fragment key={`cell-borders-${i}`}>
                                                <line
                                                    key={`cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthPadding * 2}
                                                    y2={depthCm - depthPadding * 2}
                                                    style={cellLineStyle}
                                                />
                                                <polygon
                                                    key={`cell-inner-${i}`}
                                                    points={`${x + widthPadding},${depthCm - depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthCm - depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthPadding * 2} ${x + widthPadding},${depthPadding * 2}`}
                                                    style={cellContentStyle}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </>
                    );
                }
            } else if (rackType === BOX_RACK) {

                const strokeWidthCm = 4;
                const cellLineStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm}px`,
                };
                const cellContentStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm / 2}px`,
                };
                if (isDoubleSideRack) {
                    innerCells = (
                        <>
                            <line
                                key="front-side-back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depth / 2 - depthPadding}
                                y2={depth / 2 - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depth / 2 + depthPadding}
                                y2={depth / 2 + depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 - depthPadding}
                                y2={depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 - depthPadding}
                                y2={depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 + depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 + depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            {!bordersBetweenCells.length ? (
                                <React.Fragment key={`cells-inner`}>
                                    <polygon
                                        key={`back-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthCm - depthPadding * 2} ${widthCm - widthPadding * 2},${depthCm - depthPadding * 2} ${widthCm - widthPadding * 2},${depthCm / 2 + depthPadding * 2} ${widthPadding * 2},${depthCm / 2 + depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />
                                    <polygon
                                        key={`front-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthPadding * 2} ${widthCm - widthPadding * 2},${depthPadding * 2} ${widthCm - widthPadding * 2},${depthCm / 2 - depthPadding * 2} ${widthPadding * 2},${depthCm / 2 - depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />

                                </React.Fragment>
                            ) : (
                                <React.Fragment key={`cells-inner`}>
                                    <polygon
                                        key={`back-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthCm - depthPadding * 2} ${oneSideCellWidth},${depthCm - depthPadding * 2} ${oneSideCellWidth},${depthCm / 2 + depthPadding * 2} ${widthPadding * 2},${depthCm / 2 + depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />
                                    <polygon
                                        key={`front-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthPadding * 2} ${oneSideCellWidth},${depthPadding * 2} ${oneSideCellWidth},${depthCm / 2 - depthPadding * 2} ${widthPadding * 2},${depthCm / 2 - depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />
                                    {bordersBetweenCells.map(
                                        (x, i) => (
                                            <React.Fragment key={`cell-borders-${i}`}>
                                                <polygon
                                                    key={`back-cell-inner-${i}`}
                                                    points={`${x + widthPadding},${depthCm - depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthCm - depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthCm / 2 + depthPadding * 2} ${x + widthPadding},${depthCm / 2 + depthPadding * 2}`}
                                                    style={cellContentStyle}
                                                />
                                                <polygon
                                                    key={`front-cell-inner-${i}`}
                                                    points={`${x + widthPadding},${depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthCm / 2 - depthPadding * 2} ${x + widthPadding},${depthCm / 2 - depthPadding * 2}`}
                                                    style={cellContentStyle}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </>
                    );
                } else {
                    innerCells = (
                        <>
                            <line
                                key="back"
                                x1={widthPadding - strokeWidthCm / 2}
                                x2={widthCm - depthPadding + strokeWidthCm / 2}
                                y1={depthCm - depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            {!bordersBetweenCells.length ? (
                                <polygon
                                    key={`cell-inner-left`}
                                    points={`${widthPadding * 2},${depthCm - depthPadding * 2} ${widthCm - widthPadding * 2},${depthCm - depthPadding * 2} ${widthCm - widthPadding * 2},${depthPadding * 2} ${widthPadding * 2},${depthPadding * 2}`}
                                    style={cellContentStyle}
                                />
                            ) : (
                                <React.Fragment key={`cells-inner`}>
                                    <polygon
                                        key={`back-cell-inner-left`}
                                        points={`${widthPadding * 2},${depthCm - depthPadding * 2} ${oneSideCellWidth},${depthCm - depthPadding * 2} ${oneSideCellWidth},${depthPadding * 2} ${widthPadding * 2},${depthPadding * 2}`}
                                        style={cellContentStyle}
                                    />
                                    {bordersBetweenCells.map(
                                        (x, i) => (
                                            <React.Fragment key={`cell-borders-${i}`}>
                                                <polygon
                                                    key={`cell-inner-${i}`}
                                                    points={`${x + widthPadding},${depthCm - depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthCm - depthPadding * 2} ${x + oneSideCellWidth - widthPadding},${depthPadding * 2} ${x + widthPadding},${depthPadding * 2}`}
                                                    style={cellContentStyle}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </>
                    );
                }
            } else if (rackType === SHELVING_RACK) {
                const strokeWidthCm = 4;
                const cellLineStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm / 2}px`,
                };
                const cellContentStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm}px`,
                };
                if (isDoubleSideRack) {
                    innerCells = (
                        <>
                            <line
                                key="front-side-back"
                                x1={widthPadding - strokeWidthCm / 4}
                                x2={widthCm - depthPadding + strokeWidthCm / 4}
                                y1={depth / 2 - depthPadding}
                                y2={depth / 2 - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-back"
                                x1={widthPadding - strokeWidthCm / 4}
                                x2={widthCm - depthPadding + strokeWidthCm / 4}
                                y1={depth / 2 + depthPadding}
                                y2={depth / 2 + depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 - depthPadding}
                                y2={depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 - depthPadding}
                                y2={depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 + depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 + depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            {!bordersBetweenCells.length ? (
                                <React.Fragment key={`cells-inner`}>
                                    <line
                                        key={`back-cells-inner-left`}
                                        x1={widthPadding * 2}
                                        x2={widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cells-inner-right`}
                                        x1={widthCm - widthPadding * 2}
                                        x2={widthCm - widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-left`}
                                        x1={widthPadding * 2}
                                        x2={widthPadding * 2}
                                        y1={depthPadding * 2}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-right`}
                                        x1={widthCm - widthPadding * 2}
                                        x2={widthCm - widthPadding * 2}
                                        y1={depthPadding * 2}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />

                                </React.Fragment>
                            ) : (
                                <React.Fragment key={`cells-inner`}>
                                    <line
                                        key={`back-cells-inner-left-left`}
                                        x1={widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        x2={widthPadding * 2}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cells-inner-left-right`}
                                        x1={oneSideCellWidth}
                                        y1={depthCm - depthPadding * 2}
                                        x2={oneSideCellWidth}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-left-left`}
                                        x1={widthPadding * 2}
                                        y1={depthPadding * 2}
                                        x2={widthPadding * 2}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-left-right`}
                                        x1={oneSideCellWidth}
                                        y1={depthPadding * 2}
                                        x2={oneSideCellWidth}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    {bordersBetweenCells.map(
                                        (x, i) => (
                                            <React.Fragment key={`cell-borders-${i}`}>
                                                <line
                                                    key={`front-cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthCm / 2 - depthPadding * 2}
                                                    y2={depthPadding * 2}
                                                    style={cellLineStyle}
                                                />
                                                <line
                                                    key={`back-cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthCm / 2 + depthPadding * 2}
                                                    y2={depthCm - depthPadding * 2}
                                                    style={cellLineStyle}
                                                />
                                                <line
                                                    key={`back-cell-inner-left-${i}`}
                                                    x1={x + widthPadding}
                                                    x2={x + widthPadding}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthCm / 2 + depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`back-cell-inner-right-${i}`}
                                                    x1={x + oneSideCellWidth - widthPadding}
                                                    x2={x + oneSideCellWidth - widthPadding}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthCm / 2 + depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`front-cell-inner-left-${i}`}
                                                    x1={x + widthPadding}
                                                    x2={x + widthPadding}
                                                    y1={depthPadding * 2}
                                                    y2={depthCm / 2 - depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`front-cell-inner-right-${i}`}
                                                    x1={x + oneSideCellWidth - widthPadding}
                                                    x2={x + oneSideCellWidth - widthPadding}
                                                    y1={depthPadding * 2}
                                                    y2={depthCm / 2 - depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </>
                    );
                } else {
                    innerCells = (
                        <>
                            <line
                                key="back"
                                x1={widthPadding - strokeWidthCm / 4}
                                x2={widthCm - depthPadding + strokeWidthCm / 4}
                                y1={depthCm - depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            {!bordersBetweenCells.length ? (
                                <React.Fragment key="cells-inner">
                                    <line
                                        key={`cell-inner-left-left`}
                                        x1={widthPadding * 2}
                                        x2={widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`cell-inner-left-right`}
                                        x1={widthCm - widthPadding * 2}
                                        x2={widthCm - widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment key={`cells-inner`}>
                                    <line
                                        key={`back-cell-inner-left-left`}
                                        x1={widthPadding * 2}
                                        x2={widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cell-inner-left-right`}
                                        x1={oneSideCellWidth}
                                        x2={oneSideCellWidth}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    {bordersBetweenCells.map(
                                        (x, i) => (
                                            <React.Fragment key={`cell-borders-${i}`}>
                                                <line
                                                    key={`cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthPadding * 2}
                                                    y2={depthCm - depthPadding * 2}
                                                    style={cellLineStyle}
                                                />
                                                <line
                                                    key={`cell-inner-left-${i}`}
                                                    x1={x + widthPadding}
                                                    x2={x + widthPadding}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`cell-inner-right-${i}`}
                                                    x1={x + oneSideCellWidth - widthPadding}
                                                    x2={x + oneSideCellWidth - widthPadding}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </>
                    );
                }
            } else if (rackType === PALLET_RACK) {
                const strokeWidthCm = 4;
                const cellLineStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm / 2}px`,
                };
                const cellContentStyle = {
                    ...outerLineStyle,
                    strokeWidth: `${strokeWidthCm}px`,
                };
                if (isDoubleSideRack) {
                    innerCells = (
                        <>
                            <line
                                key="front-side-back"
                                x1={widthPadding - strokeWidthCm / 4}
                                x2={widthCm - depthPadding + strokeWidthCm / 4}
                                y1={depth / 2 - depthPadding}
                                y2={depth / 2 - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-back"
                                x1={widthPadding - strokeWidthCm / 4}
                                x2={widthCm - depthPadding + strokeWidthCm / 4}
                                y1={depth / 2 + depthPadding}
                                y2={depth / 2 + depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 - depthPadding}
                                y2={depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="front-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 - depthPadding}
                                y2={depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depth / 2 + depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="back-side-right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depth / 2 + depthPadding}
                                y2={depth - depthPadding}
                                style={cellLineStyle}
                            />
                            {!bordersBetweenCells.length ? (
                                <React.Fragment key={`cells-inner`}>
                                    <line
                                        key={`back-cells-inner-left`}
                                        x1={widthPadding * 2}
                                        x2={widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cells-inner-connector`}
                                        x1={widthPadding * 2 - strokeWidthCm / 2}
                                        x2={widthCm - widthPadding * 2 + strokeWidthCm / 2}
                                        y1={depthCm / 2 + depthPadding * 2}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cells-inner-right`}
                                        x1={widthCm - widthPadding * 2}
                                        x2={widthCm - widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-left`}
                                        x1={widthPadding * 2}
                                        x2={widthPadding * 2}
                                        y1={depthPadding * 2}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-connector`}
                                        x1={widthPadding * 2 - strokeWidthCm / 2}
                                        x2={widthCm - widthPadding * 2 + strokeWidthCm / 2}
                                        y1={depthCm / 2 - depthPadding * 2}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-right`}
                                        x1={widthCm - widthPadding * 2}
                                        x2={widthCm - widthPadding * 2}
                                        y1={depthPadding * 2}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />

                                </React.Fragment>
                            ) : (
                                <React.Fragment key={`cells-inner`}>
                                    <line
                                        key={`back-cells-inner-left-left`}
                                        x1={widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        x2={widthPadding * 2}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cells-inner-left-connector`}
                                        x1={widthPadding * 2 - strokeWidthCm / 2}
                                        y1={depthCm / 2 + depthPadding * 2}
                                        x2={oneSideCellWidth + strokeWidthCm / 2}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cells-inner-left-right`}
                                        x1={oneSideCellWidth}
                                        y1={depthCm - depthPadding * 2}
                                        x2={oneSideCellWidth}
                                        y2={depthCm / 2 + depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-left-left`}
                                        x1={widthPadding * 2}
                                        y1={depthPadding * 2}
                                        x2={widthPadding * 2}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-left-connector`}
                                        x1={widthPadding * 2 - strokeWidthCm / 2}
                                        y1={depthCm / 2 - depthPadding * 2}
                                        x2={oneSideCellWidth + strokeWidthCm / 2}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`front-cells-inner-left-right`}
                                        x1={oneSideCellWidth}
                                        y1={depthPadding * 2}
                                        x2={oneSideCellWidth}
                                        y2={depthCm / 2 - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    {bordersBetweenCells.map(
                                        (x, i) => (
                                            <React.Fragment key={`cell-borders-${i}`}>
                                                <line
                                                    key={`front-cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthCm / 2 - depthPadding * 2 + strokeWidthCm / 2}
                                                    y2={depthPadding * 2}
                                                    style={cellLineStyle}
                                                />
                                                <line
                                                    key={`back-cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthCm / 2 + depthPadding * 2 - strokeWidthCm / 2}
                                                    y2={depthCm - depthPadding * 2}
                                                    style={cellLineStyle}
                                                />
                                                <line
                                                    key={`back-cell-inner-left-${i}`}
                                                    x1={x + widthPadding}
                                                    x2={x + widthPadding}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthCm / 2 + depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`back-cell-inner-connector-${i}`}
                                                    x1={x + widthPadding - strokeWidthCm / 2}
                                                    x2={x + oneSideCellWidth - widthPadding + strokeWidthCm / 2}
                                                    y1={depthCm / 2 + depthPadding * 2}
                                                    y2={depthCm / 2 + depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`back-cell-inner-right-${i}`}
                                                    x1={x + oneSideCellWidth - widthPadding}
                                                    x2={x + oneSideCellWidth - widthPadding}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthCm / 2 + depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`front-cell-inner-left-${i}`}
                                                    x1={x + widthPadding}
                                                    x2={x + widthPadding}
                                                    y1={depthPadding * 2}
                                                    y2={depthCm / 2 - depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`front-cell-inner-connector-${i}`}
                                                    x1={x + widthPadding - strokeWidthCm / 2}
                                                    x2={x + oneSideCellWidth - widthPadding + strokeWidthCm / 2}
                                                    y1={depthCm / 2 - depthPadding * 2}
                                                    y2={depthCm / 2 - depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`front-cell-inner-right-${i}`}
                                                    x1={x + oneSideCellWidth - widthPadding}
                                                    x2={x + oneSideCellWidth - widthPadding}
                                                    y1={depthPadding * 2}
                                                    y2={depthCm / 2 - depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </>
                    );
                } else {
                    innerCells = (
                        <>
                            <line
                                key="back"
                                x1={widthPadding - strokeWidthCm / 4}
                                x2={widthCm - depthPadding + strokeWidthCm / 4}
                                y1={depthCm - depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="left"
                                x1={widthPadding}
                                x2={widthPadding}
                                y1={depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            <line
                                key="right"
                                x1={widthCm - widthPadding}
                                x2={widthCm - widthPadding}
                                y1={depthPadding}
                                y2={depthCm - depthPadding}
                                style={cellLineStyle}
                            />
                            {!bordersBetweenCells.length ? (
                                <React.Fragment key="cells-inner">
                                    <line
                                        key={`cell-inner-left-left`}
                                        x1={widthPadding * 2}
                                        x2={widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`cell-inner-left-connector`}
                                        x1={widthPadding * 2 - strokeWidthCm / 2}
                                        x2={widthCm - widthPadding * 2 + strokeWidthCm / 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthCm - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`cell-inner-left-right`}
                                        x1={widthCm - widthPadding * 2}
                                        x2={widthCm - widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment key={`cells-inner`}>
                                    <line
                                        key={`back-cell-inner-left-left`}
                                        x1={widthPadding * 2}
                                        x2={widthPadding * 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cell-inner-left-connector`}
                                        x1={widthPadding * 2 - strokeWidthCm / 2}
                                        x2={oneSideCellWidth + strokeWidthCm / 2}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthCm - depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    <line
                                        key={`back-cell-inner-left-right`}
                                        x1={oneSideCellWidth}
                                        x2={oneSideCellWidth}
                                        y1={depthCm - depthPadding * 2}
                                        y2={depthPadding * 2}
                                        style={cellContentStyle}
                                    />
                                    {bordersBetweenCells.map(
                                        (x, i) => (
                                            <React.Fragment key={`cell-borders-${i}`}>
                                                <line
                                                    key={`cell-border-${i}`}
                                                    x1={x}
                                                    x2={x}
                                                    y1={depthPadding * 2}
                                                    y2={depthCm - depthPadding * 2 + strokeWidthCm / 2}
                                                    style={cellLineStyle}
                                                />
                                                <line
                                                    key={`cell-inner-left-${i}`}
                                                    x1={x + widthPadding}
                                                    x2={x + widthPadding}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`cell-inner-connector-${i}`}
                                                    x1={x + widthPadding - strokeWidthCm / 2}
                                                    x2={x + oneSideCellWidth - widthPadding + strokeWidthCm / 2}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthCm - depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                                <line
                                                    key={`cell-inner-right-${i}`}
                                                    x1={x + oneSideCellWidth - widthPadding}
                                                    x2={x + oneSideCellWidth - widthPadding}
                                                    y1={depthCm - depthPadding * 2}
                                                    y2={depthPadding * 2}
                                                    style={cellContentStyle}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </>
                    );
                }
            }

            inner = (
                <>
                    {innerSeparator}
                    {innerCells}
                </>
            );
        }



        return (
            <g transform={`translate(${-width / 2},${-depth / 2})`} viewBox={`0 0 ${width} ${depth}`}>
                <defs>
                    <filter
                        x="0"
                        y="0"
                        width="1"
                        height="1"
                        id={elementFilterId}
                    >
                        <feFlood floodColor={color} result="bg" />
                        <feMerge>
                            <feMergeNode in="bg"/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                </defs>
                <rect
                    key='outer'
                    x='0'
                    y='0'
                    width={width}
                    height={depth}
                    style={outerLineStyle}
                />
                {inner}
                <text
                    id={`element-${element.get('id')}-text`}
                    filter={`url(#${elementFilterId})`}
                    key='text'
                    x='0'
                    y='0'
                    fill="#fff"
                    transform={`translate(${width / 2}, ${depth / 2}) scale(1,-1) rotate(${textRotation})`}
                    style={{
                        textAnchor: 'middle',
                        fontSize: '11px'
                    }}
                >
                    {element.name}
                </text>
            </g>
        )
    },


    render3D: function (element, layer, scene) {
        let width = element.properties.get('width').get('length');
        let depth = 50;
        let height = 160;
        let altitude = element.properties.get('altitude').get('length');
    
        // Создаем материал с текстурой дерева
        const textureLoader = new Three.TextureLoader();
        const woodMaterial = new Three.MeshPhongMaterial({
            map: textureLoader.load(require('./wood.jpg'))
        });
    
        // Создаем основной объект стеллажа
        let bookcase = new Three.Object3D();
    
        // Задняя стенка
        let backGeometry = new Three.BoxGeometry(width, height, 0.03);
        let backPanel = new Three.Mesh(backGeometry, woodMaterial);
        backPanel.position.set(0, height / 2, -depth / 2 + 0.015);
        bookcase.add(backPanel);
    
        // Боковые стенки
        let sideGeometry = new Three.BoxGeometry(0.03, height, depth);
        let side1 = new Three.Mesh(sideGeometry, woodMaterial);
        side1.position.set(-width / 2 + 0.015, height / 2, 0);
        bookcase.add(side1);
    
        let side2 = new Three.Mesh(sideGeometry, woodMaterial);
        side2.position.set(width / 2 - 0.015, height / 2, 0);
        bookcase.add(side2);
    
        // Верхняя и нижняя панели
        let topBottomGeometry = new Three.BoxGeometry(width, 0.03, depth);
        let topPanel = new Three.Mesh(topBottomGeometry, woodMaterial);
        topPanel.position.set(0, height - 0.015, 0);
        bookcase.add(topPanel);
    
        let bottomPanel = new Three.Mesh(topBottomGeometry, woodMaterial);
        bottomPanel.position.set(0, 0.015, 0);
        bookcase.add(bottomPanel);
    
        // Полки
        let shelfGeometry = new Three.BoxGeometry(width, 0.03, depth);
        let numShelves = 4;
        for (let i = 1; i <= numShelves; i++) {
            let shelf = new Three.Mesh(shelfGeometry, woodMaterial);
            // Полки располагаем равномерно по высоте стеллажа и по центру
            shelf.position.set(0, (height / (numShelves + 1)) * i, 0);
            bookcase.add(shelf);
        }
    
        // Позиционирование и масштабирование
        bookcase.position.y += altitude;
        bookcase.position.z += depth / 2;
    
        // Разворачиваем стеллаж на 180 градусов вокруг оси Y
        bookcase.rotation.y = Math.PI;  // Поворот на 180 градусов
    
        // Добавление рамки, если объект выбран
        if (element.selected) {
            let bbox = new Three.BoxHelper(bookcase, 0x99c3fb);
            bbox.material.linewidth = 5;
            bbox.renderOrder = 1000;
            bbox.material.depthTest = false;
            bookcase.add(bbox);
        }
    
        return Promise.resolve(bookcase);
    }
    
    
    
    
};
