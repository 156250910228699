import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tabs} from "antd";
import {List, Map} from "immutable";
import convert from "convert-units";
import FormNumberInput from '../../../style/form-number-input';
import FormTextInput from '../../../style/form-text-input';
import {INPUT_STYLE, ATTRIBUTE_TABLE_STYLE, COLORS} from "../../../../shared-style";
import defaultQrCode from "../../../../catalog/attributes/defaultQrCode.json";
import PropertyStyle from "../../../../catalog/properties/shared-property-style";
import {FormLabel, FormSelect} from "../../../style/export";
import {UNIT_CENTIMETER, UNITS_LENGTH} from "../../../../constants";
import {toFixedFloat} from "../../../../utils/math";
import QrcodesEditor from "./qrcodes-editor";
import LengthInput from "../../../../../../components/LengthInput/LengthInput";

const firstTdStyle = { width: '81px', color: COLORS.font, };
const buttonStyle = {backgroundColor: 'black',};

export default function ItemAttributesEditor({element, onUpdate, attributeFormData, state, ...rest}, {translator}) {
  let name = attributeFormData.has('name') ? attributeFormData.get('name') : element.name;
  let renderedX = attributeFormData.has('x') ? attributeFormData.get('x') : element.x;
  let renderedY = attributeFormData.has('y') ? attributeFormData.get('y') : element.y;
  let renderedR = attributeFormData.has('rotation') ? attributeFormData.get('rotation') : element.rotation;

  return (
    <table className="attributes-table">
      <tbody>
      <tr>
        <td colSpan={2}>
          {translator.t('Attributes')}
        </td>
      </tr>
      <tr>
        <td style={firstTdStyle}>{translator.t('Name')}</td>
        <td>
          <FormTextInput
            state={state}
            value={name}
            onChange={event => onUpdate('name', event.target.value)}
            style={INPUT_STYLE}
          />
        </td>
      </tr>
      <tr>
        <td style={firstTdStyle}>X</td>
        <td>
          <LengthInput
            onChange={(value) => onUpdate('x', value)}
            state={state}
            translator={translator}
            unit={UNIT_CENTIMETER}
            value={renderedX}
          />
        </td>
      </tr>
      <tr>
        <td style={firstTdStyle}>Y</td>
        <td>
          <LengthInput
            onChange={(value) => onUpdate('y', value)}
            state={state}
            translator={translator}
            unit={UNIT_CENTIMETER}
            value={renderedY}
          />
        </td>
      </tr>
      <tr>
        <td style={firstTdStyle}>{translator.t('Rotation')}</td>
        <td>
          <FormNumberInput
            value={renderedR}
            onChange={event => onUpdate('rotation', event.target.value)}
            style={INPUT_STYLE}
            state={state}
            precision={2}
            {...rest}
          />
        </td>
      </tr>
      </tbody>
    </table>
  );
}

ItemAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

ItemAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
