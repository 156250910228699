/* eslint-disable no-param-reassign */
import { Modal } from 'antd';
import axios, {AxiosRequestConfig} from 'axios';
import AppConsts from '../lib/appconst';
import { L } from '../lib/abpUtility';

// eslint-disable-next-line import/no-extraneous-dependencies,@typescript-eslint/no-var-requires
const qs = require('qs');

declare let abp: any;

const http = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

export const setAxiosRequestConfig = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (abp.auth.getToken()) {
    config.headers.common['Authorization'] = `Bearer ${abp.auth.getToken()}`;
  }

  config.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
  config.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();
  config.headers.common['Referrer-Policy'] = 'no-referrer';

  return config;
};

export const setAxiosErrorResponseConfig = (error: any): Promise<any> => {
  if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
    Modal.error({
      title: error.response.data.error.message,
      content: error.response.data.error.details,
    });
  } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
    Modal.error({
      title: L('LoginFailed'),
      content: error.response.data.error.message,
    });
  } else if (!error.response) {
    Modal.error({ content: L('UnknownError') });
  }

  setTimeout(() => {}, 1000);

  return Promise.reject(error);
};

http.interceptors.request.use(
  config => setAxiosRequestConfig(config),
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  response => response,
  error => setAxiosErrorResponseConfig(error)
);

export default http;
