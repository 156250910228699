// Inspired by AirBnB react-outside-click-handler
// https://github.com/airbnb/react-outside-click-handler
import React, { Component } from 'react';
import PropTypes from 'prop-types';

interface IOutsideClickHandlerProps {
  className?: string;
  children: React.ReactNode;
  onOutsideClick: Function;
}

export default class OutsideClickHandler extends Component<IOutsideClickHandlerProps> {
  protected childNode: React.ReactNode = null;
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([ PropTypes.node, PropTypes.array ]),
    onOutsideClick: PropTypes.func
  };

  static defaultProps = {
    onOutsideClick: () => {}
  };

  constructor(props) {
    super(props);

    this._setChildNodeRef = this._setChildNodeRef.bind(this);
    this._onMouseDown = this._onMouseDown.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._onMouseDown);
    document.removeEventListener('touchend', this._onMouseDown);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._onMouseDown);
    document.addEventListener('touchend', this._onMouseDown);
  }

  _onMouseDown(e) {
    const { onOutsideClick } = this.props;
    // @ts-ignore
    const isDescendent = this.childNode && this.childNode.contains(e.target);
    if (!isDescendent) onOutsideClick(e);
  }

  _setChildNodeRef(ref) {
    this.childNode = ref;
  }

  render() {
    const {
      className,
      children
    } = this.props;

    return(
      <div ref={ this._setChildNodeRef } className={ `${className ? className : ''}` }>
        { children }
      </div>
    );
  }
}
