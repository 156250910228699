import React from "react";

const ExpandedIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.9558 12.2891C14.2371 12.0078 14.2371 11.5703 13.9558 11.2891L10.6746 8.03906C10.3308 7.69531 9.76831 7.69531 9.39331 8.03906L6.14331 11.2891C6.01831 11.4141 5.92456 11.6016 5.92456 11.7891C5.92456 11.9766 5.98706 12.1328 6.14331 12.2891C6.42456 12.5703 6.86206 12.5703 7.14331 12.2891L10.0496 9.38281L12.9558 12.2891C13.2371 12.5703 13.7058 12.5703 13.9558 12.2891Z"
                className="fill"
            />
        </svg>
    );
};

export default ExpandedIcon;
