import React from 'react';
import {INPUT_STYLE} from "../../shared-style";
import isStateReadOnly from "../../../../utils/isStateReadOnly";

const BASE_STYLE = {
  ...INPUT_STYLE,
  display: "block",
  width: "100%",
  fontSize: "13px",
  outline: "none",
  height: "30px",
  padding: "6px 0 4px 6px",
  // WebkitAppearance: "none",
};

export default function FormSelect({children, style, state, disabled = false, ...rest}) {
  const isDisabled = disabled || isStateReadOnly(state);
  return <select className="form-select" type="text" disabled={isDisabled} style={{...BASE_STYLE, ...style}} {...rest}>
    {children}
  </select>;
}
