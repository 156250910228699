interface GetItemStrokeColorOptions {
  colorIfInvalid: string,
  colorIfSelected: string,
  defaultColor: string,
}

const getItemStrokeColor = (item, options?: GetItemStrokeColorOptions) => {
  if (!item.hasIn(['misc', 'areaId'])) {
    return options?.colorIfInvalid ?? '#f00';
  }
  if (item.selected) {
    return options?.colorIfSelected ?? '#0096fd';
  }
  return options?.defaultColor ?? '#000';
};

export default getItemStrokeColor;
