import {List} from "immutable";
import {State, Area, Hole, Item, Line, Vertex} from "../react-planner/src/models";
import {getSelectedLayer} from "./getSelectedLayer";

export const getSelectedAreaIds: (state: State) => string[] = (state) => getSelectedLayer(state)
  .getIn(['selected', 'areas']) as string[];

export const getSelectedHoleIds: (state: State) => string[] = (state) => getSelectedLayer(state)
  .getIn(['selected', 'areas']) as string[];

export const getSelectedItemIds: (state: State) => string[] = (state) => getSelectedLayer(state)
  .getIn(['selected', 'items']) as string[];

export const getSelectedLineIds: (state: State) => string[] = (state) => getSelectedLayer(state)
  .getIn(['selected', 'items']) as string[];

export const getSelectedVertexIds: (state: State) => string[] = (state) => getSelectedLayer(state)
  .getIn(['selected', 'items']) as string[];

export const getSelectedAreas: (state: State) => Area[] = (state) => getSelectedAreaIds(state)
  .map((id: string) => getSelectedLayer(state).getIn(['areas', id])) as Area[];

export const getSelectedHoles: (state: State) => Hole[] = (state) => getSelectedHoleIds(state)
  .map((id: string) => getSelectedLayer(state).getIn(['holes', id])) as Hole[];

export const getSelectedItems: (state: State) => List<Item> = (state) => getSelectedItemIds(state)
  .map((id: string) => getSelectedLayer(state).getIn(['items', id])) as unknown as List<Item>;

export const getSelectedLines: (state: State) => Line[] = (state) => getSelectedLineIds(state)
  .map((id: string) => getSelectedLayer(state).getIn(['lines', id])) as Line[];

export const getSelectedVertices: (state: State) => Vertex[] = (state) => getSelectedVertexIds(state)
  .map((id: string) => getSelectedLayer(state).getIn(['vertices', id])) as Vertex[];
