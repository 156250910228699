import React from "react";
import moment from "moment";
import WarehouseSchema from "../../../models/Warehouses/WarehouseSchema";
import WarehouseSchemaActiveIcon from "../../../components/Svg/WarehouseSchemaActiveIcon";
import WarehouseSchemaNonActiveIcon from "../../../components/Svg/WarehouseSchemaNonActiveIcon";

interface WarehouseSchemaButtonProps {
  schema: WarehouseSchema|null;
}

const WarehouseSchemaButton: React.FC<WarehouseSchemaButtonProps> = ({
  schema,
}) => {
  if (!schema) {
    return null;
  }

  return (
    <div className="warehouse-schema-button">
      <div className="warehouse-schema-button-icon">
        {schema.isActive && <WarehouseSchemaActiveIcon />}
        {!schema.isActive && <WarehouseSchemaNonActiveIcon />}
      </div>
      <div className="warehouse-schema-button-data">
        <div>
          {schema.formatDate()}
        </div>
        <div className={`warehouse-schema-button-data-is-active ${schema.isActive && 'active'}`}>
          {schema.isActive && <>Используется</>}
          {!schema.isActive && <>Используется</>}
        </div>
      </div>
    </div>
  );
};

export default WarehouseSchemaButton;
