import moment from "moment";
import {
  EditWarehouseRequestDto,
  Rack,
  Shelf,
  WarehouseDto,
  WarehouseSchemaDto,
  WarehouseTopology as WarehouseTopologyDto
} from "../../../api/swagger";
import Warehouse from "../Warehouse";
import WarehouseSchema from "../WarehouseSchema";
import WarehouseSchemaCollection from "../WarehouseSchemaCollection";
import WarehouseCollection from "../WarehouseCollection";
import WarehouseTopologyCellAddress from "../WarehouseTopologyCellAddress";
import WarehouseTopologyCellAddressCollection from "../WarehouseTopologyCellAddressCollection";
import WarehouseTopologyShelf from "../WarehouseTopologyShelf";
import WarehouseTopologyShelfCollection from "../WarehouseTopologyShelfCollection";
import WarehouseTopologyRack from "../WarehouseTopologyRack";
import WarehouseTopologyRackCollection from "../WarehouseTopologyRackCollection";
import WarehouseTopology from "../WarehouseTopology";
import WarehouseTopologyCollection from "../WarehouseTopologyCollection";
import WarehouseTopologyLine from "../WarehouseTopologyLine";
import WarehouseTopologySection from "../WarehouseTopologySection";
import WarehouseTopologyLineCollection from "../WarehouseTopologyLineCollection";
import WarehouseTopologySectionCollection from "../WarehouseTopologySectionCollection";

export type LineData = {name: string | undefined, racks: Rack[]};
export type SectionData = {name: string | undefined, lines: LineData[]};

export default class WarehouseFactory {
  createWarehouseSchema(dto: WarehouseSchemaDto): WarehouseSchema {
    return new WarehouseSchema(
      dto.id,
      dto.warehouseId,
      dto.versionNo,
      dto.versionName,
      dto.schema,
      dto.topology,
      dto.graph,
      dto.creationTime ? moment(dto.creationTime) : undefined,
      dto.isActive,
      dto.isDraft
    );
  }

  createEmptyWarehouseSchema(warehouse: Warehouse): WarehouseSchema {
    return new WarehouseSchema(
      undefined,
      warehouse.id,
      undefined,
      '',
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      true,
    );
  }

  createWareHouse(dto: WarehouseDto): Warehouse {
    return new Warehouse(
      dto.id,
      dto.externalId,
      dto.name,
      dto.type,
      dto.width,
      dto.height,
      dto.unit,
      new WarehouseSchemaCollection(
        dto.schemas?.length
          ? dto.schemas.map((schema: WarehouseSchemaDto) => this.createWarehouseSchema(schema))
          : []
      ),
      dto.hasTemperatureCondition,
      dto.minDegreesCelsius,
      dto.maxDegreesCelsius,
    )
  }

  createEditWarehouseRequestDto(warehouse: Warehouse): EditWarehouseRequestDto {
    return {
      warehouseId: warehouse.id,
      externalId: warehouse.externalId,
      name: warehouse.name,
      width: warehouse.width,
      height: warehouse.height,
      unit: warehouse.unit,
      type: warehouse.type,
      hasTemperatureCondition: warehouse.hasTemperatureCondition,
      minDegreesCelsius: warehouse.minDegreesCelsius,
      maxDegreesCelsius: warehouse.maxDegreesCelsius,
    };
  }

  createWarehouseCollection(dtos: WarehouseDto[]): WarehouseCollection {
    return new WarehouseCollection(
      dtos.map((dto: WarehouseDto) => this.createWareHouse(dto))
    );
  }

  createWarehouseTopologyCellAddress(cellAddress: string): WarehouseTopologyCellAddress {
    return new WarehouseTopologyCellAddress(cellAddress);
  }

  createWarehouseTopologyCellAddressCollection(
    cellAddresses: string[] | undefined
  ): WarehouseTopologyCellAddressCollection {
    if (!cellAddresses || !cellAddresses.length) {
      return new WarehouseTopologyCellAddressCollection([]);
    }

    return new WarehouseTopologyCellAddressCollection(
      cellAddresses.map((cellAddress: string) => this.createWarehouseTopologyCellAddress(cellAddress))
    );
  }

  createWarehouseTopologyShelf(
    data: Shelf,
    systemNamePrefix: string | undefined
  ): WarehouseTopologyShelf {
    const systemName = `${systemNamePrefix}-${data.name}`;
    return new WarehouseTopologyShelf(
      data.name,
      systemName,
      this.createWarehouseTopologyCellAddressCollection(data.cellAddresses)
    );
  }

  createWarehouseTopologyShelfCollection(
    values: Shelf[] | undefined,
    systemNamePrefix: string | undefined
  ): WarehouseTopologyShelfCollection {
    if (!values || !values.length) {
      return new WarehouseTopologyShelfCollection([]);
    }

    return new WarehouseTopologyShelfCollection(
      values.map((data: Shelf) => this.createWarehouseTopologyShelf(data, systemNamePrefix))
    );
  }

  createWarehouseTopologyRack(value: Rack, systemNamePrefix: string | undefined): WarehouseTopologyRack {
    const systemName =  `${systemNamePrefix}-${value.name}`;
    return new WarehouseTopologyRack(
      value.warehouseId,
      value.name,
      systemName,
      value.workingArea,
      value.section,
      value.line,
      this.createWarehouseTopologyShelfCollection(value.shelves, systemName)
    );
  }

  createWarehouseTopologyRackCollection(
    values: Rack[] | undefined,
    systemNamePrefix: string | undefined
  ): WarehouseTopologyRackCollection {
    if (!values || !values.length) {
      return new WarehouseTopologyRackCollection([]);
    }

    return new WarehouseTopologyRackCollection(
      values.map((rack: Rack) => this.createWarehouseTopologyRack(rack, systemNamePrefix))
    );
  }

  createWarehouseTopologyLine(lineData: LineData, systemNamePrefix: string | undefined): WarehouseTopologyLine {
    const systemName = `${systemNamePrefix}-${lineData.name}`;
    return new WarehouseTopologyLine(
      lineData.name,
      systemName,
      this.createWarehouseTopologyRackCollection(lineData.racks, systemName)
    );
  }

  createWarehouseTopologyLineCollection(
    lines: LineData[],
    systemNamePrefix: string | undefined
  ): WarehouseTopologyLineCollection {
    return new WarehouseTopologyLineCollection(
      lines.map(
        (line: LineData) => this.createWarehouseTopologyLine(line, systemNamePrefix)
      )
    );
  }

  createWarehouseTopologySection(sectionData: SectionData): WarehouseTopologySection {
    return new WarehouseTopologySection(
      sectionData.name,
      this.createWarehouseTopologyLineCollection(sectionData.lines, sectionData.name)
    );
  }

  createWarehouseTopologySectionCollection(sections: SectionData[]): WarehouseTopologySectionCollection {
    return new WarehouseTopologySectionCollection(
      sections.map(
        (section: SectionData) => this.createWarehouseTopologySection(section)
      )
    );
  }

  createWarehouseTopology(topology: WarehouseTopologyDto): WarehouseTopology {
    const {racks} = topology;
    const onlyUnique = (value, index, array) => {
      return array.indexOf(value) === index;
    }

    const sectionNumbers = racks?.map(
      (rack: Rack) => rack.section
    ).filter(onlyUnique);
    const sections: SectionData[] = [];
    sectionNumbers?.forEach(
      (section: string | undefined) => {
        const sectionRacks = racks?.filter(
          (rack: Rack) => rack.section === section
        );
        if (Array.isArray(sectionRacks)) {
          const lines: LineData[] = [];
          const lineNumbers = sectionRacks?.map(
            (rack: Rack) => rack.line
          ).filter(onlyUnique);

          lineNumbers.forEach(
            (line: string | undefined) => {
              if (typeof line !== 'undefined') {
                const lineRacks = sectionRacks.filter(
                  (rack: Rack) => rack.line === line
                );

                if (Array.isArray(lineRacks)) {
                  const lineData = {
                    "name": line,
                    "racks": lineRacks
                  };
                  lines.push(lineData);
                }
              }
            }
          );

          if (lines.length && typeof section !== undefined) {
            sections.push({
              name: section,
              lines,
            });
          }
        }
      }
    );
    return new WarehouseTopology(
      topology.externalId,
      topology.warehouseName,
      this.createWarehouseTopologyRackCollection(topology.racks, undefined),
      this.createWarehouseTopologySectionCollection(sections),
    );
  }

  createWarehouseTopologyCollection(topologies: WarehouseTopologyDto[] | undefined): WarehouseTopologyCollection {
    if (!topologies || !topologies.length) {
      return new WarehouseTopologyCollection([]);
    }

    return new WarehouseTopologyCollection(
      topologies.map((topology: WarehouseTopologyDto) => this.createWarehouseTopology(topology))
    );
  }
}