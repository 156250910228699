export default class WarehouseTopologyCellAddress {
  constructor(
    readonly value: string,
  ) {
  }

  isEqualTo(that: WarehouseTopologyCellAddress): boolean {
    return this.value === that.value;
  }
}
