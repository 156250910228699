import React, {useState} from "react";
import cn from "classnames";
import OutsideClickHandler from "../OutsideClickHandler/OutsideClickHandler";

interface HamburgerButtonProps {
  children: React.ReactNode;
  dropdownWidth?: number;
}

const HamburgerButton: React.FC<HamburgerButtonProps> = ({
  children,
  dropdownWidth,
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);

  return (
    <OutsideClickHandler onOutsideClick={() => {setOpen(false);}}>
      <div
        onClick={toggleOpen}
        className={cn(
          'button hamburger-button dropdown-button',
          {
            'open': isOpen,
          }
          )}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_645_960)">
            <mask id="mask0_645_960" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <path
                d="M3 12H21M3 6H21M3 18H21"
                className="stroke"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </mask>
            <g mask="url(#mask0_645_960)">
              <rect width="24" height="24" className="fill" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_645_960">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {isOpen && (
          <div className="children" style={{width: `${dropdownWidth}px`,}}>
            {children}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
};

HamburgerButton.defaultProps = {
  dropdownWidth: 300,
};

export default HamburgerButton;
