import WarehouseTopologyRackCollection from "./WarehouseTopologyRackCollection";
import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopologyLine {
  constructor(
    readonly name: string | undefined,
    readonly systemName: string | undefined,
    readonly racks: WarehouseTopologyRackCollection,
  ) {
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologyLine {
    return new WarehouseTopologyLine(
      this.name,
      this.systemName,
      this.racks.withoutCells(removingCells),
    );
  }
}
