import ApplicationInfoDto from './applicationInfoDto';
import TenantLoginInfoDto from './tenantLoginInfoDto';
import UserLoginInfoDto from './userLoginInfoDto';
import {AlertDto} from "../../../api/swagger";

export class GetCurrentLoginInformations {
  application!: ApplicationInfoDto;
  user!: UserLoginInfoDto;
  tenant!: TenantLoginInfoDto;
  alerts!: AlertDto[]
}
