import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {fitSelection} from "react-svg-pan-zoom";
import {Map} from "immutable";

import Translator from './react-planner/src/translator/translator';
import Catalog from './react-planner/src/catalog/catalog';
import actions from './react-planner/src/actions/export';
import {objectsMap} from './react-planner/src/utils/objects-utils';
import {
    ToolbarComponents,
    Content,
    SidebarComponents,
    FooterBarComponents
} from './react-planner/src/components/export';
import {VERSION} from './react-planner/src/version';
import './react-planner/src/styles/export';
import {loadProject, setReadOnly, closeProject} from "./react-planner/src/actions/project-actions";
import isAnythingSelected from "./utils/isAnythingSelected";
import checkIsNumber from "../../utils/checkIsNumber";
import getItemPolygon from "./utils/getItemPolygon";
import {Item} from "./react-planner/src/models";
import emptyJson from "../../../src/config/empty.json";
import convert from "convert-units";

const {Toolbar} = ToolbarComponents;
const {Sidebar} = SidebarComponents;
const {FooterBar} = FooterBarComponents;

const toolBarH = 82;
const sidebarW = 400;
const footerBarH= 20;
const rulerSize = 36;

const wrapperStyle = {
    display: 'flex',
    flexFlow: 'row nowrap'
};

class ReactPlanner extends Component {

    getChildContext() {
        return {
            ...objectsMap(actions, actionNamespace => this.props[actionNamespace]),
            translator: this.props.translator,
            catalog: this.props.catalog,
        }
    }

    componentWillUnmount() {
        let {store} = this.props;
        store.dispatch(closeProject());
    }

    componentWillMount() {
        // let {store} = this.context;
        let {
            autosaveKey,
            creatingWarehouseParams,
            setCreatingWarehouseParams,
            isLocalModeEnabled,
            projectActions,
            catalog,
            stateExtractor,
            state,
            plugins,
            store,
            warehouseSchema,
            createWarehouseSchema,
            saveWarehouseSchema,
            viewer2DActions,
            width,
            height
        } = this.props;
        plugins.forEach(plugin => plugin(store, stateExtractor));
        let data;
        if (!!warehouseSchema && !!warehouseSchema.schema) {
            data = warehouseSchema.schema;
            if (creatingWarehouseParams) {
                data = JSON.parse(data);
                data._unit = creatingWarehouseParams.unit;
                data.width = creatingWarehouseParams.width;
                data.height = creatingWarehouseParams.height;
                if (setCreatingWarehouseParams) {
                    setCreatingWarehouseParams(undefined);
                }
                if (!warehouseSchema?.id) {
                    console.log('no schema id');
                    console.log(warehouseSchema.warehouseId);
                }
                data = JSON.stringify(data);
            }
        } else if (isLocalModeEnabled) {
            data = localStorage.getItem(autosaveKey);
        } else {
            if (creatingWarehouseParams) {
                emptyJson._unit = creatingWarehouseParams.unit;
                emptyJson.width = creatingWarehouseParams.width;
                emptyJson.height = creatingWarehouseParams.height;
                if (setCreatingWarehouseParams) {
                    setCreatingWarehouseParams(undefined);
                }
                if (!warehouseSchema?.id) {
                    createWarehouseSchema(
                        {
                            warehouseId: warehouseSchema.warehouseId,
                            versionName: 'Первая версия',
                            isActive: false,
                            schema: JSON.stringify(data),
                        },
                        true
                    );
                }
            }
            data = JSON.stringify(emptyJson);
        }
        if (!!data) {
            let json = JSON.parse(data);
            store.dispatch(loadProject(json));
            let extractedState = stateExtractor(state);
            const sceneWidth = extractedState.getIn(['scene', 'width']);
            const sceneHeight = extractedState.getIn(['scene', 'height']);
            // depends on so many variables -- cannot move to an external file
            extractedState = extractedState.setIn(['viewer2D'], new Map({
                "a": 1,
                "c": 0,
                "e": sceneWidth / 2,
                "b": 0,
                "d": 1,
                "f": sceneHeight / 2,
                "version": 3,
                "mode": "idle",
                "focus": false,
                "pinchPointDistance": null,
                "prePinchMode": null,
                "SVGMinX": 0,
                "SVGMinY": 0,
                "scaleFactorMin": null,
                "scaleFactorMax": null,
                "startX": null,
                "startY": null,
                "endX": null,
                "endY": null,
                "miniatureOpen": true,
                "lastAction": null,
                "viewerWidth": width - rulerSize,
                "viewerHeight": height - rulerSize * 2,
                "SVGWidth": sceneWidth,
                "SVGHeight": sceneHeight,
            }));
            const selectedLayerName = json['selectedLayer'];
            if (selectedLayerName) {
                const selectedLayer = json['layers'][selectedLayerName];
                if (selectedLayer) {
                    let minX = json['width'];
                    let minY = json['height'];
                    let maxX = 0;
                    let maxY = 0;
                    const vertices = selectedLayer.vertices;
                    const items = selectedLayer.items;
                    let points = [
                        ...Object.keys(vertices).map(vertexId => ({x: vertices[vertexId].x, y: vertices[vertexId].y})),
                    ];
                    Object.keys(items).forEach(itemId => {
                        const item = new Item(items[itemId]);
                        const itemPoints = getItemPolygon(item);
                        !!itemPoints.length && itemPoints.forEach(([x, y]) => {
                            points.push({x, y});
                        });
                    });
                    points.filter(
                        (point) => checkIsNumber(point.x) && checkIsNumber(point.y)
                    ).forEach(
                        (point) => {
                            minX = Math.min(minX, point.x);
                            minY = Math.min(minY, point.y);
                            maxX = Math.max(maxX, point.x);
                            maxY = Math.max(maxY, point.y);
                        }
                    );
                    if (!points?.length) {
                        // just created schema
                        minX = 0;
                        minY = 0;
                        maxX = json['width'];
                        maxY = json['height'];
                    }
                    viewer2DActions.fitSelection(
                        extractedState,
                        minX,
                        -maxY + extractedState.getIn(['scene', 'height']),
                        maxX - minX,
                        maxY - minY
                    );
                }
            }
        }
        if (warehouseSchema?.isActive) {
            store.dispatch(setReadOnly(true));
        }

        projectActions.initCatalog(catalog);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        let {stateExtractor, state, projectActions, catalog} = nextProps;
        let plannerState = stateExtractor(state);
        let catalogReady = plannerState?.catalog?.ready;
        if (!catalogReady) {
            projectActions.initCatalog(catalog);
        }
        // console.log(plannerState.toJS());
    }

    render() {
        let {width, height, stateExtractor, state, store, ...props} = this.props;

        let extractedState = stateExtractor(state);
        const isSelected = isAnythingSelected(extractedState);

        let contentW = width - (isSelected ? sidebarW : 0);
        let contentH = height - footerBarH * 2;
        let sidebarH = height - footerBarH * 2;

        return (
            <div style={{width, position: 'relative', display: 'flex', flexFlow: 'column nowrap'}}>
                <Toolbar width={width} height={toolBarH} state={extractedState} store={store} {...props} />
                <div style={{...wrapperStyle}}>
                    <Content
                        width={contentW}
                        height={contentH}
                        store={store}
                        state={extractedState}
                        {...props}
                    />
                    {isSelected && <Sidebar width={sidebarW} height={sidebarH} state={extractedState} {...props} />}
                </div>
                <FooterBar width={width} height={footerBarH} state={extractedState} {...props} />
            </div>
        );
    }
}

ReactPlanner.propTypes = {
    translator: PropTypes.instanceOf(Translator),
    catalog: PropTypes.instanceOf(Catalog),
    allowProjectFileSupport: PropTypes.bool,
    plugins: PropTypes.arrayOf(PropTypes.func),
    autosaveKey: PropTypes.string,
    autosaveDelay: PropTypes.number,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    stateExtractor: PropTypes.func.isRequired,
    toolbarButtons: PropTypes.array,
    sidebarComponents: PropTypes.array,
    footerbarComponents: PropTypes.array,
    customContents: PropTypes.object,
    softwareSignature: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    store: PropTypes.object.isRequired,
    warehouseSchema: PropTypes.object,
};

ReactPlanner.childContextTypes = {
    ...objectsMap(actions, () => PropTypes.object),
    translator: PropTypes.object,
    catalog: PropTypes.object,
};

ReactPlanner.defaultProps = {
    translator: new Translator(),
    catalog: new Catalog(),
    plugins: [],
    allowProjectFileSupport: true,
    softwareSignature: `React-Planner ${VERSION}`,
    toolbarButtons: [],
    sidebarComponents: [],
    footerbarComponents: [],
    customContents: {},
};

//redux connect
function mapStateToProps(reduxState) {
    return {
        state: reduxState,
    }
}

function mapDispatchToProps(dispatch) {
    return objectsMap(actions, actionNamespace => bindActionCreators(actions[actionNamespace], dispatch));
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactPlanner);
