import React, {useState} from "react";
import {Modal, Button} from "antd";
import {browserDownload} from "../../scenes/Planner/react-planner/src/utils/browser";
import WarehouseSchema from "../../models/Warehouses/WarehouseSchema";

interface DownloadIconProps {
  schema: WarehouseSchema;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({
  schema,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const closeModal = () => {
    setErrorMessage('');
    setModalOpen(false);
  };

  const onClick = () => {
    try {
      if (schema.schema) {
        const json = JSON.parse(schema.schema);
        browserDownload(json);
      } else {
        throw new Error('Incorrect version format');
      }
    } catch (e) {
      setErrorMessage(e.message);
      setModalOpen(true);
    }
  };
  return (
    <>
      {isModalOpen && (
        <Modal
          footer={[
            <Button key="ok" type="primary" onClick={closeModal}>
              Ok
            </Button>
          ]}
          onCancel={closeModal}
          onOk={closeModal}
          visible={isModalOpen}
        >
          {errorMessage}
        </Modal>
      )}
      <svg onClick={onClick} className="action-icon" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.65003 13.775C8.50003 13.925 8.30003 14 8.10003 14C7.90003 14 7.70003 13.925 7.55003 13.775L6.37503 12.55C6.15003 12.325 6.17503 11.975 6.40003 11.75C6.62503 11.525 6.97503 11.55 7.20003 11.775L7.57503 12.15V9.55836C7.57503 9.25836 7.82503 8.98336 8.15003 8.98336C8.45003 8.98336 8.72503 9.23336 8.72503 9.55836V12.15L9.10003 11.775C9.20003 11.65 9.35003 11.6 9.50003 11.6C9.65003 11.6 9.77503 11.65 9.90003 11.75C10.125 11.975 10.125 12.325 9.92503 12.55L8.65003 13.775Z" fill="black"/>
        <path d="M14.3252 3.97333C13.6502 3.24833 12.7252 2.74833 11.7502 2.57333C11.2002 1.69833 10.4002 1.04833 9.4752 0.723328C9.0502 0.548328 8.55019 0.473328 8.0252 0.473328C5.6502 0.473328 3.7252 2.32333 3.5752 4.64833C1.8252 4.87333 0.450195 6.34833 0.450195 8.17333C0.450195 10.1483 2.0002 11.7233 4.0002 11.7233C4.3002 11.7233 4.5502 11.4733 4.5752 11.1733C4.5752 10.8733 4.3252 10.5983 4.0252 10.5983C2.6752 10.5983 1.6002 9.52333 1.6002 8.17333C1.6002 6.82333 2.6752 5.74833 4.0252 5.74833H4.1502C4.4502 5.74833 4.7252 5.49833 4.7252 5.17333V4.92333C4.7252 3.09833 6.2252 1.59833 8.0502 1.59833C8.4502 1.59833 8.8002 1.64833 9.1252 1.77333C9.9002 2.04833 10.5502 2.59833 10.9502 3.34833C11.0502 3.52333 11.2002 3.62333 11.3752 3.64833C12.2002 3.72333 12.9752 4.12333 13.5502 4.72333C14.1502 5.37333 14.5002 6.22333 14.5002 7.09833C14.5002 8.54833 13.5752 9.87333 12.2002 10.3733C11.9002 10.4733 11.7502 10.7983 11.8752 11.0983C11.9752 11.3983 12.3002 11.5483 12.6002 11.4233C14.4252 10.7733 15.6252 9.02333 15.6252 7.09833C15.5752 5.97333 15.1252 4.84833 14.3252 3.97333Z" fill="black"/>
      </svg>
    </>
  );
};

export default DownloadIcon;
