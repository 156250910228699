import Collection from "../../domain/collection";
import WarehouseSchema from "./WarehouseSchema";

export default class WarehouseSchemaCollection extends Collection<WarehouseSchema> {
  getDrafts(): WarehouseSchemaCollection {
    return new WarehouseSchemaCollection(
      this.getAll().filter(
        (schema) => schema.isDraft
      )
    );
  }

  getActive(): WarehouseSchema | null {
    const items = this.getAll();
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.isActive) {
        return item;
      }
    }
    return null;
  }

  getLatest(): WarehouseSchema|null {
    if (this.isEmpty()) {
      return null;
    }

    let latest: WarehouseSchema|null = null;

    this.getAll().forEach((schema) => {
      if (!latest || latest.creationTime?.isBefore(schema.creationTime)) {
        latest = schema;
      }
    });

    return latest;
  }

  sortByDate() {
    return new WarehouseSchemaCollection(
      this.getAll().sort(
        (firstSchema, secondSchema) => {
          if (firstSchema.creationTime?.isBefore(secondSchema.creationTime)) {
            return -1;
          } else if (firstSchema.creationTime?.isAfter(secondSchema.creationTime)) {
            return 1;
          }
          return 0;
        }
      )
    );
  }
}
