import React from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const LogoIcon = () => (
  <svg width="45" height="32" viewBox="0 0 45 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0243 0L36.7094 5.18345L44.0485 13.8569L36.3925 16.613V25.83L26.2148 29.494C26.2587 29.6165 26.2815 29.7426 26.2815 29.8714C26.2815 31.047 24.3755 32 22.0243 32C19.6731 32 17.7671 31.047 17.7671 29.8714C17.7671 29.7426 17.7899 29.6165 17.8338 29.494L7.6561 25.83V16.6131L0 13.8569L7.33907 5.18345L22.0243 0ZM24.9853 12.2722L29.2482 16.9226L40.4438 12.8922L36.0755 7.72962L35.6867 7.8851L24.9853 12.2722ZM22.0242 11.0575L33.4312 6.49471L22.0243 2.26993L10.6173 6.49474L22.0242 11.0575ZM8.36183 7.88515L19.0632 12.2721L14.8003 16.9226L3.60466 12.8922L7.97301 7.72962L8.36183 7.88515ZM20.96 27.8098C20.6141 27.8543 20.2852 27.92 19.9792 28.004L9.78472 24.334V17.3794L15.4121 19.4053L20.96 13.3531V27.8098ZM24.0694 28.004C23.7634 27.92 23.4345 27.8543 23.0886 27.8098V13.3532L28.6363 19.4053L34.2639 17.3794V24.334L24.0694 28.004Z"
      fill="black"
    />
  </svg>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DashboardIcon = ({ highlight = false }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: highlight ? 'blue' : 'black' }}>
    <rect x="22" y="2" width="6" height="8" transform="rotate(90 22 2)" stroke="#707070" strokeWidth="1.5" fill={"highlight ? 'blue' : 'none'"} strokeLinejoin="round" />
    <rect x="22" y="12" width="10" height="8" transform="rotate(90 22 12)" stroke="#707070" strokeWidth="1.5" fill={"highlight ? 'blue' : 'none'"} strokeLinejoin="round" />
    <rect width="6" height="8" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 10 22)" stroke="#707070" fill={"highlight ? 'blue' : 'none'"} strokeWidth="1.5" strokeLinejoin="round" />
    <rect width="10" height="8" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 10 12)" stroke="#707070" fill={"highlight ? 'blue' : 'none'"} strokeWidth="1.5" strokeLinejoin="round" />
  </svg>
);


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SettingsIcon =  ({ highlight = false }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: highlight ? 'blue' : 'black' }}>
    <path
      d="M14.392 2.8181C14.3085 2.48422 14.0085 2.25 13.6644 2.25H10.3356C9.9914 2.25 9.69142 2.48422 9.60795 2.8181L9.11235 4.80049C9.06309 4.99752 8.93604 5.16615 8.76023 5.26782L7.79171 5.82797C7.61556 5.92985 7.40558 5.95578 7.20995 5.89981L5.24425 5.3375C4.91337 5.24285 4.56053 5.38554 4.38845 5.68358L2.72404 8.56644C2.55196 8.86448 2.60481 9.24139 2.85222 9.48061L4.32205 10.9018C4.46819 11.0431 4.55071 11.2377 4.55071 11.441L4.55071 12.559C4.55071 12.7623 4.46819 12.9569 4.32205 13.0982L2.85222 14.5194C2.60481 14.7586 2.55196 15.1355 2.72404 15.4336L4.38845 18.3164C4.56053 18.6145 4.91337 18.7572 5.24425 18.6625L7.20115 18.1027C7.40196 18.0453 7.61762 18.0742 7.79622 18.1824L8.51881 18.6205C8.67091 18.7127 8.78528 18.8559 8.84153 19.0247L9.57903 21.2372C9.68111 21.5434 9.96772 21.75 10.2905 21.75H13.7094C14.0322 21.75 14.3188 21.5434 14.4209 21.2372L15.1576 19.027C15.2144 18.8568 15.3301 18.7127 15.484 18.6206L16.2205 18.1799C16.3988 18.0732 16.6133 18.0452 16.813 18.1027L18.7551 18.6614C19.0863 18.7567 19.4397 18.6141 19.612 18.3156L21.2781 15.4299C21.4492 15.1336 21.398 14.759 21.1536 14.5194L19.7036 13.0976C19.5597 12.9566 19.4787 12.7636 19.4787 12.5621V11.4379C19.4787 11.2364 19.5597 11.0434 19.7036 10.9024L21.1536 9.4806C21.398 9.24102 21.4492 8.86643 21.2781 8.57008L19.612 5.68437C19.4397 5.38596 19.0863 5.24334 18.7551 5.3386L16.8047 5.89973C16.6099 5.95577 16.4007 5.93058 16.2248 5.8299L15.2422 5.26757C15.0649 5.1661 14.9366 4.99671 14.8871 4.79853L14.392 2.8181Z"
      stroke="#707070"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 12C15.75 14.0711 14.071 15.75 12 15.75C9.9289 15.75 8.24997 14.0711 8.24997 12C8.24997 9.92893 9.9289 8.25 12 8.25C14.071 8.25 15.75 9.92893 15.75 12Z"
      stroke="#707070"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PlannerIcon =  () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2H8.20185L12.3698 4.77863L11.6302 5.88804L7.79815 3.33333H3.33333V20.6667H10V12.6667H7.33333V11.3333H14V12.6667H11.3333V20.6667H20.6667V12.6667H18V11.3333H20.6667V3.33333H15.3333V2H22V22H2V2Z"
      fill="#707070"
      stroke="#707070"
      strokeWidth="0.5"
    />
  </svg>
);

export const IosIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_645_3601)">
      <path
        d="M23.52 3.195C23.295 2.6025 22.755 1.77 21.99 1.1775C21.54 0.84 21.03 0.51 20.31 0.3C19.53 0.075 18.57 0 17.4 0H6.6C5.4225 0 4.47 0.075 3.6975 0.3075C2.9775 0.525 2.445 0.8475 2.01 1.185C1.245 1.77 0.705 2.61 0.4725 3.2025C0.00749995 4.395 0 5.745 0 6.6V17.4C0 18.255 0.00749995 19.605 0.4725 20.805C0.705 21.3975 1.245 22.23 2.01 22.8225C2.4525 23.16 2.9775 23.49 3.6975 23.7C4.47 23.925 5.4225 24 6.6 24H17.4C18.5775 24 19.53 23.925 20.3025 23.6925C21.0225 23.475 21.555 23.1525 21.99 22.815C22.755 22.23 23.295 21.39 23.5275 20.7975C24 19.605 24 18.2475 24 17.3925V6.5925C24 5.7375 23.9925 4.3875 23.5275 3.1875L23.52 3.195ZM4.755 16.5H3.8625V11.1675H4.755V16.5ZM4.3125 10.2675C4.0125 10.2675 3.7575 10.02 3.7575 9.7125C3.7575 9.405 4.005 9.1575 4.3125 9.1575C4.62 9.1575 4.875 9.405 4.875 9.7125C4.8675 10.02 4.62 10.2675 4.3125 10.2675ZM9.675 16.635C7.32 16.635 5.835 14.955 5.835 12.2775C5.835 9.6 7.32 7.9125 9.675 7.9125C12.03 7.9125 13.515 9.6 13.515 12.2775C13.515 14.955 12.03 16.635 9.675 16.635ZM17.445 16.635C15.675 16.635 14.43 15.66 14.3475 14.205H15.15C15.24 15.2175 16.1925 15.9075 17.52 15.9075C18.7875 15.9075 19.695 15.2175 19.695 14.265C19.695 13.4775 19.1475 13.005 17.895 12.69L16.845 12.42C15.2775 12.0225 14.58 11.3625 14.58 10.245C14.58 8.88 15.81 7.905 17.475 7.905C19.125 7.905 20.325 8.88 20.3775 10.2225H19.575C19.5 9.27 18.6525 8.6325 17.4525 8.6325C16.2825 8.6325 15.4125 9.285 15.4125 10.215C15.4125 10.9425 15.9375 11.37 17.1825 11.685L18.0975 11.9175C19.8 12.345 20.5125 12.9975 20.5125 14.175C20.5125 15.66 19.32 16.635 17.445 16.635ZM12.6975 12.2775C12.6975 14.49 11.5275 15.8925 9.675 15.8925C7.8225 15.8925 6.66 14.49 6.66 12.2775C6.66 10.0575 7.8225 8.655 9.675 8.655C11.5275 8.655 12.6975 10.0575 12.6975 12.2775Z"
        fill="#707070"
      />
    </g>
    <defs>
      <clipPath id="clip0_645_3601">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AndroidIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.55005 17.4375C5.55005 18.3 6.22505 19.0125 7.12505 19.0125H8.13755V22.2C8.13755 22.9875 8.77505 23.625 9.56255 23.625C10.35 23.625 10.9875 22.9875 10.9875 22.2V19.0125H12.9V22.2C12.9 22.9875 13.5375 23.625 14.325 23.625C15.1125 23.625 15.75 22.9875 15.75 22.2V19.0125H16.7625C17.625 19.0125 18.3375 18.3375 18.3375 17.4375V8.21249H5.55005V17.4375Z"
      fill="#707070"
    />
    <path
      d="M15.1876 2.55L16.1626 0.75C16.2001 0.6375 16.2001 0.525 16.1251 0.4875C16.0501 0.375 16.0126 0.375 16.0126 0.375C15.9751 0.375 15.8626 0.4125 15.8626 0.4875L14.8501 2.325C13.9876 1.95 13.0501 1.7625 12.1126 1.7625C11.1001 1.7625 10.2001 1.9875 9.37507 2.325L8.36257 0.4875C8.32507 0.375 8.21257 0.3375 8.10007 0.45C7.98757 0.4875 7.95007 0.6 8.06257 0.7125L9.07507 2.5125C6.97507 3.5625 5.70007 5.475 5.70007 7.6875H18.5251C18.4501 5.4375 17.0626 3.5625 15.1876 2.55ZM9.03757 5.3625C8.77507 5.3625 8.51257 5.1 8.51257 4.8375C8.51257 4.575 8.77507 4.3125 9.03757 4.3125C9.30007 4.3125 9.56257 4.575 9.56257 4.8375C9.56257 5.1 9.37507 5.3625 9.03757 5.3625ZM14.9626 5.3625C14.7001 5.3625 14.4376 5.1 14.4376 4.8375C14.4376 4.575 14.7001 4.3125 14.9626 4.3125C15.2251 4.3125 15.4876 4.575 15.4876 4.8375C15.4876 5.1 15.2626 5.3625 14.9626 5.3625Z"
      fill="#707070"
    />
    <path
      d="M3.60005 7.95C2.81255 7.95 2.17505 8.5875 2.17505 9.375V15.3375C2.17505 16.125 2.81255 16.7625 3.60005 16.7625C4.38755 16.7625 5.02505 16.125 5.02505 15.3375V9.375C5.06255 8.55 4.38755 7.95 3.60005 7.95Z"
      fill="#707070"
    />
    <path
      d="M20.4001 7.95C19.6126 7.95 18.9751 8.5875 18.9751 9.375V15.3375C18.9751 16.125 19.6126 16.7625 20.4001 16.7625C21.1876 16.7625 21.8251 16.125 21.8251 15.3375V9.375C21.8251 8.55 21.1876 7.95 20.4001 7.95Z"
      fill="#707070"
    />
  </svg>
);

export const HelpIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_645_3603)">
      <g clipPath="url(#clip1_645_3603)">
        <path
          d="M12 0.675003C5.73755 0.675003 0.675049 5.7375 0.675049 12C0.675049 18.2625 5.73755 23.3625 12 23.3625C18.2626 23.3625 23.3625 18.2625 23.3625 12C23.3625 5.7375 18.2626 0.675003 12 0.675003ZM12 21.675C6.67505 21.675 2.36255 17.325 2.36255 12C2.36255 6.675 6.67505 2.3625 12 2.3625C17.325 2.3625 21.675 6.7125 21.675 12.0375C21.675 17.325 17.325 21.675 12 21.675Z"
          fill="#707070"
        />
        <path
          d="M12.6751 4.5375C11.5876 4.35 10.4626 4.65 9.63757 5.3625C8.81257 6.075 8.32507 7.0875 8.32507 8.2125C8.32507 8.625 8.40007 9.0375 8.55007 9.45C8.70007 9.9 9.18757 10.125 9.63757 9.975C10.0876 9.825 10.3126 9.3375 10.1626 8.8875C10.0876 8.6625 10.0501 8.4375 10.0501 8.2125C10.0501 7.6125 10.3126 7.05 10.7626 6.675C11.2126 6.3 11.8126 6.1125 12.4501 6.225C13.2376 6.375 13.9126 7.05 14.0626 7.8375C14.2126 8.775 13.7251 9.7125 12.8626 10.0875C11.8876 10.5 11.2501 11.475 11.2501 12.6V14.925C11.2501 15.375 11.6251 15.7875 12.1126 15.7875C12.5626 15.7875 12.9751 15.4125 12.9751 14.925V12.6C12.9751 12.1875 13.2001 11.8125 13.5376 11.6625C15.1501 10.9875 16.0876 9.3 15.7876 7.575C15.3751 6.0375 14.1751 4.8 12.6751 4.5375Z"
          fill="#707070"
        />
        <path
          d="M12.0375 17.175H11.925C11.475 17.175 11.0625 17.55 11.0625 18.0375C11.0625 18.525 11.4375 18.9 11.925 18.9H12.0375C12.4875 18.9 12.8625 18.525 12.8625 18.0375C12.8625 17.55 12.525 17.175 12.0375 17.175Z"
          fill="#707070"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_645_3603">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_645_3603">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);



const SkladarSidebar = () => {
  return (
    <div className="skladar-sidebar">
      <a className="logo" href="/">
        <LogoIcon />
      </a>
      <a className="dashboard" href="/">
        <DashboardIcon />
      </a>
      <a className="planner" href="/planner">
        <PlannerIcon />
      </a>
      <a className="settings" href="/">
        <SettingsIcon />
      </a>
      <div className="flex-grow-1" />
      <a className="android" href="/">
        <AndroidIcon />
      </a>
      <a className="ios" href="/">
        <IosIcon />
      </a>
      <a className="help" href="/">
        <HelpIcon />
      </a>
    </div>
  );
};


export default SkladarSidebar;
