import React, {useState} from "react";
import classNames from "classnames";
import {List, Map, Record} from "immutable";
import ToggleButton from "../ToggleButton/ToggleButton";
import CollapsedIcon from "../Svg/CollapsedIcon";
import ExpandedIcon from "../Svg/ExpandedIcon";
import {State} from "../../scenes/Planner/react-planner/src/models";
import {UNIT_CENTIMETER, UNITS_LENGTH} from "../../scenes/Planner/react-planner/src/constants";
import {toFixedFloat} from "../../scenes/Planner/react-planner/src/utils/math";
import convert from "convert-units";
import {FormNumberInput, FormSelect} from "../../scenes/Planner/react-planner/src/components/style/export";
import ShelfHeightIcon from "../Svg/ShelfHeightIcon";
import ShelfThicknessIcon from "../Svg/ShelfThicknessIcon";
import defaultShelf from "../../scenes/Planner/catalog/items/shelving/defaultShelf.json";
import defaultSection from "../../scenes/Planner/catalog/items/shelving/defaultSection.json";
import DeleteRowIcon from "../Svg/DeleteRowIcon";
import isStateReadOnly from "../../scenes/Planner/utils/isStateReadOnly";
import {Modal} from "antd";
import ShelfSectionEditor from "./ShelfSectionEditor";
import LengthInput from "../../scenes/Planner/components/LengthInput/LengthInput";

interface ShelfEditorProps {
    onValid: () => void;
    save: (config: Map<unknown, unknown>) => void;
    shelf: Map<unknown, unknown>;
    shelfKey: number;
    state: State;
    totalShelves: number;
    translator: {
        t: (value: string) => string;
    };
    value: Map<unknown, unknown>;
}

const ShelfEditor: React.FC<ShelfEditorProps> = (
    {
        onValid,
        save,
        shelf,
        shelfKey,
        state,
        totalShelves,
        translator,
        value,
    }
) => {
    const shelfIndex = totalShelves - shelfKey - 1;

    const addCell = () => {
        const newCell = Map(defaultSection)
            // @ts-ignore
            .set('width', Map(defaultSection.width.defaultValue))
            // @ts-ignore
            .set('thickness', Map(defaultSection.thickness.defaultValue))
            .set('topologyCell', null)
        ;

        const cells = value.getIn(['shelves', shelfIndex, 'sections']);
        // @ts-ignore
        const cellsMerged = cells.push(newCell);

        const valueMerged = value.setIn(['shelves', shelfIndex, 'sections'], cellsMerged);

        save(valueMerged);
    };

    const height = shelf.has('height')
        ? shelf.get('height')
        : Map(defaultShelf.height)
    ;

    const updateShelfHeight = (lengthInput, unitInput = UNIT_CENTIMETER) => {
        const newLength = toFixedFloat(lengthInput);

        // @ts-ignore
        const heightMerged = height.merge({
            length: unitInput !== UNIT_CENTIMETER
                ? convert(newLength).from(unitInput).to(UNIT_CENTIMETER)
                : newLength
            ,
            _length: lengthInput,
            _unit: unitInput,
        });

        const valueMerged = value.setIn(['shelves', shelfIndex, 'height'], heightMerged);

        save(valueMerged);
    };

    const thickness = shelf.has('thickness')
        ? shelf.get('thickness')
        : Map(defaultShelf.thickness)
    ;

    const updateShelfThickness = (lengthInput, unitInput = UNIT_CENTIMETER) => {
        const newThickness = toFixedFloat(lengthInput);
        // @ts-ignore
        const thicknessMerged = thickness.merge({
            length: unitInput !== UNIT_CENTIMETER
                ? convert(newThickness).from(unitInput).to(UNIT_CENTIMETER)
                : newThickness
            ,
            _length: lengthInput,
            _unit: unitInput,
        });

        const valueMerged = value.setIn(['shelves', shelfIndex, 'thickness'], thicknessMerged);

        save(valueMerged);
    };

    const deleteShelf = () => {
        // @ts-ignore
        const updatedShelves = value.get('shelves').delete(shelfIndex);
        const valueMerged = value.set('shelves', updatedShelves);

        save(valueMerged);
    };

    const [isCollapsed, setCollapsed] = useState<boolean>(true);
    const [isShownDeleteDialog, setShownDeleteDialog] = useState<boolean>(false);
    const showDeleteDialog = () => setShownDeleteDialog(true);
    const hideDeleteDialog = () => setShownDeleteDialog(false);
    const processDeleteShelf = () => {
        deleteShelf();
        hideDeleteDialog();
    };
    const expand = () => setCollapsed(false);
    const collapse = () => setCollapsed(true);
    const isReadOnly = isStateReadOnly(state);

    return (
        <div className={classNames({
            'shelves-and-cells-body-manual-shelf': true,
            'expanded': !isCollapsed,
        })}>
            <div className="shelves-and-cells-body-manual-shelf-settings">
                <ToggleButton isActive={!isCollapsed} onActivate={expand} onDeactivate={collapse}>
                    {isCollapsed && <CollapsedIcon />}
                    {!isCollapsed && <ExpandedIcon />}
                </ToggleButton>
                <div className="shelves-and-cells-body-manual-shelf-number">
                    {shelfIndex + 1}
                </div>
                <div className="shelves-and-cells-body-manual-shelf-height-icon">
                    <ShelfHeightIcon />
                </div>
                <div className="shelves-and-cells-body-manual-shelf-height-value">
                    <LengthInput
                      onChange={(centimeters) => updateShelfHeight(centimeters, UNIT_CENTIMETER)}
                      showUnit={false}
                      state={state}
                      translator={translator}
                      unit={
                        //@ts-ignore
                        height.get('_unit')
                      }
                      value={
                        // @ts-ignore
                        height.get('_length')
                      }
                    />
                </div>
                <div className="shelves-and-cells-body-manual-shelf-thickness-icon">
                    <ShelfThicknessIcon />
                </div>
                <div className="shelves-and-cells-body-manual-shelf-thickness-value">
                    <LengthInput
                      showUnit={false}
                      onChange={(centimeters) => updateShelfThickness(centimeters, UNIT_CENTIMETER)}
                      state={state}
                      translator={translator}
                      unit={
                        //@ts-ignore
                        thickness.get('_unit')
                      }
                      value={
                        //@ts-ignore
                        thickness.get('_length')
                      }
                    />
                </div>
                <div className="shelves-and-cells-body-manual-shelf-divider" />
                <div className="shelves-and-cells-body-manual-shelf-delete-icon">
                    <ToggleButton
                        isActive={isShownDeleteDialog}
                        isDisabled={isReadOnly}
                        onActivate={showDeleteDialog}
                        onDeactivate={hideDeleteDialog}
                    >
                        <DeleteRowIcon />
                    </ToggleButton>
                </div>
            </div>
            {!isCollapsed && (
                <>
                    <div className="shelves-and-cells-body-manual-shelf-sections">
                        <div className="shelves-and-cells-body-manual-add">
                            <button
                                onClick={addCell}
                                disabled={isReadOnly}
                                className="shelves-and-cells-body-manual-add-button"
                            >
                                {translator.t('Add Cell')}
                            </button>
                        </div>
                        {
                            // @ts-ignore
                            shelf.has('sections') && shelf.get('sections').reverse().map((cell, cellKey) => {
                                return (
                                    <ShelfSectionEditor
                                        key={cellKey}
                                        onValid={onValid}
                                        save={save}
                                        section={cell}
                                        sectionKey={cellKey}
                                        shelfKey={shelfIndex}
                                        state={state}
                                        totalSections={
                                            // @ts-ignore
                                            shelf.get('sections').size
                                        }
                                        translator={translator}
                                        value={value}
                                    />
                                );
                            })
                        }
                    </div>
                </>
            )}
            <Modal
                visible={isShownDeleteDialog}
                onOk={processDeleteShelf}
                onCancel={hideDeleteDialog}
                title={translator.t('Delete shelf and all Elements')}
                okText={translator.t('Delete')}
                cancelText={translator.t('Cancel')}
                okButtonProps={{danger: true}}
            />
        </div>
    );
};

export default ShelfEditor;
