import React from "react";

const Logo = (props: {width: number | undefined, height: number | undefined} | undefined) => (
  <svg width={props?.width ?? 45} height={props?.height ?? 32} viewBox="0 0 45 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0243 0L36.7094 5.18345L44.0485 13.8569L36.3925 16.613V25.83L26.2148 29.494C26.2587 29.6165 26.2815 29.7426 26.2815 29.8714C26.2815 31.047 24.3755 32 22.0243 32C19.6731 32 17.7671 31.047 17.7671 29.8714C17.7671 29.7426 17.7899 29.6165 17.8338 29.494L7.6561 25.83V16.6131L0 13.8569L7.33907 5.18345L22.0243 0ZM24.9853 12.2722L29.2482 16.9226L40.4438 12.8922L36.0755 7.72962L35.6867 7.8851L24.9853 12.2722ZM22.0242 11.0575L33.4312 6.49471L22.0243 2.26993L10.6173 6.49474L22.0242 11.0575ZM8.36183 7.88515L19.0632 12.2721L14.8003 16.9226L3.60466 12.8922L7.97301 7.72962L8.36183 7.88515ZM20.96 27.8098C20.6141 27.8543 20.2852 27.92 19.9792 28.004L9.78472 24.334V17.3794L15.4121 19.4053L20.96 13.3531V27.8098ZM24.0694 28.004C23.7634 27.92 23.4345 27.8543 23.0886 27.8098V13.3532L28.6363 19.4053L34.2639 17.3794V24.334L24.0694 28.004Z"
      fill="black"
    />
  </svg>
  );

export default Logo;
