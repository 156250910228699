const getAreaPolygons = (state, layerID, areas, vertices: object|null = null) => {
  if (vertices === null) {
    vertices = state.getIn(['scene', 'layers', layerID, 'vertices']).toJS();
  }
  const polygons = {};
  areas.map(
    (area) => {
      const id = area.get('id');
      const areaVertices = area.get('vertices');
      polygons[id] = areaVertices.map(
        (vertexId) => {
          const vertex = (vertices as object)[vertexId];
          return [vertex.x, vertex.y];
        }
      ).toJS();
    }
  );
  return polygons;
};

export default getAreaPolygons;
