import React from "react";
import {Button, Dropdown, Menu} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import {BackWallIcon, FrontWallIcon, LeftWallIcon, RightWallIcon} from "../../../../../../../../components/Svg/Walls";
import {WALL_BACK, WALL_BOTTOM, WALL_FRONT, WALL_LEFT, WALL_RIGHT, WALL_TOP} from "../../../../constants";
import LengthInput from "../../../../../../components/LengthInput/LengthInput";

export const QrCodes = (
  {
    children,
  }
) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: '6px',
      minHeight: 'calc(100vh - 240px)',
    }}>
      {children}
    </div>
  );
};

export const QrCodesHeader = (
  {
    children,
  }
) => {
  return (
    <div style={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      fontSize: '10px',
      fontWeight: '600',
      gap: '16px',
      justifyContent: 'space-between',
      lineHeight: '20px',
    }}>
      <>
        <div>
          {children}
        </div>
        <div style={{
          border: 'solid 1px rgba(217, 217, 217, 1)',
          flexGrow: 1,
          height: 0,
        }} />
      </>
    </div>
  );
};

export const QrCodesGrid = (
  {
    children,
  }
) => {
  return (
    <div style={{
      alignItems: 'stretch',
      columnGap: '6px',
      display: 'grid',
      gridTemplateColumns: '108px 40px 85px 85px 18px',
      rowGap: '6px',
    }}>
      {children}
    </div>
  );
};

export const QrCodesGridHeaderCell = (
  {
    children,
  }
) => {
  return (
    <div style={{
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '20px',
    }}>
      {children}
    </div>
  );
};

export const QrCodeGridCell = (
  {
    alignSelf = undefined,
    children,
  }
) => {
  const style = {};
  if (alignSelf) {
    style.alignSelf = alignSelf;
  }
  return (

    <div style={style}>
      {children}
    </div>
  );
};

const QrCodeSideSelectIcon = (
  {
    children,
    isActive,
  }
) => {
  return isActive
    ? (<div className="active">{children}</div>)
    : (<>{children}</>)
  ;
};


export const QrCodeSideSelect = (
  {
    onChange,
    translator,
    value,
  }
) => {
  const menu = (
    <Menu className="qr-codes qr-codes-menu">
      <Menu.Item onClick={() => onChange(WALL_LEFT)}>
        <QrCodeSideSelectIcon isActive={value === WALL_LEFT}>
          <LeftWallIcon />
        </QrCodeSideSelectIcon>
        {translator.t('onTheLeft')}
      </Menu.Item>
      <Menu.Item onClick={() => onChange(WALL_RIGHT)}>
        <QrCodeSideSelectIcon isActive={value === WALL_RIGHT}>
          <RightWallIcon />
        </QrCodeSideSelectIcon>
        {translator.t('onTheRight')}
      </Menu.Item>
      <Menu.Item onClick={() => onChange(WALL_FRONT)}>
        <QrCodeSideSelectIcon isActive={value === WALL_FRONT}>
          <FrontWallIcon />
        </QrCodeSideSelectIcon>
        {translator.t('onTheFront')}
      </Menu.Item>
      <Menu.Item onClick={() => onChange(WALL_BACK)}>
        <QrCodeSideSelectIcon isActive={value === WALL_BACK}>
          <BackWallIcon />
        </QrCodeSideSelectIcon>
        {translator.t('onTheBack')}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown className="qr-codes qrcode-side-select" overlay={menu}>
      <div style={{
        alignItems: 'center',
        backgroundColor: 'rgba(243, 243, 243, 1)',
        border: 'none',
        borderRadius: '6px',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}>
        {value === WALL_LEFT && <LeftWallIcon />}
        {value === WALL_RIGHT && <RightWallIcon />}
        {value === WALL_BACK && <BackWallIcon />}
        {value === WALL_FRONT && <FrontWallIcon />}
      </div>
    </Dropdown>
  );
};

const DropdownAddon = (
  {
    children,
    overlay,
  }
) => {
  return (
    <Dropdown className="qr-codes" overlay={overlay}>
      <div style={{
        alignItems: 'center',
        backgroundColor: 'rgba(243, 243, 243, 1)',
        border: 'none',
        borderRadius: '6px',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '20px',
      }}>
        {children}
      </div>
    </Dropdown>
  );
};

export const QrCodeMarginSelect = (
  {
    onMarginChange,
    onLengthChange,
    state,
    translator,
    lengthValue,
    marginValue,
  }
) => {
  const menu = (
    <Menu className="qr-codes qr-codes-menu">
      <Menu.Item onClick={() => onMarginChange(WALL_LEFT)}>
        <QrCodeSideSelectIcon isActive={marginValue === WALL_LEFT}>
          <LeftWallIcon />
        </QrCodeSideSelectIcon>
        {translator.t('onTheLeft')}
      </Menu.Item>
      <Menu.Item onClick={() => onMarginChange(WALL_RIGHT)}>
        <QrCodeSideSelectIcon isActive={marginValue === WALL_RIGHT}>
          <RightWallIcon />
        </QrCodeSideSelectIcon>
        {translator.t('onTheRight')}
      </Menu.Item>
    </Menu>
  );
  const addonAfter = (
    <DropdownAddon overlay={menu}>
      {marginValue === WALL_LEFT && <LeftWallIcon />}
      {marginValue === WALL_RIGHT && <RightWallIcon />}
    </DropdownAddon>
  );
  return (
    <LengthInput
      addonAfter={addonAfter}
      onChange={onLengthChange}
      state={state}
      translator={translator}
      value={lengthValue}
      showUnit={false}
    />
  );
};

export const QrCodeHeightSelect = (
  {
    onHeightChange,
    onLengthChange,
    state,
    translator,
    lengthValue,
    heightValue,
  }
) => {
  const menu = (
    <Menu className="qr-codes qr-codes-menu">
      <Menu.Item onClick={() => onHeightChange(WALL_TOP)}>
        <QrCodeSideSelectIcon isActive={heightValue === WALL_TOP}>
          <LeftWallIcon />
        </QrCodeSideSelectIcon>
        {translator.t('onTheTop')}
      </Menu.Item>
      <Menu.Item onClick={() => onHeightChange(WALL_BOTTOM)}>
        <QrCodeSideSelectIcon isActive={heightValue === WALL_BOTTOM}>
          <RightWallIcon />
        </QrCodeSideSelectIcon>
        {translator.t('onTheBottom')}
      </Menu.Item>
    </Menu>
  );
  const addonAfter = (
    <DropdownAddon overlay={menu}>
      {heightValue === WALL_TOP && <BackWallIcon />}
      {heightValue === WALL_BOTTOM && <FrontWallIcon />}
    </DropdownAddon>
  );
  return (
    <LengthInput
      addonAfter={addonAfter}
      onChange={onLengthChange}
      state={state}
      translator={translator}
      value={lengthValue}
      showUnit={false}
    />
  );
};

export const QrCodeActions = (
  {
    onDelete,
    translator,
  }
) => {
  const menu = (
    <Menu className="qr-codes qr-codes-menu">
      <Menu.Item onClick={onDelete}>
        {translator.t('Delete QR Code')}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown className="qr-codes" overlay={menu}>
      <EllipsisOutlined />
    </Dropdown>
  );
};

export const AddQrCodeButton = (
  {
    onAddQrCode,
    translator,
  }
) => {
  return (
    <div style={{
      gridColumn: '1 / span 4',
    }}>
      <Button type="primary" onClick={onAddQrCode}>
        {translator.t('Add QR Code')}
      </Button>
    </div>
  );
};

export const DownloadAllQrCodesButton = (
  {
    onClick,
    translator,
  }
) => {
  return (
    <div className="qr-codes">
      <Button type="primary" onClick={onClick}>
        {translator.t('Download All QR Codes')}
      </Button>
    </div>
  );
};
