import React from "react";

const LadderIcon: React.FC = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_726_1219)">
    <mask id="mask0_726_1219" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path d="M12 2.5V21.5" className="stroke" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M3 3.2478V16.7494" className="stroke" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M21 7.2478V20.7494" className="stroke" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M21.5 12L2.5 12" className="stroke" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M21.5 8L2.5 8" className="stroke" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M12 4L2.5 4.00001" className="stroke" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M21.5 16L2.5 16" className="stroke" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M21.5 20L11.5 20" className="stroke" strokeWidth="1.5" strokeMiterlimit="10"/>
    </mask>
    <g mask="url(#mask0_726_1219)">
      <rect width="24" height="24" className="fill" />
    </g>
  </g>
  <defs>
    <clipPath id="clip0_726_1219">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export default LadderIcon;
