import React from "react";

const StairsIcon: React.FC = ({}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_726_1212)">
    <mask id="mask0_726_1212" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path
        d="M6.13364 19.6518V15.7327H9.25909V11.8218H13.1782V7.91091H17.0891V4H21V19.6518H3"
        stroke="#020202"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </mask>
    <g mask="url(#mask0_726_1212)">
      <rect width="24" height="24" className="fill"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_726_1212">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export default StairsIcon;
