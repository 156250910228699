import React from "react";
import {InputNumber} from "antd";
import {UNIT_ONES} from "../../react-planner/src/constants";

interface CountInputProps {
  disabled?: boolean;
  onChange: (value: string | number | undefined) => void;
  showUnit?: boolean;
  translator: {
    t: (value: string) => string;
  };
  unit?: string;
  value: number;
}

const CountInput: React.FC<CountInputProps> = (
  {
    disabled = false,
    onChange,
    showUnit = true,
    translator,
    unit = UNIT_ONES,
    value,
  }
) => {
  const unitTranslated = translator.t(unit);
  const formatValue = (val) => showUnit
    ? `${val} ${unitTranslated}`
    : `${val}`
  ;

  const parseValue = (inputValue: string | undefined): string => {
    if (!inputValue) {
      return '';
    }

    const matchResult = inputValue.match(/(\d+)/);
    return !matchResult?.length
      ? ''
      : matchResult[0]
      ;
  };

  return (
    <div className="count-input">
      <InputNumber
        disabled={disabled}
        defaultValue={0}
        value={value}
        min={0}
        step={1}
        onChange={onChange}
        prefix={unitTranslated}
        formatter={formatValue}
      />
    </div>
  );
};

export default CountInput;
