import React from "react";

const ObstacleItemsIcon: React.FC = ({}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="none"/>
    <mask id="path-2-inside-1_234_3422" fill="white">
      <path d="M21 3H3V21H21V3Z"/>
    </mask>
    <path
      d="M6.96967 8.03033L15.9697 17.0303L17.0303 15.9697L8.03033 6.96967L6.96967 8.03033ZM15.9697 6.96967L6.96967 15.9697L8.03033 17.0303L17.0303 8.03033L15.9697 6.96967ZM3 3V1.5H1.5V3H3ZM21 3H22.5V1.5H21V3ZM21 21V22.5H22.5V21H21ZM3 21H1.5V22.5H3V21ZM3 4.5H21V1.5H3V4.5ZM19.5 3V21H22.5V3H19.5ZM21 19.5H3V22.5H21V19.5ZM4.5 21V3H1.5V21H4.5Z"
      className="fill"
      mask="url(#path-2-inside-1_234_3422)"/>
    <rect x="-451.5" y="-19.5" width="1935" height="63" rx="4.5" className="stroke" strokeDasharray="10 5"/>
  </svg>
;

export default ObstacleItemsIcon;
