export default class Stores {
  static AuthenticationStore = 'authenticationStore';
  static RoleStore = 'roleStore';
  static TenantStore = 'tenantStore';
  static UserStore = 'userStore';
  static SessionStore = 'sessionStore';
  static AccountStore = 'accountStore';
  static TaskStore = 'taskStore';
  static DeviceStore = 'deviceStore';
  static ProductStore = 'productStore';
  static WebRTCStore = 'webRTCStore';
  static WarehouseStore = 'warehouseStore';
}
