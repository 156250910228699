import React from "react";

const SectionThicknessIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0001 9.66683H11.1667V0.333496H10.0001V9.66683ZM0.666748 4.41683H6.60041L5.38358 3.2L6.20841 2.37516L8.83342 5.00016L6.20841 7.62516L5.38358 6.80033L6.60041 5.5835H0.666748V4.41683Z"
                className="fill"
            />
        </svg>

    );
};

export default SectionThicknessIcon;
