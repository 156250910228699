import convert from "convert-units";
import {UNIT_CENTIMETER} from "../react-planner/src/constants";
import {CATALOG_ITEMS} from "../catalog/mycatalog";
const getAngle = (rotation: number): number => {
  return (rotation + 360) % 360;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const geometric = require("geometric");

const getItemPolygon = (item) => {
  const properties = item.get('properties');
  switch (item.get('type')) {
    case CATALOG_ITEMS.COLUMN:
      const radius = properties.get('radius');
      const centimeters = convert(radius.get('length')).from(radius.get('unit')).to(UNIT_CENTIMETER);
      const leftBorderX = item.x - centimeters;
      const rightBorderX = item.x + centimeters;
      const topBorderY = item.y + centimeters;
      const bottomBorderY = item.y - centimeters;
      return [
        [leftBorderX, topBorderY],
        [leftBorderX, bottomBorderY],
        [rightBorderX, bottomBorderY],
        [rightBorderX, topBorderY],
      ];
    case CATALOG_ITEMS.COLUMN_SQUARE:
    case CATALOG_ITEMS.RACK:
    case CATALOG_ITEMS.MOVE_DIRECTION:
    case CATALOG_ITEMS.SIMPLE_STAIR:
      const width = convert(properties.getIn(['width', 'length']))
        .from(properties.getIn(['width', 'unit']))
        .to(UNIT_CENTIMETER);
      const depth = convert(properties.getIn(['depth', 'length']))
        .from(properties.getIn(['depth', 'unit']))
        .to(UNIT_CENTIMETER)
      ;
      const leftRectX = item.x - width / 2;
      const rightRectX = item.x + width / 2;
      const topRectY = item.y + depth / 2;
      const bottomRectY = item.y - depth / 2;
      const rectBase = [
        [leftRectX, topRectY],
        [leftRectX, bottomRectY],
        [rightRectX, bottomRectY],
        [rightRectX, topRectY],
      ];
      const angle = getAngle(item.get('rotation'));
      return geometric.polygonRotate(rectBase, angle, [item.x, item.y]);
  }
  return null;
};

export default getItemPolygon;
