import WarehouseSchemaCollection from "./WarehouseSchemaCollection";
import {UnitType, WarehouseType} from "../../api/swagger";

export default class Warehouse {
  constructor(
    readonly id: string | undefined,
    readonly externalId: string | undefined,
    readonly name: string | undefined,
    readonly type: WarehouseType | undefined,
    readonly width: number | undefined,
    readonly height: number | undefined,
    readonly unit: UnitType | undefined,
    readonly schemas: WarehouseSchemaCollection,
    readonly hasTemperatureCondition: boolean | undefined,
    readonly minDegreesCelsius: number | undefined,
    readonly maxDegreesCelsius: number | undefined,
  ) {
  }

  equals(that: Warehouse | undefined): boolean {
    return that instanceof Warehouse && this.id === that.id;
  }
}