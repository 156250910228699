import React from "react";
import {Button} from "antd";
import WarehouseSchema from "../../../models/Warehouses/WarehouseSchema";
import WarehouseSchemaIcon from "../../../components/Svg/WarehouseSchemaIcon";
import DeleteIcon from "../../../components/Svg/DeleteIcon";
import PlayIcon from "../../../components/Svg/PlayIcon";
import DownloadIcon from "../../../components/Svg/DownloadIcon";
import connect from "../../../stores/connect";

interface WarehouseSchemaDescriptionProps {
  schema: WarehouseSchema | null;
  setCurrentWarehouseSchema: (schema: WarehouseSchema|null) => void;
}

const WarehouseSchemaDescription: React.FC<WarehouseSchemaDescriptionProps> = ({
  schema,
  setCurrentWarehouseSchema,
}) => {
  if (!schema) {
    return null;
  }

  const onOpenButtonClick = () => setCurrentWarehouseSchema(schema);

  return (
    <div className="warehouse-schema-description">
      <div className="warehouse-schema-description-info">
        <div className="warehouse-schema-description-info-data">
          <WarehouseSchemaIcon schema={schema} />
          <div className="warehouse-schema-description-info-data-status">
            <div className="warehouse-schema-description-info-data-status-date">{schema.formatDate()}</div>
            <div className={`warehouse-schema-description-info-data-status-text ${schema.isActive ? 'active' : ''}`}>
              {schema.isActive && <>Используется в работе склада</>}
              {!schema.isActive && schema.isDraft && <>Черновик</>}
              {!schema.isActive && !schema.isDraft && <>Не используется в работе склада</>}
            </div>
          </div>
        </div>
        <div className="warehouse-schema-description-info-name">
          {schema.name || "No name"}
        </div>
      </div>
      <div className="warehouse-schema-description-actions">
        <div className="warehouse-schema-description-actions-icons">
          <div><DeleteIcon schema={schema} /></div>
          <div><PlayIcon schema={schema} /></div>
          <div><DownloadIcon schema={schema} /></div>
        </div>
        <div className="warehouse-schema-description-actions-open">
          <Button
            className={`warehouse-schema-description-actions-open-button ${schema.isActive ? 'active' : ''}`}
            onClick={onOpenButtonClick}
          >
            Открыть
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(({store}) => ({
  setCurrentWarehouseSchema: store.warehouseStore.setCurrentWarehouseSchema,
}))(WarehouseSchemaDescription);
