import {COLORS} from "../../shared-style";

const tableStyle = {width: '100%', borderSpacing: '2px 0', marginBottom: '2px'};
const firstTdStyle = {width: '81px', color: COLORS.font, paddingBottom: '8px', paddingTop: '8px', textTransform:'capitalize'};

const PropertyStyle = {
  tableStyle,
  firstTdStyle
};

export default PropertyStyle;
