import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FormNumberInput, FormTextInput } from '../../../style/export';
import { PropertyLengthMeasure } from '../../../../catalog/properties/export';
import {COLORS, INPUT_STYLE} from "../../../../shared-style";
import QrcodesEditor from "./qrcodes-editor";
import LengthInput from "../../../../../../components/LengthInput/LengthInput";
import {UNIT_CENTIMETER} from "../../../../constants";

const tableStyle = { width: '100%' };
const firstTdStyle = { width: '81px', color: COLORS.font };
const inputStyle = { textAlign: 'left' };

export default function LineAttributesEditor({element, onUpdate, attributeFormData, state, ...rest}, {translator}) {

  let name = attributeFormData.has('name') ? attributeFormData.get('name') : element.name;
  let vertexOne = attributeFormData.has('vertexOne') ? attributeFormData.get('vertexOne') : null;
  let vertexTwo = attributeFormData.has('vertexTwo') ? attributeFormData.get('vertexTwo') : null;
  let lineLength = attributeFormData.has('lineLength') ? attributeFormData.get('lineLength') : null;

  return (
    <div>
      <table className="attributes-table">
        <tbody>
          <tr>
            <td colSpan={2}>
              {translator.t('Attributes')}
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>{translator.t('Name')}</td>
            <td>
              <FormTextInput
                state={state}
                value={name}
                onChange={event => onUpdate('name', event.target.value)}
                style={INPUT_STYLE}
              />
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>X1</td>
            <td>
              <LengthInput
                onChange={(value) => onUpdate('vertexOne', {'x': value})}
                state={state}
                translator={translator}
                unit={UNIT_CENTIMETER}
                value={vertexOne.get('x')}
              />
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>Y1</td>
            <td>
              <LengthInput
                onChange={(value) => onUpdate('vertexOne', {'y': value})}
                state={state}
                translator={translator}
                unit={UNIT_CENTIMETER}
                value={vertexOne.get('y')}
              />
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>X2</td>
            <td>
              <LengthInput
                onChange={(value) => onUpdate('vertexTwo', {'x': value})}
                state={state}
                translator={translator}
                unit={UNIT_CENTIMETER}
                value={vertexTwo.get('x')}
              />
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>Y2</td>
            <td>
              <LengthInput
                onChange={(value) => onUpdate('vertexTwo', {'y': value})}
                state={state}
                translator={translator}
                unit={UNIT_CENTIMETER}
                value={vertexTwo.get('y')}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <PropertyLengthMeasure
        value={ lineLength }
        onUpdate={mapped => onUpdate('lineLength', mapped)}
        configs={{label: 'Length', min: 0, max: Infinity, precision: 2}}
        state={state}
        translator={ translator }
      />
      {element.get('type') === 'wall' && (
        <QrcodesEditor
          translator={translator}
          attributeFormData={attributeFormData}
          state={state}
          onUpdate={onUpdate}
          element={element}
          {...rest}
        />
      )}
    </div>
  );
}

LineAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

LineAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
