import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Panel from '../panel';
import {Seq, Map, List} from 'immutable';
import {
  MODE_IDLE, MODE_2D_ZOOM_IN, MODE_2D_ZOOM_OUT, MODE_2D_PAN, MODE_3D_VIEW, MODE_3D_FIRST_PERSON,
  MODE_WAITING_DRAWING_LINE, MODE_DRAWING_LINE, MODE_DRAWING_HOLE, MODE_DRAWING_ITEM, MODE_DRAGGING_LINE,
  MODE_DRAGGING_VERTEX, MODE_DRAGGING_ITEM, MODE_DRAGGING_HOLE, MODE_FITTING_IMAGE, MODE_UPLOADING_IMAGE,
  MODE_ROTATING_ITEM
} from '../../../constants';
import ElementEditor from './element-editor';
import {getSelectedItems} from "../../../../../utils/getSelectedElements";
import {getSelectedLayer, getSelectedLayerId} from "../../../../../utils/getSelectedLayer";
import {getCopiedItemList} from "../../../../../utils/getCopiedElements";

export default function PanelElementEditor({state}, {projectActions, itemsActions, translator}) {

  let {scene, mode} = state;
  const selectedItemsRaw = getSelectedItems(state);
  const selectedItems = useMemo(() => {
    return selectedItemsRaw.sort(
      (firstItem, secondItem) => {
        if (firstItem.id < secondItem.id) {
          return -1;
        } else if (firstItem.id > secondItem.id) {
          return 1;
        }
        return 0;
      }
    );
  }, [selectedItemsRaw.hashCode()]);

  const copiedItems = getCopiedItemList(state);
  const selectedItemsAreCopied = useMemo(
    () => {
      return copiedItems.equals(selectedItems);
    },
    [selectedItemsRaw.hashCode(), copiedItems.hashCode()]
  );

  if (![MODE_IDLE, MODE_2D_ZOOM_IN, MODE_2D_ZOOM_OUT, MODE_2D_PAN,
      MODE_3D_VIEW, MODE_3D_FIRST_PERSON,
      MODE_WAITING_DRAWING_LINE, MODE_DRAWING_LINE, MODE_DRAWING_HOLE, MODE_DRAWING_ITEM,
      MODE_DRAGGING_LINE, MODE_DRAGGING_VERTEX, MODE_DRAGGING_ITEM, MODE_DRAGGING_HOLE,
      MODE_ROTATING_ITEM, MODE_UPLOADING_IMAGE, MODE_FITTING_IMAGE].includes(mode)
  ) {
    return null;
  }

  const copyItems = () => {
    itemsActions.copyItems(
      getSelectedLayerId(state),
      getSelectedItems(state)
        .map(
          (item) => item.id
        )
        .toArray()
    );
  };

  const pasteItems = () => {
    itemsActions.pasteItems(
      getSelectedLayerId(state),
    );
  };

  let componentRenderer = (element, layer) =>
    <Panel key={element.id} name={translator.t('Properties: [{0}] {1}', element.type, element.id)} opened={true}>
      <div style={{padding: '5px 15px'}}>
        <ElementEditor element={element} layer={layer} state={state}/>
      </div>
    </Panel>;

  let layerRenderer = layer => Seq()
    .concat(layer.lines, layer.holes, layer.areas, layer.items)
    .filter(element => element.selected)
    .map(element => componentRenderer(element, layer))
    .valueSeq();

  return <div>
    {selectedItems && selectedItems.size > 1 && (
      <button className="copy-button" type="button" disabled={selectedItemsAreCopied} onClick={copyItems}>
        Копировать
      </button>
    )}
    {copiedItems && !!copiedItems.size && (
      <button className="copy-button" type="button" onClick={pasteItems}>
        Вставить
      </button>
    )}
    <>{scene.layers.valueSeq().map(layerRenderer)}</>
  </div>

}

PanelElementEditor.propTypes = {
  state: PropTypes.object.isRequired,
};

PanelElementEditor.contextTypes = {
  itemsActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};
