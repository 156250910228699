import React from "react";
import classNames from "classnames";

interface ToggleButtonProps {
    children: React.ReactNode;
    isActive: boolean;
    isDisabled?: boolean;
    onActivate: () => void;
    onDeactivate: () => void;
    style?: {[property: string]: string};
}

const ToggleButton: React.FC<ToggleButtonProps> = (
    {
        children,
        isActive,
        isDisabled = false,
        onActivate,
        onDeactivate,
        style = {},
    }
) => {
    return (
        <button
            className={classNames({
                'toggle-button': true,
                'button': true,
                'active': isActive,
            })}
            disabled={isDisabled}
            onClick={isActive ? onDeactivate : onActivate}
            style={style}
        >
            {children}
        </button>
    );
};

export default ToggleButton;
