import React from "react";

const EditIcon: React.FC = () => {
  return (
    <svg className="edit-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_758_4800)">
        <path d="M15.3001 3.25001C14.5001 2.40001 13.6501 1.55001 12.8001 0.725012C12.6251 0.550012 12.4251 0.450012 12.2001 0.450012C11.9751 0.450012 11.7501 0.525012 11.6001 0.700012L2.17508 10.05C2.02508 10.2 1.92508 10.375 1.85008 10.55L0.475076 14.75C0.400076 14.95 0.450076 15.15 0.550076 15.3C0.675076 15.45 0.850076 15.55 1.07508 15.55H1.17508L5.45008 14.125C5.65008 14.05 5.82508 13.95 5.95008 13.8L15.3251 4.45001C15.4751 4.30001 15.5751 4.07501 15.5751 3.85001C15.5751 3.62501 15.4751 3.42501 15.3001 3.25001ZM5.15008 13.025C5.12508 13.05 5.10008 13.05 5.07508 13.075L1.85008 14.15L2.92508 10.925C2.92508 10.9 2.95008 10.875 2.97508 10.85L9.85008 4.00001L12.0251 6.17501L5.15008 13.025ZM12.8001 5.37501L10.6251 3.20001L12.1501 1.67501C12.8751 2.37501 13.6001 3.12501 14.3001 3.85001L12.8001 5.37501Z" fill="#2F80ED"/>
      </g>
      <defs>
        <clipPath id="clip0_758_4800">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default EditIcon;
