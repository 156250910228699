import {action, observable} from "mobx";
import {apiClient} from "../services/apiClient";
import {
  WarehouseDto,
  WarehouseSchemaDto,
  EditWarehouseSchemaRequestDto,
  SetWarehouseSchemaAsActiveRequestDto,
  CreateWarehouseSchemaRequestDto,
  GuidEntityDto, EditWarehouseRequestDto,
} from "../api/swagger";
import EditWarehouseSchemaJsonInputDto from "./dto/EditWarehouseSchemaJsonInputDto";
import Warehouse from "../models/Warehouses/Warehouse";
import WarehouseSchema from "../models/Warehouses/WarehouseSchema";
import WarehouseCollection from "../models/Warehouses/WarehouseCollection";
import WarehouseFactory from "../models/Warehouses/factories/WarehouseFactory";
import WarehouseTopologyCollection from "../models/Warehouses/WarehouseTopologyCollection";
import WarehouseTopology from "../models/Warehouses/WarehouseTopology";

export interface CreatingWarehouseParams {
  height: number;
  width: number;
  unit: string;
}

class WarehouseStore {
  @observable creatingWarehouseParams: CreatingWarehouseParams | undefined = undefined;
  @observable isCreatingWarehouseSchemaDraft: boolean = false;
  @observable isEditingWarehouse: boolean = false;
  @observable isDeletingWarehouseSchema: boolean = false;
  @observable isLinkingWarehouseToTopology: boolean = false;
  @observable isLoadingTopologies: boolean = false;
  @observable isLoadingWarehouses: boolean = false;
  @observable isSavingWarehouseSchema: boolean = false;
  @observable isSettingWarehouseSchemaActive: boolean = false;
  @observable currentWarehouse: Warehouse|undefined = undefined;
  @observable currentWarehouseSchema: WarehouseSchema|undefined = undefined;
  @observable currentWarehouseTopology: WarehouseTopology|undefined = undefined;
  @observable warehouses: WarehouseCollection = new WarehouseCollection([]);
  @observable topologies: WarehouseTopologyCollection = new WarehouseTopologyCollection([]);
  private warehouseFactory = new WarehouseFactory();

  @action.bound createEditWarehouseRequestDto(): EditWarehouseRequestDto | undefined {
    if (!this.currentWarehouse) {
      return undefined;
    }
    return this.warehouseFactory.createEditWarehouseRequestDto(this.currentWarehouse);
  }

  @action getAll: () => Promise<void> = async () => {
    this.isLoadingWarehouses = true;
    const result = await apiClient.getWarehouses();
    this.isLoadingWarehouses = false;
    this.warehouses = this.warehouseFactory.createWarehouseCollection(result);
    this.setCurrentWarehouseSchema(undefined);
    this.setCurrentWarehouse(undefined);
  };

  @action getTopologies: () => Promise<WarehouseTopologyCollection> = async () => {
    this.isLoadingTopologies = true;
    const result = await apiClient.getTopologySchema();
    this.isLoadingTopologies = false;
    this.topologies = this.warehouseFactory.createWarehouseTopologyCollection(result.warehousesTopologies);
    return this.topologies;
  };

  @action saveWarehouseSchemaJson: (input: EditWarehouseSchemaJsonInputDto) => Promise<WarehouseSchemaDto> = async (input) => {
    const body: EditWarehouseSchemaRequestDto = {
      warehouseSchemaId: input.warehouseSchema.id,
      versionName: input.warehouseSchema.versionName,
      schema: JSON.stringify(input.json),
      topology: input.warehouseSchema.topology,
      graph: input.warehouseSchema.graph,
      isActive: input.warehouseSchema.isActive,
    };
    this.isSavingWarehouseSchema = true;
    const result = await apiClient.editWarehouseSchema(body);
    this.setCurrentWarehouseSchema(this.warehouseFactory.createWarehouseSchema(result));
    this.isSavingWarehouseSchema = false;
    await this.getAll();
    return result;
  };

  @action setCurrentWarehouse: (warehouse: Warehouse|undefined) => void = (warehouse) => {
    this.currentWarehouse = warehouse;
    this.setCurrentWarehouseSchema(undefined);
    this.currentWarehouseTopology = undefined;
    if (!!warehouse?.externalId && this.topologies && !this.topologies.isEmpty()) {
      const topology = this.topologies.findById(warehouse.externalId);
      if (topology) {
        this.currentWarehouseTopology = topology;
      }
    }
    if (warehouse && (!warehouse.schemas || warehouse.schemas.isEmpty())) {
      const createdWarehouseSchema = this.warehouseFactory.createEmptyWarehouseSchema(warehouse);
      this.setCurrentWarehouseSchema(createdWarehouseSchema);
    }
  };

  @action setCurrentWarehouseSchema: (schema: WarehouseSchema|undefined) => void = (schema) => {
    this.currentWarehouseSchema = schema;
  };

  @action setWarehouseSchemaActive: (schema: WarehouseSchema) => void = async (schema) => {
    this.isSettingWarehouseSchemaActive = true;
    const dto: SetWarehouseSchemaAsActiveRequestDto = {
      warehouseId: schema.warehouseId,
      warehouseSchemaId: schema.id,
    };
    const updatedWarehouseSchemaDto = await apiClient.setWarehouseSchemaAsActive(dto);
    const updatedWarehouseSchema = this.warehouseFactory.createWarehouseSchema(updatedWarehouseSchemaDto);
    this.setCurrentWarehouseSchema(updatedWarehouseSchema);
    this.isSettingWarehouseSchemaActive = false;
  };

  @action deleteWarehouseSchema: (schema: WarehouseSchema) => Promise<void> = async (schema) => {
    const dto: GuidEntityDto = {
      id: schema.id,
    };
    this.isDeletingWarehouseSchema = true;
    await apiClient.deleteWarehouseSchema(dto);
    this.isDeletingWarehouseSchema = false;
    const warehouseId = this.currentWarehouse?.id;
    await this.getAll();
    this.setCurrentWarehouse(this.warehouses.find((warehouse) => warehouse.id === warehouseId));
  };

  @action createWarehouseSchemaDraft: (schema: WarehouseSchema) => Promise<void> = async (schema) => {
    const dto: CreateWarehouseSchemaRequestDto = {
      warehouseId: schema.warehouseId,
      versionName: schema.name,
      schema: schema.schema,
      topology: schema.topology,
      graph: schema.graph,
      isActive: false,
    }
    this.isCreatingWarehouseSchemaDraft = true;
    const createdWarehouseSchemaDto = await apiClient.createWarehouseSchema(dto);
    this.isCreatingWarehouseSchemaDraft = false;
    const createdWarehouseSchema = this.warehouseFactory.createWarehouseSchema(createdWarehouseSchemaDto);
    this.setCurrentWarehouseSchema(createdWarehouseSchema);
  }

  @action linkWarehouseToTopology: (warehouse: Warehouse, topology: WarehouseTopology | null) => Promise<void> = async (warehouse, topology) => {
    const dto: EditWarehouseRequestDto = {
      name: warehouse.name,
      warehouseId: warehouse.id,
      externalId: topology?.externalId,
    };

    this.isLinkingWarehouseToTopology = true;
    const result = await apiClient.editWarehouse(dto);
    this.isLinkingWarehouseToTopology = false;
    this.getAll();
    this.getTopologies();
  };

  @action editWarehouse: (warehouse: Warehouse, dto: EditWarehouseRequestDto) => Promise<void> = async (warehouse, dto) => {
    this.isEditingWarehouse = true;
    const result = await apiClient.editWarehouse(dto);
    this.isEditingWarehouse = false;
  };

  @action createWarehouse: (
    dto: EditWarehouseRequestDto,
    {
      width,
      height,
      unit,
    }: CreatingWarehouseParams,
  ) => Promise<void> = async (
    dto: EditWarehouseRequestDto,
    {
      width,
      height,
      unit,
    }
  ) => {
    this.isEditingWarehouse = true;
    const result = await apiClient.editWarehouse(dto);
    const createdWarehouse = this.warehouseFactory.createWareHouse(result);
    const warehouseSchemaDto = await apiClient.createWarehouseSchema({
      warehouseId: createdWarehouse.id,
      versionName: 'Первая версия',
      isActive: false,
    });
    const createdWarehouseSchema = this.warehouseFactory.createWarehouseSchema(warehouseSchemaDto);
    await this.getAll();
    this.isEditingWarehouse = false;
    this.setCreatingWarehouseParams({
      width,
      height,
      unit,
    });
    this.setCurrentWarehouse(createdWarehouse);
    this.setCurrentWarehouseSchema(createdWarehouseSchema);
  };

  setCreatingWarehouseParams: (params: CreatingWarehouseParams | undefined) => void = (params) => {
    this.creatingWarehouseParams = params;
  };

  @action createWarehouseSchema: (dto: CreateWarehouseSchemaRequestDto, setCurrent: boolean) => void = async (dto, setCurrent = false) => {
    const result = await apiClient.createWarehouseSchema(dto);
    const createdWarehouseSchema = this.warehouseFactory.createWarehouseSchema(result);
    if (setCurrent) {
      this.setCurrentWarehouseSchema(createdWarehouseSchema);
    }
  };
}

export default WarehouseStore;
