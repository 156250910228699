import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopologyShelf {
  constructor(
    readonly name: string | undefined,
    readonly systemName: string | undefined,
    readonly cellAddresses: WarehouseTopologyCellAddressCollection,
  ) {
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologyShelf {
    return new WarehouseTopologyShelf(
      this.name,
      this.systemName,
      this.cellAddresses.withoutCells(removingCells),
    );
  }
}
