import React, { Component } from 'react';
import * as SharedStyle from '../../shared-style';
import {INPUT_STYLE} from "../../shared-style";
import isStateReadOnly from "../../../../utils/isStateReadOnly";

const STYLE_INPUT = {
  ...INPUT_STYLE,
  display: 'block',
  width: '100%',
  fontSize: '13px',
  lineHeight: '1.25',
  backgroundImage: 'none',
  outline: 'none',
  height: '30px',
};


export default class FormTextInput extends Component {

  constructor(props) {
    super(props);
    this.state = { focus: false };
  }

  render() {
    let { style, state, ...rest } = this.props;

    let textInputStyle = { ...STYLE_INPUT, ...style };
    if (this.state.focus) textInputStyle.border = `1px solid ${SharedStyle.SECONDARY_COLOR.main}`;

    return <input
      disabled={isStateReadOnly(state)}
      onFocus={e => this.setState({ focus: true })}
      onBlur={e => this.setState({ focus: false })}
      style={textInputStyle}
      type="text"
      {...rest}
    />
  }
}

FormTextInput.defaultProps = {
  style: {}
};
