import React from "react";

export const NoWallsIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                className="stroke"
                x="3.25"
                y="3.25"
                width="13.5"
                height="13.5"
                strokeWidth="1.5"
                strokeDasharray="3 3"
            />
        </svg>
    );
};

export const LeftWallIcon: React.FC = () => {
    return (
        <svg className="fill" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_1004_6232" fill="white">
                <path d="M2.5 2.5H17.5V17.5H2.5V2.5Z"/>
            </mask>
            <path
                className="fill"
                d="M17.5 2.5H18.25V1.75H17.5V2.5ZM17.5 17.5V18.25H18.25V17.5H17.5ZM2.5 18.25H3.75V16.75H2.5V18.25ZM6.25 18.25H8.75V16.75H6.25V18.25ZM11.25 18.25H13.75V16.75H11.25V18.25ZM16.25 18.25H17.5V16.75H16.25V18.25ZM18.25 17.5V16.25H16.75V17.5H18.25ZM18.25 13.75V11.25H16.75V13.75H18.25ZM18.25 8.75V6.25H16.75V8.75H18.25ZM18.25 3.75V2.5H16.75V3.75H18.25ZM17.5 1.75H16.25V3.25H17.5V1.75ZM13.75 1.75H11.25V3.25H13.75V1.75ZM8.75 1.75H6.25V3.25H8.75V1.75ZM3.75 1.75H2.5V3.25H3.75V1.75ZM17.5 2.5H19V1H17.5V2.5ZM17.5 17.5V19H19V17.5H17.5ZM2.5 19H3.75V16H2.5V19ZM6.25 19H8.75V16H6.25V19ZM11.25 19H13.75V16H11.25V19ZM16.25 19H17.5V16H16.25V19ZM19 17.5V16.25H16V17.5H19ZM19 13.75V11.25H16V13.75H19ZM19 8.75V6.25H16V8.75H19ZM19 3.75V2.5H16V3.75H19ZM17.5 1H16.25V4H17.5V1ZM13.75 1H11.25V4H13.75V1ZM8.75 1H6.25V4H8.75V1ZM3.75 1H2.5V4H3.75V1Z"
                mask="url(#path-1-inside-1_1004_6232)"
            />
            <mask id="path-3-inside-2_1004_6232" fill="white">
                <path d="M2.5 2.5H17.5V17.5H2.5V2.5Z"/>
            </mask>
            <path className="fill" d="M4 17.5V2.5H1V17.5H4Z" mask="url(#path-3-inside-2_1004_6232)"/>
        </svg>

    );
};

export const BackWallIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_1004_6234" fill="white">
                <path d="M17.5 2.5L17.5 17.5L2.5 17.5L2.5 2.5L17.5 2.5Z"/>
            </mask>
            <path
                className="fill"
                d="M17.5 17.5L17.5 18.25L18.25 18.25L18.25 17.5L17.5 17.5ZM2.5 17.5L1.75 17.5L1.75 18.25L2.5 18.25L2.5 17.5ZM1.75 2.5L1.75 4.375L3.25 4.375L3.25 2.5L1.75 2.5ZM1.75 8.125L1.75 11.875L3.25 11.875L3.25 8.125L1.75 8.125ZM1.75 15.625L1.75 17.5L3.25 17.5L3.25 15.625L1.75 15.625ZM2.5 18.25L3.75 18.25L3.75 16.75L2.5 16.75L2.5 18.25ZM6.25 18.25L8.75 18.25L8.75 16.75L6.25 16.75L6.25 18.25ZM11.25 18.25L13.75 18.25L13.75 16.75L11.25 16.75L11.25 18.25ZM16.25 18.25L17.5 18.25L17.5 16.75L16.25 16.75L16.25 18.25ZM18.25 17.5L18.25 15.625L16.75 15.625L16.75 17.5L18.25 17.5ZM18.25 11.875L18.25 8.125L16.75 8.125L16.75 11.875L18.25 11.875ZM18.25 4.375L18.25 2.5L16.75 2.5L16.75 4.375L18.25 4.375ZM17.5 17.5L17.5 19L19 19L19 17.5L17.5 17.5ZM2.5 17.5L0.999999 17.5L0.999999 19L2.5 19L2.5 17.5ZM1 2.5L1 4.375L4 4.375L4 2.5L1 2.5ZM1 8.125L1 11.875L4 11.875L4 8.125L1 8.125ZM0.999999 15.625L0.999999 17.5L4 17.5L4 15.625L0.999999 15.625ZM2.5 19L3.75 19L3.75 16L2.5 16L2.5 19ZM6.25 19L8.75 19L8.75 16L6.25 16L6.25 19ZM11.25 19L13.75 19L13.75 16L11.25 16L11.25 19ZM16.25 19L17.5 19L17.5 16L16.25 16L16.25 19ZM19 17.5L19 15.625L16 15.625L16 17.5L19 17.5ZM19 11.875L19 8.125L16 8.125L16 11.875L19 11.875ZM19 4.375L19 2.5L16 2.5L16 4.375L19 4.375Z"
                mask="url(#path-1-inside-1_1004_6234)"
            />
            <mask id="path-3-inside-2_1004_6234" fill="white">
                <path d="M17.5 2.5L17.5 17.5L2.5 17.5L2.5 2.5L17.5 2.5Z"/>
            </mask>
            <path className="fill" d="M2.5 4L17.5 4L17.5 1L2.5 0.999999L2.5 4Z" mask="url(#path-3-inside-2_1004_6234)"/>
        </svg>
    );
};

export const RightWallIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_1004_6236" fill="white">
                <path d="M17.5 17.5L2.5 17.5L2.5 2.5L17.5 2.5L17.5 17.5Z"/>
            </mask>
            <path
                d="M2.5 17.5L1.75 17.5L1.75 18.25L2.5 18.25L2.5 17.5ZM2.5 2.5L2.5 1.75L1.75 1.75L1.75 2.5L2.5 2.5ZM17.5 1.75L16.25 1.75L16.25 3.25L17.5 3.25L17.5 1.75ZM13.75 1.75L11.25 1.75L11.25 3.25L13.75 3.25L13.75 1.75ZM8.75 1.75L6.25 1.75L6.25 3.25L8.75 3.25L8.75 1.75ZM3.75 1.75L2.5 1.75L2.5 3.25L3.75 3.25L3.75 1.75ZM1.75 2.5L1.75 3.75L3.25 3.75L3.25 2.5L1.75 2.5ZM1.75 6.25L1.75 8.75L3.25 8.75L3.25 6.25L1.75 6.25ZM1.75 11.25L1.75 13.75L3.25 13.75L3.25 11.25L1.75 11.25ZM1.75 16.25L1.75 17.5L3.25 17.5L3.25 16.25L1.75 16.25ZM2.5 18.25L3.75 18.25L3.75 16.75L2.5 16.75L2.5 18.25ZM6.25 18.25L8.75 18.25L8.75 16.75L6.25 16.75L6.25 18.25ZM11.25 18.25L13.75 18.25L13.75 16.75L11.25 16.75L11.25 18.25ZM16.25 18.25L17.5 18.25L17.5 16.75L16.25 16.75L16.25 18.25ZM2.5 17.5L1 17.5L1 19L2.5 19L2.5 17.5ZM2.5 2.5L2.5 0.999999L1 0.999999L1 2.5L2.5 2.5ZM17.5 1L16.25 1L16.25 4L17.5 4L17.5 1ZM13.75 1L11.25 0.999999L11.25 4L13.75 4L13.75 1ZM8.75 0.999999L6.25 0.999999L6.25 4L8.75 4L8.75 0.999999ZM3.75 0.999999L2.5 0.999999L2.5 4L3.75 4L3.75 0.999999ZM1 2.5L1 3.75L4 3.75L4 2.5L1 2.5ZM1 6.25L1 8.75L4 8.75L4 6.25L1 6.25ZM1 11.25L1 13.75L4 13.75L4 11.25L1 11.25ZM1 16.25L1 17.5L4 17.5L4 16.25L1 16.25ZM2.5 19L3.75 19L3.75 16L2.5 16L2.5 19ZM6.25 19L8.75 19L8.75 16L6.25 16L6.25 19ZM11.25 19L13.75 19L13.75 16L11.25 16L11.25 19ZM16.25 19L17.5 19L17.5 16L16.25 16L16.25 19Z"
                className="fill"
                mask="url(#path-1-inside-1_1004_6236)"
            />
            <mask id="path-3-inside-2_1004_6236" fill="white">
                <path d="M17.5 17.5L2.5 17.5L2.5 2.5L17.5 2.5L17.5 17.5Z"/>
            </mask>
            <path className="fill" d="M16 2.5L16 17.5L19 17.5L19 2.5L16 2.5Z" mask="url(#path-3-inside-2_1004_6236)"/>
        </svg>
    );
};

export const FrontWallIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_1004_6238" fill="white">
                <path d="M2.5 17.5L2.5 2.5L17.5 2.5L17.5 17.5L2.5 17.5Z"/>
            </mask>
            <path
                d="M2.5 2.5L2.5 1.75L1.75 1.75L1.75 2.5L2.5 2.5ZM17.5 2.5L18.25 2.5L18.25 1.75L17.5 1.75L17.5 2.5ZM18.25 17.5L18.25 16.25L16.75 16.25L16.75 17.5L18.25 17.5ZM18.25 13.75L18.25 11.25L16.75 11.25L16.75 13.75L18.25 13.75ZM18.25 8.75L18.25 6.25L16.75 6.25L16.75 8.75L18.25 8.75ZM18.25 3.75L18.25 2.5L16.75 2.5L16.75 3.75L18.25 3.75ZM17.5 1.75L16.25 1.75L16.25 3.25L17.5 3.25L17.5 1.75ZM13.75 1.75L11.25 1.75L11.25 3.25L13.75 3.25L13.75 1.75ZM8.75 1.75L6.25 1.75L6.25 3.25L8.75 3.25L8.75 1.75ZM3.75 1.75L2.5 1.75L2.5 3.25L3.75 3.25L3.75 1.75ZM1.75 2.5L1.75 3.75L3.25 3.75L3.25 2.5L1.75 2.5ZM1.75 6.25L1.75 8.75L3.25 8.75L3.25 6.25L1.75 6.25ZM1.75 11.25L1.75 13.75L3.25 13.75L3.25 11.25L1.75 11.25ZM1.75 16.25L1.75 17.5L3.25 17.5L3.25 16.25L1.75 16.25ZM2.5 2.5L2.5 1L1 1L1 2.5L2.5 2.5ZM17.5 2.5L19 2.5L19 1L17.5 1L17.5 2.5ZM19 17.5L19 16.25L16 16.25L16 17.5L19 17.5ZM19 13.75L19 11.25L16 11.25L16 13.75L19 13.75ZM19 8.75L19 6.25L16 6.25L16 8.75L19 8.75ZM19 3.75L19 2.5L16 2.5L16 3.75L19 3.75ZM17.5 1L16.25 1L16.25 4L17.5 4L17.5 1ZM13.75 1L11.25 1L11.25 4L13.75 4L13.75 1ZM8.75 1L6.25 1L6.25 4L8.75 4L8.75 1ZM3.75 1L2.5 1L2.5 4L3.75 4L3.75 1ZM1 2.5L1 3.75L4 3.75L4 2.5L1 2.5ZM1 6.25L1 8.75L4 8.75L4 6.25L1 6.25ZM1 11.25L1 13.75L4 13.75L4 11.25L1 11.25ZM1 16.25L1 17.5L4 17.5L4 16.25L1 16.25Z"
                className="fill"
                mask="url(#path-1-inside-1_1004_6238)"
            />
            <mask id="path-3-inside-2_1004_6238" fill="white">
                <path d="M2.5 17.5L2.5 2.5L17.5 2.5L17.5 17.5L2.5 17.5Z"/>
            </mask>
            <path className="fill" d="M17.5 16L2.5 16L2.5 19L17.5 19L17.5 16Z" mask="url(#path-3-inside-2_1004_6238)"/>
        </svg>
    );
};
