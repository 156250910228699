import {DeviceDto, DeviceKind, DeviceStatus} from "../../api/swagger";
import DeviceForUserModel from "./deviceFofUserModel";

class DeviceModel {
  id: string;
  code: string;
  name: string;
  kind: DeviceKind;
  status: DeviceStatus;
  users: DeviceForUserModel[];

  constructor(device: DeviceDto | undefined = undefined) {
    this.id = device?.id ?? '';
    this.code = device?.code ?? '';
    this.name = device?.name ?? '';
    this.kind = device?.kind ?? DeviceKind.Smartphone;
    this.status = device?.status ?? DeviceStatus.Offline;
    this.users = device?.users?.map(x => new DeviceForUserModel(x)) ?? []
  }
}

export default DeviceModel;