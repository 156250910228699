import React from "react";
import connect from "../../stores/connect";
import WarehouseSchema from "../../models/Warehouses/WarehouseSchema";
import {LoadingOutlined} from "@ant-design/icons";

interface PlayIconProps {
  isSettingWarehouseSchemaIsActive: boolean;
  setWarehouseSchemaIsActive: (schema: WarehouseSchema) => void;
  schema: WarehouseSchema;
}

const PlayIcon: React.FC<PlayIconProps> = ({
  isSettingWarehouseSchemaIsActive ,
  setWarehouseSchemaIsActive,
  schema,
}) => {
  const onClick = () => {
    setWarehouseSchemaIsActive(schema);
  };

  return (
    <>
      {isSettingWarehouseSchemaIsActive && <LoadingOutlined />}
      {!isSettingWarehouseSchemaIsActive && (
        <svg onClick={onClick} className="action-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_259_2981)">
            <mask id="mask0_259_2981" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
              <path d="M2.4251 15.575C2.2501 15.575 2.0501 15.525 1.9001 15.425C1.5501 15.225 1.3501 14.9 1.3501 14.5V1.49997C1.3501 1.12497 1.5501 0.774974 1.9001 0.574974C2.2501 0.374974 2.6501 0.399974 3.0001 0.599974L14.2001 7.12497C14.5251 7.32497 14.7001 7.64997 14.7001 8.02497C14.7001 8.37497 14.5251 8.72497 14.2001 8.89997L2.9751 15.4C2.8001 15.5 2.6001 15.575 2.4251 15.575ZM2.4501 1.59997V14.4L13.4501 7.99997L2.4501 1.59997Z" fill="black"/>
            </mask>
            <g mask="url(#mask0_259_2981)">
              <rect width="16" height="16" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_259_2981">
              <rect width="16" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default connect(({store}) => ({
  isSettingWarehouseSchemaIsActive: store.warehouseStore.isSettingWarehouseSchemaActive,
  setWarehouseSchemaIsActive: store.warehouseStore.setWarehouseSchemaActive,
}))(PlayIcon);
