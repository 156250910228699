import { HomeOutlined, UserOutlined, TagsOutlined, AppstoreOutlined, InfoCircleOutlined } from '@ant-design/icons';
import LoadableComponent from "../Loadable/index";
import Planner from "../../scenes/Planner";

export interface RouteModel {
  path: string;
  exact?: boolean,
  name: string;
  permission?: string;
  title: string;
  icon?: any;
  isLayout?: boolean;
  showInMenu: boolean;
  showInTabs?: boolean;
  component: any;
}

export const userRouter: RouteModel[] = [
  {
    path: '/user',
    name: 'user',
    title: 'User',
    component: LoadableComponent(() => import("../Layout/UserLayout")),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: '/user/login',
    name: 'login',
    title: 'LogIn',
    component: LoadableComponent(() => import('../../scenes/Login')),
    showInMenu: false,
  },
];

export const appRouters: RouteModel[] = [
  {
    path: '/',
    exact: true,
    name: 'home',
    permission: '',
    title: 'Home',
    component: LoadableComponent(() => import("../Layout/AppLayout")),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    permission: '',
    title: 'Dashboard',
    icon: HomeOutlined,
    showInMenu: true,
    showInTabs: true,
    component: LoadableComponent(() => import('../../scenes/Dashboard')),
  },
  {
    path: '/tasks',
    name: 'tasks',
    permission: '',
    title: 'Tasks',
    showInMenu: false,
    showInTabs: true,
    component: LoadableComponent(() => import('../../scenes/Tasks/tasks')),
  },
  {
    path: '/staff',
    name: 'staff',
    permission: 'Pages.Users',
    title: 'Staff',
    showInMenu: false,
    showInTabs: true,
    component: LoadableComponent(() => import('../../scenes/Staff/staff')),
  },
  {
    path: '/devices',
    name: 'devices',
    permission: '',
    title: 'Devices',
    showInMenu: false,
    showInTabs: true,
    component: LoadableComponent(() => import('../../scenes/Devices/devices')),
  },
  {
    path: '/users',
    permission: 'Pages.Users',
    title: 'Users',
    name: 'user',
    icon: UserOutlined,
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Users/users')),
  },
  {
    path: '/roles',
    permission: 'Pages.Roles',
    title: 'Roles',
    name: 'role',
    icon: TagsOutlined,
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Roles')),
  },
  {
    path: '/tenants',
    permission: 'Pages.Tenants',
    title: 'Tenants',
    name: 'tenant',
    icon: AppstoreOutlined,
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Tenants')),
  },
  {
    path: '/about',
    permission: '',
    title: 'About',
    name: 'about',
    icon: InfoCircleOutlined,
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/About')),
  },
  {
    path: '/planner',
    permission: '',
    title: 'Planner',
    name: 'planner',
    icon: InfoCircleOutlined,
    showInMenu: true,
    component: Planner,
  },
  {
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'logout',
    showInMenu: false,
    component: LoadableComponent(() => import("../Logout")),
  },
  {
    path: '/exception?:type',
    permission: '',
    title: 'exception',
    name: 'exception',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Exception')),
  },
];

export const routers = [...userRouter, ...appRouters];
