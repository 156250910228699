export default {
  "+ Add Horizontal Giude":"+ Добавить горизонтальное руководство",
  "+ Add Vertical Giude":"+ Добавить вертикальное руководство",
  "1 side": "1 сторона",
  "2 sides": "2 стороны",
  "2D View": "Плоский вид",
  "3D First Person": "3D от первого лица",
  "3D View": "Объёмный вид",
  "altitude": "над полом",
  "Add QR Code": "Добавить код",
  "Add Cell": "Добавить ячейку",
  "Add Shelf": "Добавить ярус",
  "Altitude": "Над полом",
  "Areas": "Поверхности",
  "Attributes": "Атрибуты",
  "bottom": "Снизу",
  "Box Rack": "Ящичный стеллаж",
  "boxes": "Ящики",
  "Boxes": "Ящики",
  "Cancel": "Отмена",
  "Cantilever Rack": "Консольный стеллаж",
  "Catalog": "Каталог",
  "Cellular Rack": "Ячеистый стеллаж",
  "Chain selected Elements to Group":"Цепочка выбранных элементов в группу",
  "Cell": "Ячейка",
  "Cell1s": "Ячейка 1С",
  "Circular":"круговой",
  "cm": "см",
  "color":"цвет",
  "Configure layer": "Настроить слой",
  "Configure project": "Настроить проект",
  "Confirm": "Подтвердить",
  "Copy": "Копировать",
  "Delete group and all Elements":"Удалить группу и все элементы",
  "Delete shelf and all Elements":"Удалить ярус и все его элементы?",
  "Delete cell and all Elements":"Удалить ячейку и все ее элементы?",
  "Delete layer": "Удалить слой",
  "Delete QR Code": "Удалить код",
  "Delete": "Удалить",
  "Depth": "Глубина",
  "depth": "глубина",
  "Download All QR Codes": "Скачать все коды",
  "Elements on layer {0}": "Элементов на слое {0}",
  "Elements":"элементы",
  "ft": "фт",
  "Get Screenshot": "Сделать снимок экрана",
  "Group [{0}]":"группа [{0}]",
  "Group's Barycenter":"Барицентр группы",
  "Group's Elements":"Элементы группы",
  "Groups":"группы",
  "Guides":"руководства",
  "Height": "Высота",
  "Height (auto)": "Высота (авто)",
  "height": "высота",
  "Holes": "Проёмы",
  "Horizontal":"горизонтальный",
  "Identifier": "Идентификатор",
  "Items": "Предметы",
  "id": "id",
  "in": "д.",
  "Last Selected": "Последний выбраный",
  "Layer config": "Настройка слоя",
  "Layer":"Слой",
  "Layers": "Слои",
  "Length": "Длина",
  "Line": "Линия",
  "Line1s": "Линия 1С",
  "Lines": "Линии",
  "Load project": "Загрузить проект",
  "left": "Слева",
  "Margin": "Отступ",
  "Mouse X Coordinate": "X-координата курсора",
  "Mouse Y Coordinate": "Y-координата курсора",
  "m": "м",
  "mi": "ми",
  "mm": "мм",
  "Name": "Название",
  "New Empty Group":"Новая пустая группа",
  "New Group from selected":"Новая группа из выбранных",
  "New layer": "Новый слой",
  "New project": "Новый проект",
  "number": "номер",
  "ones": "шт.",
  "onTheBack": "Сзади",
  "onTheBottom": "Снизу",
  "onTheFront": "Спереди",
  "onTheLeft": "Слева",
  "onTheRight": "Справа",
  "onTheTop": "Сверху",
  "opacity": "непрозрачность",
  "Open catalog": "Открыть каталог",
  "order": "порядок",
  "Pallet Rack": "Паллетный стеллаж",
  "Pan": "Двигать",
  "Paste": "Вставить",
  "Project config": "Настройка проекта",
  "Properties": "Свойства",
  "Properties: [{0}] {1}": "Свойства: [{0}] {1}",
  "Prototype":"Опытный образец",
  "QR Code": "QR-код",
  "QR Codes": "QR-коды",
  "Radius": "Радиус",
  "radius": "радиус",
  "Rack": "Стеллаж",
  "Rack1s": "Стеллаж 1С",
  "Rack1sConfirm": "Подтвердить и пересоздать ярусы стеллажа",
  "Rack1sMessage": "Ярусы и ячейки стеллажа будут удалены и созданы заново из выбранного стеллажа 1С",
  "Rack1sNotSelected": "Не привязывать к стеллажу 1С",
  "rack": "стеллаж",
  "Reset": "Сброс",
  "Rotation": "Вращение",
  "rib": "Ребро",
  "right": "Справа",
  "Save project": "Сохранить проект",
  "Save": "Сохранить",
  "Scene Zoom Level": "Уровень приближениия сцены",
  "Search Element": "Поиск",
  "Section": "Секция",
  "Section1s": "Секция 1С",
  "SectionsAndCells": "Полки и ячейки",
  "Select": "Выбрать",
  "Shelf": "Ярус",
  "Shelf Rack": "Полочный стеллаж",
  "Shelf1s": "Ярус 1С",
  "Shelves": "Ярусы",
  "Shelving Rack": "Набивной стеллаж",
  "Shelving": "Стеллаж",
  "shelving": "стеллаж",
  "Side": "Сторона",
  "Sides": "Стороны",
  "Snap to Grid": "Привязка к сетке",
  "Snap to Guide": "Привязать к руководству",
  "Snap to Line": "Привязка к линии",
  "Snap to Point": "Привязка к точке",
  "Snap to Segment": "Привязка к сегменту",
  "texture":"текстура",
  "Thickness":"Толщина",
  "thickness":"толщина",
  "Toggle Group Visibility":"Переключить видимость группы",
  "top": "Сверху",
  "Type": "Тип",
  "type": "Тип",
  "Un-chain all Group\'s Elements and remove Group":"Удалить цепочку элементов группы и удалить группу",
  "Un-chain Element from Group":"Unchain Element из группы",
  "Undo (CTRL-Z)": "Отменить действие (CTRL-Z)",
  "Unselect": "Снять выделене",
  "Vertical":"вертикальный",
  "Walls": "Стенки",
  "Warehouse": "Склад",
  "Width": "Ширина",
  "width": "ширина",
  "Would you want to start a new Project?": "Начать новый проект?",
  "x": "x",
  "y": "y",
  "Zoom in": "Приблизить",
  "Zoom out": "Отдалить"
};
