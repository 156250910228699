import React from "react";

const FloorAreaBorderIcon: React.FC = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M3.75 19.9743V4.04057L10.2628 6.21151L10.4657 6.27913L10.6737 6.2296L20.25 3.94954V16.75H14H13.8836L13.7726 16.7853L3.75 19.9743Z"
    className="stroke"
    strokeWidth="1.5"
  />
  <path d="M4 9.5L8 5.5" className="stroke"/>
  <path d="M4 13.5L11.5 6" className="stroke"/>
  <path d="M4 17.5L16.5 5" className="stroke"/>
  <path d="M12 17.5L20.5 9" className="stroke"/>
  <path d="M17 16.5L20.5 13" className="stroke"/>
  <path d="M6 19.5L20.5 5" className="stroke"/>
</svg>;

export default FloorAreaBorderIcon;
