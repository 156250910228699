import RoleStore from './roleStore';
import TenantStore from './tenantStore';
import UserStore from './userStore';
import SessionStore from './sessionStore';
import AuthenticationStore from './authenticationStore';
import AccountStore from './accountStore';
import TaskStore from "./taskStore";
import DeviceStore from "./deviceStore";
import ProductStore from "./productStore";
import WarehouseStore from "./warehouseStore";
import WebRTCStore from "./webRTCStore";

export class RootStore {
  authenticationStore = new AuthenticationStore();
  roleStore = new RoleStore();
  tenantStore = new TenantStore();
  userStore = new UserStore();
  sessionStore = new SessionStore();
  accountStore = new AccountStore();
  taskStore = new TaskStore();
  deviceStore = new DeviceStore();
  productStore = new ProductStore();
  warehouseStore = new WarehouseStore();
  webRTCStore = new WebRTCStore();
}

const rootStore = new RootStore();

export default rootStore;
