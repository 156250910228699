import WarehouseTopologyRackCollection from "./WarehouseTopologyRackCollection";
import WarehouseTopologySectionCollection from "./WarehouseTopologySectionCollection";
import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopology {
  constructor(
    readonly externalId: string | undefined,
    readonly warehouseName: string | undefined,
    readonly racks: WarehouseTopologyRackCollection,
    readonly sections: WarehouseTopologySectionCollection,
  ) {
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopology {
    return new WarehouseTopology(
      this.externalId,
      this.warehouseName,
      this.racks.withoutCells(removingCells),
      this.sections.withoutCells(removingCells)
    );
  }
}
