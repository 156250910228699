import React from "react";

const SectionWidthIcon = () => {
    return (
        <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.75 11.25L11.75 0.75M1.25 11.25L1.25 0.75M3.29167 6L9.70833 6M9.70833 7.75L9.70833 4.25M3.29167 7.75L3.29167 4.25"
                className="stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SectionWidthIcon;
