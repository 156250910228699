import React, {useEffect, useRef, useState} from "react";
import convert from "convert-units";
import {Button, Modal} from "antd";
import connect from "../../../../stores/connect";
import WarehouseTopologyCollection from "../../../../models/Warehouses/WarehouseTopologyCollection";
import {
  ProjectSettings,
  ProjectSettingsModalNameRow,
  ProjectSettingsModalRow, ProjectSettingsModalSizeUnit,
  ProjectSettingsModalSourceRow,
  ProjectSettingsModalTemperatures,
  ProjectSettingsModalWarehouseTopologyItem,
  ProjectSettingsModalWarehouseTypeItem
} from "./ProjectSettingsComponents";
import {EditWarehouseRequestDto, WarehouseType} from "../../../../api/swagger";
import {UNIT_CENTIMETER} from "../../react-planner/es/constants";
import {CreatingWarehouseParams} from "../../../../stores/warehouseStore";

interface NewProjectModalProps {
  createWarehouse: (dto: EditWarehouseRequestDto, {width, height, unit}: CreatingWarehouseParams) => Promise<void>;
  isOpen: boolean;
  isSaving: boolean;
  setOpen: (value: boolean) => void;
  warehouseTopologies: WarehouseTopologyCollection,
}

const DisconnectedNewProjectModal: React.FC<NewProjectModalProps> = (
  {
    createWarehouse,
    isOpen,
    isSaving,
    setOpen,
    warehouseTopologies,
  }
) => {
  const [warehouseName, setWarehouseName] = useState<string | undefined>('');

  const [isCreatingFromTopology, setCreatingFromTopology] = useState<boolean>(false);

  const [warehouseTopologyId, setWarehouseTopologyId] = useState<string | undefined>(undefined);

  const [hasTemperatureCondition, setHasTemperatureCondition] = useState<boolean>(false);

  const [warehouseType, setWarehouseType] = useState<WarehouseType | undefined>(undefined);

  const [temperatureFrom, setTemperatureFrom] = useState<number>(-50);
  const [temperatureTo, setTemperatureTo] = useState(50);

  const [unit, setUnit] = useState<string>(UNIT_CENTIMETER);
  const prevUnit = useRef(unit);
  const [height, setHeight] = useState(2000);
  const [width, setWidth] = useState(3000);

  useEffect(() => {
    setHeight(convert(height).from(prevUnit.current).to(unit));
    setWidth(convert(width).from(prevUnit.current).to(unit));
    prevUnit.current = unit;
  }, [unit]);


  const canSave = !!warehouseType && !!warehouseName;

  const closeModal = () => setOpen(false);

  const save = async () => {
    const dto: EditWarehouseRequestDto = {
      warehouseId: undefined,
      type: warehouseType,
      externalId: warehouseTopologyId,
      name: warehouseName,
      hasTemperatureCondition,
      minDegreesCelsius: temperatureFrom,
      maxDegreesCelsius: temperatureTo,
    };
    await createWarehouse(dto, {width, height, unit});
    closeModal();
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      title="Новый склад"
      footer={[
        <Button key="ok" type="primary" disabled={!canSave} loading={isSaving} onClick={save}>
          Сохранить
        </Button>,
        <Button key="cancel" onClick={closeModal}>Отменить</Button>
      ]}
    >
      <ProjectSettings>
        <ProjectSettingsModalSourceRow value={isCreatingFromTopology} onChange={setCreatingFromTopology} />
        <ProjectSettingsModalRow>
          <ProjectSettingsModalNameRow value={warehouseName} onChange={setWarehouseName} />
        </ProjectSettingsModalRow>
        {isCreatingFromTopology && (
          <ProjectSettingsModalRow>
            <ProjectSettingsModalWarehouseTopologyItem
              warehouseTopologies={warehouseTopologies}
              value={warehouseTopologyId}
              onChange={setWarehouseTopologyId}
            />
          </ProjectSettingsModalRow>
        )}
        <ProjectSettingsModalRow>
          <ProjectSettingsModalSizeUnit
            height={height}
            onHeightChange={setHeight}
            onUnitChange={setUnit}
            onWidthChange={setWidth}
            unit={unit}
            width={width}
          />
        </ProjectSettingsModalRow>
        <ProjectSettingsModalRow>
          <ProjectSettingsModalWarehouseTypeItem value={warehouseType} onChange={setWarehouseType} />
        </ProjectSettingsModalRow>
        <ProjectSettingsModalRow>
          <ProjectSettingsModalTemperatures
            hasTemperatureCondition={hasTemperatureCondition}
            onHasTemperatureConditionUpdate={setHasTemperatureCondition}
            onTemperatureFromChange={setTemperatureFrom}
            onTemperatureToChange={setTemperatureTo}
            temperatureFrom={temperatureFrom}
            temperatureTo={temperatureTo}
          />
        </ProjectSettingsModalRow>
      </ProjectSettings>
    </Modal>
  );
};

export default connect(({store}) => ({
  createWarehouse: store.warehouseStore.createWarehouse,
  isSaving: store.warehouseStore.isEditingWarehouse,
  warehouseTopologies: store.warehouseStore.topologies,
}))(DisconnectedNewProjectModal);
