import React from "react";

const ShelfThicknessIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.807617 0.269043L0.807617 4.57674C0.807617 4.71954 0.864348 4.8565 0.965329 4.95749C1.06631 5.05847 1.20327 5.1152 1.34608 5.1152H12.6538C12.7966 5.1152 12.9335 5.05847 13.0345 4.95749C13.1355 4.8565 13.1922 4.71954 13.1922 4.57674V0.269043H12.1153V4.03827H1.88454V0.269043L0.807617 0.269043ZM13.1922 13.7306V9.42289C13.1922 9.28008 13.1355 9.14312 13.0345 9.04214C12.9335 8.94116 12.7966 8.88443 12.6538 8.88443H1.34608C1.20327 8.88443 1.06631 8.94116 0.965329 9.04214C0.864348 9.14312 0.807617 9.28008 0.807617 9.42289L0.807617 13.7306H1.88454L1.88454 9.96135H12.1153V13.7306H13.1922ZM3.23069 7.53827H1.07685V6.46135H3.23069V7.53827ZM4.30762 7.53827H6.46146V6.46135H4.30762V7.53827ZM9.69223 7.53827H7.53839V6.46135H9.69223V7.53827ZM10.7692 7.53827H12.923V6.46135H10.7692V7.53827Z"
                className="fill"
            />
        </svg>

    );
};

export default ShelfThicknessIcon;
