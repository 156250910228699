import WarehouseTopology from "./WarehouseTopology";
import Collection from "../../domain/collection";

export default class WarehouseTopologyCollection extends Collection<WarehouseTopology> {
  findById(topologyId: string | undefined): WarehouseTopology|undefined {
    return this.find(
      (topology: WarehouseTopology) => topology.externalId === topologyId
    );
  }
}
