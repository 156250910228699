import {DeviceForUserDto, UserDto} from "../../api/swagger";

class DeviceForUserModel {
  id: string;
  deviceId: string;
  userId: number;
  user: UserDto | undefined;
  isActive: boolean;
  lastUsed: Date | undefined;

  constructor(dto: DeviceForUserDto | undefined = undefined) {
    this.id = dto?.id ?? '';
    this.deviceId = dto?.deviceId ?? '';
    this.userId = dto?.userId ?? 0;
    this.user = dto?.user
    this.isActive = dto?.isActive ?? false;
    this.lastUsed = dto?.lastUsed;
  }
}
export default DeviceForUserModel;