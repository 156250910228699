import React from "react";
import {Menu, Tooltip} from "antd";
import {isGranted, L} from "../../lib/abpUtility";

export interface ProtectedMenuItemProps {
  history: any;
  icon: React.ReactNode;
  path: string;
  permission: string | null;
  title: string;
  onItemHover?: () => void;
}

const ProtectedMenuItem: React.FC<ProtectedMenuItemProps> = ({
  history,
  icon,
  path,
  permission,
  title, onItemHover,
}) => {
  if (permission && !isGranted(permission)) {
    return null;
  }

  return (
    <Menu.Item 
      onItemHover={onItemHover}
      style={{
        cursor: 'pointer',
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        lineHeight: 1,
        alignItems: 'center',
        marginBottom: 20
      }}
      onClick={() => history.push(path)}
    >
      <Tooltip title={L(title)}>
        <div className="iconHover" style={{marginRight: 9}}>
          {icon}
        </div>
      </Tooltip>
    </Menu.Item>
  );
};

export default ProtectedMenuItem;
