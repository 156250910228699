import {State} from './react-planner/src/models';

const defaultState = {
    'react-planner': new State(),
};

export {
    State,
};

export default defaultState;
