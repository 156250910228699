import React from "react";

const DeleteRowIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.39552 1.59473L1.60352 6.38673"
                className="stroke"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.39707 6.38977L1.60107 1.59277"
                className="stroke"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default DeleteRowIcon;
