import React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import {UNITS_LENGTH, UNIT_CENTIMETER} from '../../constants';
import convert from 'convert-units';
import { FormLabel, FormNumberInput, FormSelect } from '../../components/style/export';
import {toFixedFloat} from '../../utils/math';
import PropertyStyle from './shared-property-style';
import LengthInput from "../../../../components/LengthInput/LengthInput";

const internalTableStyle = {borderCollapse: 'collapse'};
const secondTdStyle = {padding: 0};
const unitContainerStyle = {width: '5em'};

const PropertyLengthMeasure = (
  {value, onUpdate, onValid, configs, sourceElement, internalState, state, translator},
  {catalog}
) => {
  let length = value.get('length') || 0;
  let _length = value.get('_length') || length;
  let _unit = value.get('_unit') || UNIT_CENTIMETER;
  let { hook, label, ...configRest} = configs;

  let update = (lengthInput, unitInput) => {

    let newLength = toFixedFloat(lengthInput);
    let merged = value.merge({
      length: unitInput !== UNIT_CENTIMETER ? convert(newLength).from(unitInput).to(UNIT_CENTIMETER) : newLength,
      _length: lengthInput,
      _unit: unitInput
    });

    if (hook) {
      return hook(merged, sourceElement, internalState, state).then(val => {
        return onUpdate(val);
      });
    }

    return onUpdate(merged);
  };

  return (
    <table className="PropertyLengthMeasure" style={PropertyStyle.tableStyle}>
      <tbody>
      <tr>
        <td style={PropertyStyle.firstTdStyle}><FormLabel>{ translator.t(label) }</FormLabel></td>
        <td style={secondTdStyle}>
          <LengthInput
            onChange={(centimeters) => update(centimeters, UNIT_CENTIMETER)}
            state={state}
            translator={translator}
            unit={_unit}
            value={_length}
          />
        </td>
      </tr>
      </tbody>
    </table>
  );

}

PropertyLengthMeasure.propTypes = {
  // eslint-disable-next-line consistent-return,react/require-default-props
  value: (props, propName, componentName) => {
    if (!(Map.isMap(props[propName]))) {
      return new Error(`Invalid prop ${propName} passed to ${componentName}. Expected a Map instance`);
    }
  },
  onUpdate: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired
};

PropertyLengthMeasure.contextTypes = {
  catalog: PropTypes.object.isRequired
};

export default PropertyLengthMeasure;
