import React from "react";
import WarehouseSchema from "../../../models/Warehouses/WarehouseSchema";
import DeleteIcon from "../../../components/Svg/DeleteIcon";
import DownloadIcon from "../../../components/Svg/DownloadIcon";
import EditIcon from "../../../components/Svg/EditIcon";
import connect from "../../../stores/connect";

interface WarehouseSchemaHistoryItemProps {
  schema: WarehouseSchema;
  setCurrentWarehouseSchema: (schema: WarehouseSchema | null) => void;
}

const WarehouseSchemaHistoryItem: React.FC<WarehouseSchemaHistoryItemProps> = ({
  schema,
  setCurrentWarehouseSchema,
}) => {
  const onEditButtonClick = () => {
    setCurrentWarehouseSchema(schema);
  }

  return (
    <div className="warehouse-schema-history-item">
      <div className="warehouse-schema-history-item-info">
        <div className="warehouse-schema-history-item-info-data">
          <div className="warehouse-schema-history-item-info-data-date">
            {schema.formatDate()}
          </div>
          <div className={`warehouse-schema-history-item-info-data-status ${schema.isActive ? 'active' : ''}`}>
            {!schema.isActive && schema.isDraft && <>Черновик</>}
            {schema.isActive && <>Используется в работе склада</>}
            {!schema.isActive && !schema.isDraft && <>Не используется</>}
          </div>
        </div>
        <div className="warehouse-schema-history-item-info-actions">
          <div><DeleteIcon schema={schema} /></div>
          <div><DownloadIcon schema={schema} /></div>
          <div onClick={onEditButtonClick}><EditIcon /></div>
        </div>
      </div>
      <div className="warehouse-schema-history-item-name">
        {schema.name || 'No name'}
      </div>
    </div>
  );
};

export default connect(({store}) => ({
  setCurrentWarehouseSchema: store.warehouseStore.setCurrentWarehouseSchema,
}))(WarehouseSchemaHistoryItem);
