import PoleAreaBorderFactory from "../../factories/PoleAreaBorderFactory";

import {POLE_AREA_BORDER_TYPE} from "../../constants";

/* eslint-disable global-require */
const info = {
  title: 'Pole Area Border',
  tag: ['pole-area-border'],
  description: 'Pole Area Border',
  image: require('./wall.png'),
  visibility: {
    catalog: true,
    layerElementsVisible: true
  }
};

const textures = {
  // bricks: {
  //   name: 'Bricks',
  //   uri: require('./textures/bricks.jpg'),
  //   lengthRepeatScale: 0.01,
  //   heightRepeatScale: 0.01,
  //   normal: {
  //     uri: require('./textures/bricks-normal.jpg'),
  //     lengthRepeatScale: 0.01,
  //     heightRepeatScale: 0.01,
  //     normalScaleX: 0.8,
  //     normalScaleY: 0.8
  //   }
  // },
  // painted: {
  //   name:'Painted',
  //   uri: require('./textures/painted.jpg'),
  //   lengthRepeatScale: 0.01,
  //   heightRepeatScale: 0.01,
  //   normal: {
  //     uri: require('./textures/painted-normal.jpg'),
  //     lengthRepeatScale: 0.01,
  //     heightRepeatScale: 0.01,
  //     normalScaleX: 0.4,
  //     normalScaleY: 0.4
  //   }
  // },
};

export default PoleAreaBorderFactory(POLE_AREA_BORDER_TYPE, info, textures);

