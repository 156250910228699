import React, {useState} from "react";
import cn from 'classnames';
import OutsideClickHandler from "../OutsideClickHandler/OutsideClickHandler";
import DropdownIcon from "../Svg/DropdownIcon";
import WallItemsIcon from "../Svg/WallItemsIcon";
import ObstacleItemsIcon from "../Svg/ObstacleItemsIcon";
import StairsIcon from "../Svg/StairsIcon";
import HolesIcon from "../Svg/HolesIcon";
import RackIcon from "../Svg/RackIcon";

export interface IDropdownButtonProps {
  children: React.ReactNode;
  dropdownWidth?: string;
  type: "wall" | "obstacle" | "stairs" | "hole" | "rack"
}

const DropdownButton: React.FC<IDropdownButtonProps> = ({
  children,
  dropdownWidth = '100px',
  type=""
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);

  return (
    <OutsideClickHandler onOutsideClick={() => {setOpen(false);}}>
      <div onClick={toggleOpen} className={cn(
        'button new-object-button dropdown-button',
        {
          'open': isOpen,
        }
      )}>
        <div className="icons">
          {type === "wall" && <WallItemsIcon />}
          {type === "obstacle" && <ObstacleItemsIcon />}
          {type === "stairs" && <StairsIcon />}
          {type === "hole" && <HolesIcon />}
          {type === "rack" && <RackIcon />}
          <DropdownIcon />
        </div>
        {isOpen && (
          <div className="children" style={{width: dropdownWidth,}}>
              {children}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default DropdownButton;
