import {action, observable} from "mobx";

import {ProductDto} from "../api/swagger";
import {apiClient} from "../services/apiClient";

class ProductStore {
  @observable products!: ProductDto[];
  @observable isProductsLoaded = false

  @action async getAll(): Promise<void> {
    this.products = await apiClient.getProducts()
    this.isProductsLoaded = true
  }
}

export default ProductStore;