import Collection from "../../domain/collection";
import WarehouseTopologyLine from "./WarehouseTopologyLine";
import WarehouseTopologyCellAddressCollection from "./WarehouseTopologyCellAddressCollection";

export default class WarehouseTopologyLineCollection extends Collection<WarehouseTopologyLine> {
  findByName(lineName: string): WarehouseTopologyLine | undefined {
    return this.find(
      (line) => line.name === lineName
    );
  }

  withoutCells(removingCells: WarehouseTopologyCellAddressCollection): WarehouseTopologyLineCollection {
    return new WarehouseTopologyLineCollection(
      this
        .getAll()
        .map(
          (line: WarehouseTopologyLine) => line.withoutCells(removingCells)
        )
        .filter(
          (line: WarehouseTopologyLine) => !line.racks.isEmpty()
        )
    );
  }
}
