import React from "react";

const DropdownIcon: React.FC = ({}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_645_2041)">
    <mask id="mask0_645_2041" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path
        d="M7.25312 12.2532C6.91562 12.5907 6.91562 13.1157 7.25312 13.4532L11.1906 17.3532C11.6031 17.7657 12.2781 17.7657 12.7281 17.3532L16.6281 13.4532C16.7781 13.3032 16.8906 13.0782 16.8906 12.8532C16.8906 12.6282 16.8156 12.4407 16.6281 12.2532C16.2906 11.9157 15.7656 11.9157 15.4281 12.2532L11.9406 15.7407L8.45313 12.2532C8.11563 11.9157 7.55312 11.9157 7.25312 12.2532Z"
        className="fill"/>
    </mask>
    <g mask="url(#mask0_645_2041)">
      <rect width="24" height="24" className="fill"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_645_2041">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export default DropdownIcon;
