import {State} from "../state";
import WarehouseTopology from "../../../models/Warehouses/WarehouseTopology";
import {getSelectedLayer} from "./getSelectedLayer";
import {Item} from "../react-planner/src/models";
import WarehouseTopologyCellAddress from "../../../models/Warehouses/WarehouseTopologyCellAddress";
import WarehouseTopologyCellAddressCollection from "../../../models/Warehouses/WarehouseTopologyCellAddressCollection";

const getBusyWarehouseTopologyCells: (state: State) => WarehouseTopologyCellAddressCollection = (state) => {
  const cells: WarehouseTopologyCellAddress[] = [];
  const selectedLayer = getSelectedLayer(state);
  // @ts-ignore
  const havingTopologyItems = selectedLayer
    // @ts-ignore
    .getIn(['items'])
    .filter(
      (item: Item) => item.get('type') === 'rack'
    )
  ;

  havingTopologyItems.map(
    (item: Item) => {
      const properties = item.get('properties');
      switch (item.get('type')) {
        case 'rack':
          const rackConfig = properties.get('config');
          const topology = rackConfig.get('topology');

          const {section, line, rackName: rack} = topology.toJS();
          if (!section || !line || !rack) {
            return;
          }
          const shelves = rackConfig.get('shelves');
          shelves.map(
            (shelfData) => {
              const shelfName = shelfData.get('topologyShelf');
              if (!shelfName) {
                return;
              }
              const sections = shelfData.get('sections');
              sections.map(
                (sectionData) => {
                  const topologyCell = sectionData.get('topologyCell');
                  if (!topologyCell) {
                    return;
                  }
                  cells.push(new WarehouseTopologyCellAddress(topologyCell));
                }
              );
            }
          );
        default:
          return;
      }
    }
  );

  return new WarehouseTopologyCellAddressCollection(cells);
};

export default getBusyWarehouseTopologyCells;
