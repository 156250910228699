import React from "react";

const CollapsedIcon: React.FC = () => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.04419 7.71094C5.76294 7.99219 5.76294 8.42969 6.04419 8.71094L9.32544 11.9609C9.66919 12.3047 10.2317 12.3047 10.6067 11.9609L13.8567 8.71094C13.9817 8.58594 14.0754 8.39844 14.0754 8.21094C14.0754 8.02344 14.0129 7.86719 13.8567 7.71094C13.5754 7.42969 13.1379 7.42969 12.8567 7.71094L9.95044 10.6172L7.04419 7.71094C6.76294 7.42969 6.29419 7.42969 6.04419 7.71094Z"
                className="fill"
            />
        </svg>
    );
};

export default CollapsedIcon;
